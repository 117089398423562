import { Component } from '@angular/core';
import { Explicacion } from 'src/app/models/info_usuarios';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { LoginService } from 'src/app/services/login.service';
import { PopupService } from 'src/app/services/popups.service';

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css']
})
export class UsuarioComponent {
  
  constructor(
    private popupService: PopupService,
    private loginService: LoginService,
    private imagenesService: ImagenesService,
  ){
    this.ResumenAntecedentesMedicos(this.loginService.InfoUsuario.Ficha_Medica.Antecedentes_Personales)
    this.imagenesService.getImagen(this.InfoUsuario.photoURL)
    .then( URL => this.URLfoto = URL )
  }

  InfoUsuario = this.loginService.InfoUsuario;
  URLfoto: string = '/assets/images/usuario_sin_imagen.svg';


  editar_Usuario = (formularios: Array<'Info_General' | 'Info_Especifica' | 'Ficha_Medica'>  = ['Info_General', 'Info_Especifica', 'Ficha_Medica']) =>
    this.popupService.popupEditarInformacionGeneral([].concat(formularios))

  ResumenAntecedentesMedicos = ( antecedentes ) =>
    Object.entries(antecedentes).map( ([key, valor]) => ({key, valor}) ).filter( (elemento: {key: string, valor: Explicacion}) => elemento.valor.Valor )

  subirImagen = ( archivos ) => {
    const PopupTitulo = `Editar imagen ${this.InfoUsuario.email}`;
    const PopupPregunta = `¿Seguro desea ${this.InfoUsuario.photoURL ? 'editar' : 'establecer'} la imagen seleccionada para ${this.InfoUsuario.email}?`;
    const Accion = (): void => this.imagenesService.subirArchivo( archivos.target.files, 'usuarios' );
    
    this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }



}
