<div class="container">
    <ng-template #cargando>
        <div class="Cargando">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>

    <ng-container *ngIf="cargado; else cargando">
        <div [ngSwitch]="view">
            <div class="container_containers_navegacion sticky_top">
                <div class="container_navegacion">
                    <button mat-raised-button color="primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Atras</button>
                    <button mat-raised-button [color]="vistaHoy() ? 'basic' : 'primary'" mwlCalendarToday [(viewDate)]="viewDate">Hoy</button>
                    <button mat-raised-button color="primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">Siguiente</button>
                </div>
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'es' }}</h3>
                <div class="container_navegacion">
                    <button mat-raised-button [color]="view === 'month' ? 'basic' : 'primary'" (click)="setView(CalendarView.Month)">Mes</button>
                    <button mat-raised-button [color]="view === 'week' ? 'basic' : 'primary'" (click)="setView(CalendarView.Week)">Semana</button>
                    <button mat-raised-button [color]="view === 'day' ? 'basic' : 'primary'" (click)="setView(CalendarView.Day)">Día</button>
                </div>
            </div>

            <br>
            <mat-card>
                <mat-card-content>
                    <div class="Muestra_Color">
                        <p class="Color_No_Confirmadas">Reservas Sin Confirmar</p>
                        <p class="Color_Confirmadas">Reservas Directas Confirmadas</p>
                        <p class="Color_Aliados">Reservas Aliados Confirmadas</p>
                        <p class="Color_Bloqueos">Bloqueos</p>
                    </div>
                </mat-card-content>
            </mat-card>

            <div class="Calendario">
                <mwl-calendar-month-view
                *ngSwitchCase="CalendarView.Month"
                [viewDate]="viewDate"
                [events]="eventos()"
                [refresh]="refresh"
                [activeDayIsOpen]="activeDayIsOpen"
                (dayClicked)="dayClicked($event.day)"
                (eventClicked)="verEvento($event.event)"
                (eventTimesChanged)="moverFechaBloqueo($event)"
                (beforeViewRender)="beforeMonthViewRender($event)"
                >
                </mwl-calendar-month-view>
                <mwl-calendar-week-view
                *ngSwitchCase="CalendarView.Week"
                [viewDate]="viewDate"
                [events]="eventos()"
                [refresh]="refresh"
                (eventClicked)="verEvento($event.event)"
                (eventTimesChanged)="moverFechaBloqueo($event)"
                >
                </mwl-calendar-week-view>
                <mwl-calendar-day-view
                *ngSwitchCase="CalendarView.Day"
                [viewDate]="viewDate"
                [events]="eventos()"
                [refresh]="refresh"
                (eventClicked)="verEvento($event.event)"
                (eventTimesChanged)="moverFechaBloqueo($event)"
                >
                </mwl-calendar-day-view>
            </div>
        </div>
        <div class="container_containers_navegacion sticky_bottom">
            <button mat-raised-button color="primary" (click)="agregarBloqueo()"><mat-icon aria-hidden="false">new_label</mat-icon> Nuevo Bloqueo</button>
        </div>
    </ng-container>
    
</div>