export const Lista_Categorias_Staff = [
    "Baqueano o Guía Local",
    "Director",
    "Guía",
    "Primer Respondiente",
] as const

export const Lista_Categorias_Tipos = {
    "Destino": 'DES',
    "Documento": 'DOC',
    "Equipo": 'EQP',
    "Producto": 'PRO',
    "Proveedor": 'PVD',
    "Libro": 'LIB',
} as const

export const Lista_Categorias_Destinos = {
    "Amazonía": 'AMZ',
    "Andina": 'AND',
    "Caribe": 'CAR',
    "Orinoquía": 'ORQ',
    "Pacífico": 'PAF',
    "Varias regiones": 'VAR',
} as const

export const Lista_Categorias_Documentos = {
    "Actas": 'ACT',
    "Formatos": 'FOR',
    "Generales": 'GEN',
    "Manuales": 'MAN',
    "Políticas y Códigos": 'POL',
    "Procedimientos y Protocolos": 'PRO',
    "Programas": 'PGM',
} as const

export const Lista_Categorias_Equipos = {
    "Arneses de escalada": 'ARN',
    "Binoculares": 'BIN',
    "Carpas": 'CAR',
    "Cascos de escalada": 'CAE',
    "Computadores": 'COM',
    "Crampones": 'CRA',
    "Drones": 'DRO',
    "Equipos Botiquín": 'FAE',
    "GPS": 'GPS',
    "Lupas Botánicas": 'LUP',
    "Misceláneos": 'MSN',
    "Piolets": 'IXE',
    "Radios": 'RAD',
    "Sleeping Bags": 'SLE',
} as const

export const Lista_Categorias_Productos = {
    "Avistamiento de aves": 'AVE',
    "Caminatas": 'CAM',
    "City tours": 'CIT',
    "Cursos": 'CUR',
    "Eco-Aventuras": 'ADV',
    "Eco-Tours": 'ECO',
    "Educación experiencial": 'EDX',
    "Expediciones": 'EXP',
    "Turismo científico": 'CIE',
} as const

export const Lista_Temas_Libros = {
    "Aves": 'AVE',
    "Ecología": 'ECO',
    "Diseño": 'DIS',
    "Mamíferos": 'MAM',
    "Plantas": 'PLA',
} as const

export const Lista_Monedas = {
    "Pesos Colombianos": 'COP',
    "Dolares Estadounidenses": 'USD',
    "Euros": 'EUR',
    "Bitcoins": 'BTC',
} as const

export const Lista_Formas_Compra = [
    'Directamente al vendedor',
    'Tienda en físico',
    'Tienda online',
    'Regalo',
] as const

export const Lista_Estado_Solicitud = [
    'Solicitud pendiente',
    'Aprobada',
    'Rechazada',
] as const

export const Lista_Estado_Prestamo = [
    'Nuevo',
    'Por recoger',
    'Pendiente',
    'Devuelto',
] as const

export const Lista_Etapa_Solicitud = [
    'Solicitud',
    'Aprobación',
    'Recogida',
    'Devolución',
] as const