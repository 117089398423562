<h2 mat-dialog-title>Entregar prestamo</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="formulario_elemento">
        
        <mat-divider></mat-divider>

        <mat-form-field>
            <mat-label>Fecha Prestamo</mat-label>
            <mat-date-range-input [formGroup]="Fecha_Prestamo" [rangePicker]="rangoFecha" [disabled]="Fecha_Aprobacion?.value">
                <input matStartDate formControlName="Inicio" placeholder="Fecha recogida">
                <input matEndDate formControlName="Fin" placeholder="Fecha devolución">
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="rangoFecha"></mat-datepicker-toggle>
            <mat-hint>DD/MM/AAAA - DD/MM/AAAA</mat-hint>
            <mat-date-range-picker #rangoFecha [dateClass]="dateClass"></mat-date-range-picker>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Justificación</mat-label>
            <input matInput type="text" [formControl]="Justificacion">
            <button mat-icon-button *ngIf="Justificacion.value && data.tipo === 'Solicitud'" matSuffix aria-label="Clear" (click)="borrarCampo(Justificacion)">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>

        <mat-form-field>
            <mat-label>Persona solicitante</mat-label>
            <input matInput type="text"  [value]="getNombreUsuario(ID_Solicitante.value)" disabled>
        </mat-form-field>

        <mat-divider></mat-divider>

        <ng-container *ngIf="(Fecha_Prestamo.valid && Justificacion.valid) || data.tipo !== 'Solicitud'">
            <p>Elementos a prestar</p>
            <table formArrayName="Codigos_Elementos_Prestamo">
                <tr>
                    <th># Elemento</th>
                    <th>Tipo</th>
                    <th *ngIf="mostrarColumna('Tipo_Elemento')">Categoría</th>
                    <th *ngIf="mostrarColumna('Categoria_Elemento')">Elemento</th>
                    <th *ngIf="Codigos_Elementos_Prestamo.value.length > 1 && data.tipo === 'Solicitud'">Quitar</th>
                </tr>
    
                <ng-container *ngIf="Fecha_Aprobacion?.value; else SolicitudNueva">
                    <tr *ngFor="let elemento of Codigos_Elementos_Prestamo.controls; let Numero_Elemento = index" [formGroupName]="Numero_Elemento">
                        <td class="Numero_Elemento">{{ Numero_Elemento + 1 }}</td>
                        <td>
                            <mat-form-field>
                                <input matInput type="text" formControlName="Tipo_Elemento">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput type="text" formControlName="Categoria_Elemento">
                            </mat-form-field>
                        </td>
                        <td>
                            <mat-form-field>
                                <input matInput type="text" formControlName="Codigo_Elemento">
                            </mat-form-field>
                        </td>
                    </tr>
                </ng-container>
                <ng-template #SolicitudNueva>
                    <tr *ngFor="let elemento of Codigos_Elementos_Prestamo.controls; let Numero_Elemento = index" [formGroupName]="Numero_Elemento">
                        <td class="Numero_Elemento">{{ Numero_Elemento + 1 }}</td>
                        <td>
                            <mat-form-field>
                                <mat-select formControlName="Tipo_Elemento">
                                    <mat-option value="Libro" *ngIf="!categoriaVacia('Libro')">Libro</mat-option>
                                    <mat-option value="Equipo" *ngIf="!categoriaVacia('Equipo')">Equipo</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td *ngIf="getValorElemento(Numero_Elemento, 'Tipo_Elemento'); else Categoria_Elemento_Blanco">
                            <mat-form-field>
                                <mat-select formControlName="Categoria_Elemento">
                                    <mat-option *ngFor="let categoria of getCategorias(Numero_Elemento)" [value]="categoria">{{ categoria }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
                        <td *ngIf="getValorElemento(Numero_Elemento, 'Categoria_Elemento'); else Codigo_Elemento_Blanco">
                            <mat-form-field>
                                <mat-select formControlName="Codigo_Elemento">
                                    <mat-option *ngFor="let elemento of getElementos(Numero_Elemento)" [disabled]="elementoYaEscogido(elemento)" [value]="elemento.Codigo_Elemento">{{ elemento.Codigo_Elemento }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </td>
        
                        <ng-template #Categoria_Elemento_Blanco>
                            <td *ngIf="mostrarColumna('Tipo_Elemento')"></td>
                        </ng-template>
                        <ng-template #Codigo_Elemento_Blanco>
                            <td *ngIf="mostrarColumna('Categoria_Elemento')"></td>
                        </ng-template>
                        
                        <td *ngIf="Codigos_Elementos_Prestamo.value.length > 1">
                            <button mat-mini-fab color="warn" aria-label="Borrar" (click)="borrarElemento(Numero_Elemento)">
                                <mat-icon>delete</mat-icon>
                            </button>
                        </td>
                    </tr>
                    <tr>
                        <td colspan="5" class="Boton_Agregar">
                            <button mat-fab extended color="accent" (click)="agregarElemento()">
                                <mat-icon>add</mat-icon>
                                Agregar Elemento
                            </button>
                        </td>
                    </tr>
                </ng-template>
            </table>
        </ng-container>


        <ng-container *ngIf="Fecha_Aprobacion">
            <mat-divider></mat-divider>
            
            <mat-form-field>
                <mat-label>Persona Que Aprueba</mat-label>
                <input matInput type="text"  [value]="getNombreUsuario(ID_Persona_Que_Aprueba.value)" disabled>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Observaciones Prestamo</mat-label>
                <input matInput type="text" [formControl]="Observaciones_Prestamo">
                <button mat-icon-button *ngIf="Observaciones_Prestamo.value && data.tipo === 'Aprobación'" matSuffix aria-label="Clear" (click)="borrarCampo(Observaciones_Prestamo)">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="Fecha_Recogida">
            <mat-divider></mat-divider>
    
            <mat-form-field>
                <mat-label>Persona Que Entrega</mat-label>
                <input matInput type="text"  [value]="getNombreUsuario(ID_Persona_Que_Entrega.value)" disabled>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Observaciones Entrega</mat-label>
                <input matInput type="text" [formControl]="Observaciones_Recogida">
                <button mat-icon-button *ngIf="Observaciones_Recogida.value && data.tipo === 'Recogida'" matSuffix aria-label="Clear" (click)="borrarCampo(Observaciones_Recogida)">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="Fecha_Devolucion">
            <mat-divider></mat-divider>
    
            <mat-form-field>
                <mat-label>Persona Que Recibe</mat-label>
                <input matInput type="text"  [value]="getNombreUsuario(ID_Persona_Que_Recibe.value)" disabled>
            </mat-form-field>
    
            <mat-form-field>
                <mat-label>Observaciones Devolución</mat-label>
                <input matInput type="text" [formControl]="Observaciones_Devolucion">
                <button mat-icon-button *ngIf="Observaciones_Devolucion.value" matSuffix aria-label="Clear" (click)="borrarCampo(Observaciones_Devolucion)">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </mat-form-field>
        </ng-container>

    </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="Gap" [ngClass]="{'Acciones': data.tipo === 'Aprobación'}">
    <div>
        <button mat-fab extended cdkFocusInitial color="accent" *ngIf="(formulario_elemento.valid && formulario_elemento.dirty) || Fecha_Aprobacion" (click)="enviarSolicitud(true)">
            <mat-icon>check_circle</mat-icon>
            <ng-container *ngIf="data.tipo === 'Recogida' || data.tipo === 'Devolución'; else respuestaGeneral">
                Confirmar {{ data.tipo === 'Recogida' ? 'recogida' : 'devolución' }}
            </ng-container>
            <ng-template #respuestaGeneral>
                Aceptar
            </ng-template>
        </button>
        <button mat-fab extended cdkFocusInitial color="warn" *ngIf="data.tipo === 'Aprobación'" (click)="enviarSolicitud(false)">
            <mat-icon>cancel</mat-icon>
            Rechazar
        </button>
    </div>
    <button mat-fab extended color="primary" (click)="Cancelar()">Cancelar</button>
</mat-dialog-actions>