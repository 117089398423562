import { Component, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Lista_Tipos_Documento } from 'src/app/models/tipos_documento';
import { Lista_Paises, Paises } from 'src/app/models/paises';
import { GeneralesService } from 'src/app/services/generales.service';
import { Frecuencia, Gravedad, GrupoSanguineo, RestriccionesAlimenticias, Sexo, TipoDocumento } from 'src/app/models/info_usuarios';
import { Lista_Restricciones_Alimenticias } from 'src/app/models/restricciones_alimenticias';
import { Lista_Frecuencia } from 'src/app/models/frecuencia';
import { Lista_Gravedad } from 'src/app/models/gravedad';
import { Lista_Grupo_Sanguineo } from 'src/app/models/grupo_sanguineo';
import { LoginService } from 'src/app/services/login.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../../services/popups.service';
import { EquipoService } from '../../../services/equipo.service';

@Component({
  selector: 'app-editar-informacion-general-dialog',
  templateUrl: './editar-informacion-general-dialog.component.html',
  styleUrls: ['./editar-informacion-general-dialog.component.css']
})
export class EditarInformacionGeneralDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Array<'Info_General' | 'Info_Especifica' | 'Ficha_Medica'>,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private generalesService: GeneralesService,
    private equipoService: EquipoService,
    private popupService: PopupService,
  )
  {
    this.getPaises();
    
    this.crearformInfoGeneral();
    this.crearformInfoEspecifica();
    this.crearformFichaMedica();
    this.Tiene_RNT = new FormControl()
    this.Tiene_RNT.setValue(this.RNT.value !== 'No tiene' ? true : false)

    this.Tiene_RNT.valueChanges.subscribe(
      res => this.RNT.setValue(res ? undefined : 'No tiene') 
    )
    // TODO: No olvidar quitar o desactivar este log //
    // this.form_Info_General.valueChanges.subscribe(
      // form => {
        // console.log(this.form_Info_General.value)
    //     console.log(this.form_Info_Especifica)
    //     console.log(this.form_Ficha_Medica)
      // }
    // )
    this.Sexo.valueChanges.subscribe( valor =>  {
        valor === 'Masculino' ? this.Ant_Per_Embarazo.get('Valor').setValue( false ) : this.Ant_Per_Embarazo.reset()
        valor === 'Masculino' ? this.Ant_Per_Embarazo.get('Explicacion').setValue( 'No aplica' ) : this.Ant_Per_Embarazo.reset()
      }
    )
    this.Restricciones_Alimenticias_Generales.valueChanges.subscribe( valor =>  valor !== 'Otra' ? this.Restricciones_Alimenticias_Especificas.setValue( 'Ningúna' ) : this.Restricciones_Alimenticias_Especificas.reset() )
    this.Fuma.get('Valor').valueChanges.subscribe( valor =>  {
        valor === false ? this.Fuma.get('Explicacion').setValue( 'No fuma' ) : this.Cigarrillos_Frecuencia.reset()
        valor === false ? this.Cigarrillos_Frecuencia.setValue( 'No fuma' ) : this.Cigarrillos_Frecuencia.reset()
      }
    )
    this.Realiza_Actividad_Fisica.get('Valor').valueChanges.subscribe( valor =>  {
        valor === false ? this.Actividad_Fisica_Cuales.setValue( 'No hace actividad física' ) : this.Actividad_Fisica_Cuales.reset()
        valor === false ? this.Actividad_Fisica_Frecuencia.setValue( 'No hace actividad física' ) : this.Actividad_Fisica_Frecuencia.reset()
      }
    )
    this.Toma_Medicamentos.get('Valor').valueChanges.subscribe(
      valor =>  this.Cuales_Dosis.setValue( valor === false ? 'No toma medicamentos' : '' )
    )
    this.Alguna_Fobia.get('Valor').valueChanges.subscribe( valor =>  {
        valor === false ? this.Fobia.setValue( 'No tiene fobias' ) : this.Fobia.reset()
        valor === false ? this.Gravedad.setValue( 'No tiene fobias' ) : this.Gravedad.reset()
      }
    )

  }

  //////////  MARCADOR: Informacion General /////////////////////////////////////////////////////////////////
  get Tipo_Documento()   { return this.form_Info_General.get('Tipo_Documento')   as FormControl<TipoDocumento> }
  get Documento()        { return this.form_Info_General.get('Documento')        as FormControl<number>        }
  get RNT()              { return this.form_Info_General.get('RNT')              as FormControl<number | 'No tiene'>        }
  get Sexo()             { return this.form_Info_General.get('Sexo')             as FormControl<Sexo>          }
  get Fecha_Nacimiento() { return this.form_Info_General.get('Fecha_Nacimiento') as FormControl<Date>          }

  get Telefono_Celular() { return this.form_Info_General.get('Telefono_Celular') as FormGroup                  }
    get Indicativo()     {   return this.Telefono_Celular.get('Indicativo')        as FormControl<number>      }
    get Telefono()       {   return this.Telefono_Celular.get('Telefono')          as FormControl<number>      }

  get Nacionalidad()     { return this.form_Info_General.get('Nacionalidad')     as FormControl<string>        }

  get Residencia()       { return this.form_Info_General.get('Residencia')       as FormGroup                  }
    get Pais()           {   return this.Residencia.get('Pais')                    as FormControl<string>     }
    get Estado()         {   return this.Residencia.get('Estado')                  as FormControl<string>     }
    get Ciudad()         {   return this.Residencia.get('Ciudad')                  as FormControl<string>     }
  //////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////


  //////////  MARCADOR: Informacion Específica /////////////////////////////////////////////////////////////////
  get Restricciones_Alimenticias_Generales()   { return this.form_Info_Especifica.get('Restricciones_Alimenticias_Generales')   as FormControl<RestriccionesAlimenticias>     }
  get Restricciones_Alimenticias_Especificas() { return this.form_Info_Especifica.get('Restricciones_Alimenticias_Especificas') as FormControl<string>     }
  get Contacto_Emergencia()                    { return this.form_Info_Especifica.get('Contacto_Emergencia')                    as FormGroup       }
    get Contacto_Nombre()                      {   return this.Contacto_Emergencia.get('Nombre')                                  as FormControl<string>   }
    get Contacto_Telefono()                    {   return this.Contacto_Emergencia.get('Telefono')                                as FormGroup     }
      get Contacto_Tel_Indicativo()            {     return this.Contacto_Telefono.get('Indicativo')                                as FormControl<number> }
      get Contacto_Tel_Numero()                {     return this.Contacto_Telefono.get('Numero')                                    as FormControl<number> }

  get Habitos()                                { return this.form_Info_Especifica.get('Habitos')                                as FormGroup       }
    get Cigarrillos()                          {   return this.Habitos.get('Cigarrillos')                                         as FormGroup     }
      get Fuma()                               {     return this.Cigarrillos.get('Fuma')                                            as FormGroup }
      get Cigarrillos_Frecuencia()             {     return this.Cigarrillos.get('Frecuencia')                                      as FormControl<Frecuencia | 'No fuma'> }
    get Actividad_Fisica()                     {   return this.Habitos.get('Actividad_Fisica')                                    as FormGroup     }
      get Realiza_Actividad_Fisica()           {     return this.Actividad_Fisica.get('Realiza_Actividad_Fisica')                   as FormGroup }
        get Actividad_Fisica_Cuales()            {     return this.Realiza_Actividad_Fisica.get('Explicacion')                          as FormControl }
      get Actividad_Fisica_Frecuencia()        {     return this.Actividad_Fisica.get('Frecuencia')                                 as FormControl<Frecuencia | 'No hace actividad física'> }
  //////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////
  
  
  //////////  MARCADOR: Ficha Médica /////////////////////////////////////////////////////////////////
  get EPS_Seguro()                           { return this.form_Ficha_Medica.get('EPS_Seguro')                       as FormControl<string>   }
  get Tipo_Sangre()                          { return this.form_Ficha_Medica.get('Tipo_Sangre')                      as FormGroup     }
    get Grupo_Sanguineo()                    { return this.Tipo_Sangre.get('Grupo_Sanguineo')                          as FormControl<GrupoSanguineo> }
    get RH()                                 { return this.Tipo_Sangre.get('RH')                                       as FormControl<'+' | '-'> }

  get Antecedentes_Personales()              { return this.form_Ficha_Medica.get('Antecedentes_Personales')          as FormGroup     }
    get Ant_Per_Alergias()                   {   return this.Antecedentes_Personales.get('Alergias')                   as FormGroup }
    get Ant_Per_Problemas_Cardiovasculares() {   return this.Antecedentes_Personales.get('Problemas_Cardiovasculares') as FormGroup }
    get Ant_Per_Problemas_Osteomusculares()  {   return this.Antecedentes_Personales.get('Problemas_Osteomusculares')  as FormGroup }
    get Ant_Per_Problemas_Respiratorios()    {   return this.Antecedentes_Personales.get('Problemas_Respiratorios')    as FormGroup }
    get Ant_Per_Enfermedades_Neurologicas()  {   return this.Antecedentes_Personales.get('Enfermedades_Neurologicas')  as FormGroup }
    get Ant_Per_Dolores_Nauseas_Desmayos()   {   return this.Antecedentes_Personales.get('Dolores_Nauseas_Desmayos')   as FormGroup }
    get Ant_Per_Embarazo()                   {   return this.Antecedentes_Personales.get('Embarazo')                   as FormGroup }
    get Ant_Per_Otro()                       {   return this.Antecedentes_Personales.get('Otro')                       as FormGroup }
  getValorAntecedente = (valor: FormGroup, tipo: 'Valor' | 'Explicacion') => valor.get(tipo) as FormControl<'Si' | 'No'>

  get Antecedentes_Familiares()              { return this.form_Ficha_Medica.get('Antecedentes_Familiares')          as FormGroup     }
    get Ant_Fam_Muerte_Prematura()           {   return this.Antecedentes_Familiares.get('Muerte_Prematura')           as FormControl<'Si' | 'No'> }
    get Ant_Fam_Problemas_Cardiovasculares() {   return this.Antecedentes_Familiares.get('Problemas_Cardiovasculares') as FormControl<'Si' | 'No'> }
    get Ant_Fam_Otro()                       {   return this.Antecedentes_Familiares.get('Otro')                       as FormControl<'Si' | 'No'> }

  get Medicamentos()                         { return this.form_Ficha_Medica.get('Medicamentos')                     as FormGroup     }
    get Toma_Medicamentos()                  {   return this.Medicamentos.get('Toma_Medicamentos')                     as FormGroup }
    get Cuales_Dosis()                       {   return this.Toma_Medicamentos.get('Explicacion')                        as FormControl<string> }

  get Fobias()                               { return this.form_Ficha_Medica.get('Fobias')                           as FormGroup     }
    get Alguna_Fobia()                       {   return this.Fobias.get('Alguna_Fobia')                                as FormGroup }
    get Fobia()                              {   return this.Alguna_Fobia.get('Explicacion')                                    as FormControl<string> }
    get Gravedad()                           {   return this.Fobias.get('Gravedad')                                    as FormControl<Gravedad | 'No tiene fobias'> }
//////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////

get validacionSubmit() {
  return (!this.FormulariosMostrados.includes('Info_General') || this.form_Info_General.valid) &&
        (!this.FormulariosMostrados.includes('Info_Especifica') || this.form_Info_Especifica.valid) &&
        (!this.FormulariosMostrados.includes('Ficha_Medica') || this.form_Ficha_Medica.valid)
}

get FormulariosMostrados() {return this.data}

  Lista_Tipos_Documento = Lista_Tipos_Documento;
  Lista_Grupo_Sanguineo = Lista_Grupo_Sanguineo;
  Lista_Restricciones_Alimenticias = Lista_Restricciones_Alimenticias;
  Lista_Frecuencia = Lista_Frecuencia;
  Lista_Gravedad = Lista_Gravedad;

  Array_Paises: Paises[] = [];
  Paises_Cargados = false;
  Error_Paises = false;

  CiudadesPais = [];
  Ciudades_Cargadas = false;

  Tiene_RNT: FormControl<boolean>;

  form_Info_General   : FormGroup;
  form_Info_Especifica: FormGroup;
  form_Ficha_Medica   : FormGroup;

  borrarCampo  = this.generalesService.borrarCampoForm;

  Cargado = () => this.Paises_Cargados;

  ExplicacionGroup = {
    Valor       : ['', [Validators.required]],
    Explicacion : ['', [Validators.required]],
  }

  crearformInfoGeneral(): void {
    this.form_Info_General = this.formBuilder.group({
      Tipo_Documento   : ['', [Validators.required]],
      Documento        : ['', [Validators.required]],
      RNT              : ['No tiene', [Validators.required]],
      Sexo             : ['', [Validators.required]],
      Fecha_Nacimiento : ['', [Validators.required]],
      Telefono_Celular : this.formBuilder.group({
        Indicativo     : ['', [Validators.required]],
        Telefono       : ['', [Validators.required]],
      }),
      Nacionalidad     : ['', [Validators.required]],
      Residencia       : this.formBuilder.group({
        Pais           : ['', [Validators.required]],
        Estado         : ['', [Validators.required]],
        Ciudad         : ['', [Validators.required]],
      })
    })
    const Info_General = this.loginService.InfoUsuario.Informacion_General
    if(Info_General) this.form_Info_General.patchValue( Info_General )
  }

  crearformInfoEspecifica(): void {
    this.form_Info_Especifica = this.formBuilder.group({
      Restricciones_Alimenticias_Generales   : ['', [Validators.required]],
      Restricciones_Alimenticias_Especificas : ['', [Validators.required]],
      Contacto_Emergencia                    : this.formBuilder.group({
        Nombre                               : ['', [Validators.required]],
        Telefono                             : this.formBuilder.group({
          Indicativo                         : ['', [Validators.required]],
          Numero                             : ['', [Validators.required]],
        }),
      }),
      Habitos                                : this.formBuilder.group({
        Cigarrillos                          : this.formBuilder.group({
          Fuma                               : this.formBuilder.group(this.ExplicacionGroup),
          Frecuencia                         : ['', [Validators.required]],
        }),
        Actividad_Fisica                     : this.formBuilder.group({
          Realiza_Actividad_Fisica           : this.formBuilder.group(this.ExplicacionGroup),
          Frecuencia                         : ['', [Validators.required]],
        })
      }),
    })

    const Info_Especifica = this.loginService.InfoUsuario.Informacion_Especifica
    if(Info_Especifica) this.form_Info_Especifica.patchValue(Info_Especifica)

  }
  
  crearformFichaMedica(): void {
    this.form_Ficha_Medica = this.formBuilder.group({
      EPS_Seguro                   : ['', [Validators.required]],
      Tipo_Sangre                  : this.formBuilder.group({
        Grupo_Sanguineo            : ['', [Validators.required]],
        RH                         : ['', [Validators.required]],
      }),
      Antecedentes_Personales      : this.formBuilder.group({
        Alergias                   : this.formBuilder.group(this.ExplicacionGroup),
        Problemas_Cardiovasculares : this.formBuilder.group(this.ExplicacionGroup),
        Problemas_Osteomusculares  : this.formBuilder.group(this.ExplicacionGroup),
        Problemas_Respiratorios    : this.formBuilder.group(this.ExplicacionGroup),
        Enfermedades_Neurologicas  : this.formBuilder.group(this.ExplicacionGroup),
        Dolores_Nauseas_Desmayos   : this.formBuilder.group(this.ExplicacionGroup),
        Embarazo                   : this.formBuilder.group(this.ExplicacionGroup),
        Otro                       : this.formBuilder.group(this.ExplicacionGroup),
      }),
      Antecedentes_Familiares      : this.formBuilder.group({
        Muerte_Prematura           : this.formBuilder.group(this.ExplicacionGroup),
        Problemas_Cardiovasculares : this.formBuilder.group(this.ExplicacionGroup),
        Otro                       : this.formBuilder.group(this.ExplicacionGroup),
      }),
      Medicamentos                 : this.formBuilder.group({
        Toma_Medicamentos          : this.formBuilder.group(this.ExplicacionGroup),
      }),
      Fobias                       : this.formBuilder.group({
        Alguna_Fobia               : this.formBuilder.group(this.ExplicacionGroup),
        Gravedad                   : ['', [Validators.required]],
      })
    })

    const Ficha_Medica = this.loginService.InfoUsuario.Ficha_Medica
    if(Ficha_Medica) this.form_Ficha_Medica.patchValue(Ficha_Medica)
  }

  getPaises() {
    this.Paises_Cargados = false;
    this.Error_Paises = false;

    this.http.get<Lista_Paises[]>('../assets/Paises.json')
      .pipe(
        map (
          respuesta => {
            return respuesta.map( (pais): Paises => ({
              Nombre: pais.translations.es || pais.name,
              Codigo: pais.iso2,
              // NOTA: hay algunos indicativos con + o con - por lo que acá se quitan y se dejan solo numeros //
              Indicativo: Number( pais.phone_code.replace(/\D/g, '') ),
              Estados: pais.states.map(
                estado => ({
                  Nombre: estado.name,
                  Ciudades: estado.cities.map(
                    ciudad => ciudad.name
                  )
                })
              )
            })).sort((a, b) => (a.Nombre > b.Nombre) ? 1 : -1);
          }
        ),
      ).toPromise()
      .then(
        respuesta => {
          this.Paises_Cargados = true;
          this.Array_Paises = respuesta
        }
      )
  }

  getEstados = (pais: string) =>
    this.Array_Paises.find( resultado => resultado.Nombre === pais ).Estados
  getCiudades = (pais: string, estado: string) => 
    this.getEstados( pais ).find( resultado => resultado.Nombre === estado )?.Ciudades

    actualizarInfoUsuario() {
    // console.log('Info_General', this.form_Info_General.value)
    // console.log('Info_Especifica', this.form_Info_Especifica.value)
    // console.log('Ficha_Medica', this.form_Ficha_Medica.value)

    const form_tocado = this.form_Info_General.touched || this.form_Info_Especifica.touched || this.form_Ficha_Medica.touched

    if (!form_tocado) return

    this.loginService.InfoUsuario.Informacion_General = this.form_Info_General.value
    this.loginService.InfoUsuario.Informacion_Especifica = this.form_Info_Especifica.value
    this.loginService.InfoUsuario.Ficha_Medica = this.form_Ficha_Medica.value

    this.equipoService.actualizarInfoUsuario()
      .then(
        res => {
          const PopupMensaje = `Se ha actualizado la información de tú usuario  ${this.loginService.InfoUsuario.email}`;
          this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
        }
      )
      .catch(
        res => {
          const PopupMensaje = `Ha habido un problema creando el usuario  ${this.loginService.InfoUsuario.email}`;
          this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
        }
      )
  }

}
