import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EstadoSolicitudPluralPipe } from './estado-solicitud-plural.pipe';




@NgModule({
  declarations: [
  
    EstadoSolicitudPluralPipe
  ],
  imports: [
    CommonModule,
  ],
  exports: [
    EstadoSolicitudPluralPipe
  ]
})
export class PipesModule { }
