<div class="contenedor-tarjetas">

    <mat-card class="tarjetas">
        <mat-card-header>
            <mat-card-title>{{InfoUsuario.Nombres}} {{InfoUsuario.Apellidos}}</mat-card-title>
            <mat-card-subtitle>Email: {{InfoUsuario.email}}</mat-card-subtitle>
        </mat-card-header>

        <input #upload type="file" accept="image/*" hidden (change)="subirImagen($event)"/>
        <div mat-card-image class="Contenedor_Imagen_Usuario" *ngIf="URLfoto" [style.backgroundImage]="'url('+ URLfoto +')'">
            <button mat-mini-fab color="primary" (click)="upload.click()"><mat-icon>edit</mat-icon></button>
        </div>
        <mat-card-content>
            <p>Categoría: <mat-chip>{{InfoUsuario.Categoria}}</mat-chip></p>
            <p>Idiomas:</p>
            <mat-chip-set cdkDropList cdkDropListOrientation="horizontal">
                <mat-chip *ngFor="let idioma of InfoUsuario.Idiomas">{{ idioma.Idioma }}({{idioma.Nivel}})</mat-chip>
            </mat-chip-set>
            <div class="grid-columnas">
                <div *ngIf="InfoUsuario.Especialidades.Naturaleza.Activa" class="Cuadro-Naturaleza">
                    Naturaleza
                    <mat-chip-listbox>
                        <mat-chip *ngFor="let especifica of InfoUsuario.Especialidades.Naturaleza.Especificas">{{especifica}}</mat-chip>
                    </mat-chip-listbox>
                </div>
                <div *ngIf="InfoUsuario.Especialidades.Cultura.Activa" class="Cuadro-Cultura">
                    Cultura
                    <mat-chip-listbox>
                        <mat-chip *ngFor="let especifica of InfoUsuario.Especialidades.Cultura.Especificas">{{especifica}}</mat-chip>
                    </mat-chip-listbox>
                </div>
                <div *ngIf="InfoUsuario.Especialidades.Deporte.Activa" class="Cuadro-Deporte">
                    Deporte
                    <mat-chip-listbox>
                        <mat-chip *ngFor="let especifica of InfoUsuario.Especialidades.Deporte.Especificas">{{especifica}}</mat-chip>
                    </mat-chip-listbox>
                </div>
            </div>
            <p>Nivel: <mat-chip>{{InfoUsuario.Puntaje ? InfoUsuario.Puntaje : 'Sin definir'}}</mat-chip></p>
            <p>Calificación: <mat-chip>{{InfoUsuario.Calificacion ? InfoUsuario.Calificacion : 'Sin calificaciones'}}</mat-chip></p>
        </mat-card-content>
        <!-- <mat-card-actions>
            <button mat-button>LIKE</button>
            <button mat-button>SHARE</button>
        </mat-card-actions> -->
    </mat-card>
    
    <ng-container *ngIf="!InfoUsuario.Informacion_General && !InfoUsuario.Informacion_Especifica && !InfoUsuario.Ficha_Medica; else tarjetas">
        <button mat-fab extended color="accent" (click)="editar_Usuario()">Llenar formulario<mat-icon>edit</mat-icon></button>
    </ng-container>
    <ng-template #tarjetas>
        <mat-card class="tarjetas">
            <mat-card-header>
                <mat-card-title>Información general</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>Documento: {{ InfoUsuario.Informacion_General.Tipo_Documento }} {{ InfoUsuario.Informacion_General.Documento }}</p>
                <p *ngIf="InfoUsuario.Informacion_General.RNT">RNT: {{ InfoUsuario.Informacion_General.RNT }}</p>
                <p>Sexo: {{ InfoUsuario.Informacion_General.Sexo }}</p>
                <p>Fecha Nacimiento: {{ InfoUsuario.Informacion_General.Fecha_Nacimiento | date }}</p>
                <p>Telefono Celular: ({{ InfoUsuario.Informacion_General.Telefono_Celular.Indicativo }}) {{ InfoUsuario.Informacion_General.Telefono_Celular.Telefono | mask: '000 000-0000' }}</p>
                <p>Nacionalidad: {{ InfoUsuario.Informacion_General.Nacionalidad }}</p>
                <p>Residencia: {{ InfoUsuario.Informacion_General.Residencia.Pais }}, {{ InfoUsuario.Informacion_General.Residencia.Estado }}, {{ InfoUsuario.Informacion_General.Residencia.Ciudad }}</p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-fab extended color="primary" (click)="editar_Usuario('Info_General')">Editar<mat-icon>edit</mat-icon></button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="tarjetas">
            <mat-card-header>
                <mat-card-title>Información específica</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>Restricciones Alimenticias: {{
                    InfoUsuario.Informacion_Especifica.Restricciones_Alimenticias_Generales !== 'Otra' ?
                    InfoUsuario.Informacion_Especifica.Restricciones_Alimenticias_Generales :
                    InfoUsuario.Informacion_Especifica.Restricciones_Alimenticias_Especificas
                }}
                </p>
                <p>Contacto de Emergencia: {{ InfoUsuario.Informacion_Especifica.Contacto_Emergencia.Nombre }}  ({{ InfoUsuario.Informacion_Especifica.Contacto_Emergencia.Telefono.Indicativo }}) {{ InfoUsuario.Informacion_Especifica.Contacto_Emergencia.Telefono.Numero | mask: '000 000-0000' }}</p>
                <p>Habitos:</p>
                <p *ngIf="InfoUsuario.Informacion_Especifica.Habitos.Cigarrillos.Fuma.Valor">
                    Cigarrillos: ({{ InfoUsuario.Informacion_Especifica.Habitos.Cigarrillos.Fuma.Explicacion }}) {{ InfoUsuario.Informacion_Especifica.Habitos.Cigarrillos.Frecuencia }}
                </p>
                <p *ngIf="InfoUsuario.Informacion_Especifica.Habitos.Actividad_Fisica.Realiza_Actividad_Fisica.Valor">
                    Actividad Física: {{ InfoUsuario.Informacion_Especifica.Habitos.Actividad_Fisica.Realiza_Actividad_Fisica.Explicacion }} ({{ InfoUsuario.Informacion_Especifica.Habitos.Actividad_Fisica.Frecuencia }})
                </p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-fab extended color="primary" (click)="editar_Usuario('Info_Especifica')">Editar<mat-icon>edit</mat-icon></button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="tarjetas">
            <mat-card-header>
                <mat-card-title>Ficha médica</mat-card-title>
                <mat-card-subtitle></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <p>EPS/Seguro médico: {{ InfoUsuario.Ficha_Medica.EPS_Seguro }}</p>
                <p>Tipo de Sangre: {{ InfoUsuario.Ficha_Medica.Tipo_Sangre.Grupo_Sanguineo }} ({{ InfoUsuario.Ficha_Medica.Tipo_Sangre.RH }})</p>
                <h3>Antecedentes médicos personales:</h3>
                <ng-container *ngIf="ResumenAntecedentesMedicos(InfoUsuario.Ficha_Medica.Antecedentes_Personales).length; else sinAntecedentesPersonales">
                    <p *ngFor="let elemento of ResumenAntecedentesMedicos(InfoUsuario.Ficha_Medica.Antecedentes_Personales)">
                        {{ elemento.key }} {{ elemento.valor.Explicacion }}
                    </p>
                </ng-container>
                <ng-template #sinAntecedentesPersonales>
                    <p>Sin antecedentes</p>
                </ng-template>
                
                <h3>Antecedentes médicos familiares:</h3>
                <ng-container *ngIf="ResumenAntecedentesMedicos(InfoUsuario.Ficha_Medica.Antecedentes_Familiares).length; else sinAntecedentesFamiliares">
                    <p *ngFor="let elemento of ResumenAntecedentesMedicos(InfoUsuario.Ficha_Medica.Antecedentes_Familiares)">
                        {{ elemento.key }} {{ elemento.valor.Explicacion }}
                    </p>
                </ng-container>
                <ng-template #sinAntecedentesFamiliares>
                    <p>Sin antecedentes</p>
                </ng-template>

                <p *ngIf="InfoUsuario.Ficha_Medica.Medicamentos.Toma_Medicamentos.Valor">
                    Medicamentos: {{ InfoUsuario.Ficha_Medica.Medicamentos.Toma_Medicamentos.Explicacion }}
                </p>
                <p *ngIf="InfoUsuario.Ficha_Medica.Fobias.Alguna_Fobia.Valor">
                    Fobias: {{ InfoUsuario.Ficha_Medica.Fobias.Alguna_Fobia.Explicacion }} ({{ InfoUsuario.Ficha_Medica.Fobias.Gravedad }})
                </p>
            </mat-card-content>
            <mat-card-actions>
                <button mat-fab extended color="primary" (click)="editar_Usuario('Ficha_Medica')">Editar<mat-icon>edit</mat-icon></button>
            </mat-card-actions>
        </mat-card>
    </ng-template>
    

    
</div>