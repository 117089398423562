import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.routes';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';
import { environment } from 'src/environments/environment';

// NOTA: Modulos de terceros //
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { provideNgxMask } from 'ngx-mask';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';

// NOTA: Modulos personales //
import { MaterialModule } from './shared/material/material.module';
import { PopupsModule } from './shared/popups/popups.module';
import { WidgetsModule } from './shared/widgets/widgets.module';

// NOTA: Components //
import { MenuComponent } from './shared/menu/menu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { LoginComponent } from './components/login/login.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { ProductosComponent } from './components/productos/productos.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { PrestamosComponent } from './components/prestamos/prestamos.component';
import { PipesModule } from './pipes/pipes.module';
import { MatSortModule } from '@angular/material/sort';

registerLocaleData(localeEs);


@NgModule({
  declarations: [
    AppComponent,
    MenuComponent,
    DashboardComponent,
    LoginComponent,
    EquipoComponent,
    ReservasComponent,
    InventarioComponent,
    ProductosComponent,
    UsuarioComponent,
    PrestamosComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    MaterialModule,
    PipesModule,
    WidgetsModule,
    PopupsModule,
    provideFirebaseApp(() => initializeApp( environment.firebase )),
    provideFirestore(() => getFirestore()),
    provideAuth(() => getAuth()),
    provideStorage(() => getStorage()),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es' },
    // NOTA: esta línea hace que todos los inputs de forms tengan apariencia tipo "outline" por defecto //
    {provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: {appearance: 'outline'}},
    provideNgxMask(),

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
