import { Injectable } from '@angular/core';

// import { AngularFirestore, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';

// import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent } from 'angular-calendar';
import { Bloqueo, InformacionBloqueo, Reserva_Directa, Reserva_Aliados, TipoEvento, InfoReservaDirectaRespuesta, InformacionReservaAliados } from '../models/reservas.model';
import { LoginService } from './login.service';
import { DocumentReference, Firestore, addDoc, collection, collectionChanges, deleteDoc, doc, onSnapshot, setDoc, updateDoc } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class ReservasService {

  constructor(
    private firestore: Firestore,
    private loginService: LoginService,
  ) {
    this.getEventos();
  }

  eventos: CalendarEvent<{Tipo: TipoEvento}>[];

  ReservasDirectas: InfoReservaDirectaRespuesta[];
  ReservasAliados: InformacionReservaAliados[];
  Bloqueos: InformacionBloqueo[];

  getEventos(): void {
    let eventosReservasDirectas: Reserva_Directa[];
    let eventosReservasAliados: Reserva_Aliados[];
    let eventosBloqueos: Bloqueo[];

    onSnapshot(collection(this.firestore, 'reservas_directas'), coleccion => {
      const data: InfoReservaDirectaRespuesta[] = coleccion.docs.map(documento => ({...documento.data(), id: documento.id} as InfoReservaDirectaRespuesta))

      eventosReservasDirectas = data.map(evento => new Reserva_Directa(evento) );
      this.eventos = [].concat(eventosReservasDirectas).concat(eventosReservasAliados).concat(eventosBloqueos);

      this.ReservasDirectas = data;
    })
    onSnapshot(collection(this.firestore, 'reservas_aliados'), coleccion => {
      const data: InformacionReservaAliados[] = coleccion.docs.map(documento => ({...documento.data(), id: documento.id} as InformacionReservaAliados))

      eventosReservasAliados = data.map(evento => new Reserva_Aliados(evento) );
      this.eventos = [].concat(eventosReservasDirectas).concat(eventosReservasAliados).concat(eventosBloqueos);

      this.ReservasAliados = data;
    })
    onSnapshot(collection(this.firestore, 'bloqueos'), coleccion => {
      const data: InformacionBloqueo[] = coleccion.docs.map(documento => ({...documento.data(), id: documento.id} as InformacionBloqueo))

      eventosBloqueos = data.map(evento => new Bloqueo(evento) );
      this.eventos = [].concat(eventosReservasDirectas).concat(eventosReservasAliados).concat(eventosBloqueos);

      this.Bloqueos = data;
    })
  }

  moverFechaBloqueo({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): Promise<void> {
      const documento = doc(this.firestore, `bloqueos/${event.id}`)
      const info = {
        Fecha_Bloqueo: {
          Inicio: newStart,
          Fin: newEnd
        }
      }
      return updateDoc(documento, info )
  }

  agregarBloqueo = ( contenido: InformacionBloqueo ): Promise<DocumentReference<unknown>> =>
    addDoc(collection(this.firestore, 'bloqueos'), contenido)

  editarBloqueo( contenido: InformacionBloqueo ): Promise<void> {
    const ID = contenido.id;
    delete contenido.id;

    return setDoc(doc(this.firestore, `bloqueos/${ID}`), contenido)
  }

  borrarBloqueo = ( contenido: InformacionBloqueo ): Promise<void> =>
    deleteDoc(doc(this.firestore, `bloqueos/${contenido.id}`))

  confirmarReserva(tipo: 'directas'| 'aliados', id: string) {
    const info = {
      Estado_Reserva: 'Confirmada',
      UID_Quien_Confirma: this.loginService.InfoUsuario.uid
    }
    return updateDoc(doc(this.firestore, `reservas_${tipo}/${id}`), info)
  }

}

