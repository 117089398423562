<h2 mat-dialog-title>Recordar Contraseña</h2>
<mat-dialog-content class="mat-typography">
    <p>Para restablecer tu contraseña, escribe tu correo electrónico registrado</p>
    <mat-form-field class="Input">
        <mat-label>Correo</mat-label>
        <input matInput type="text" [formControl]="Correo">
        <button mat-button *ngIf="Correo.value" matSuffix mat-icon-button aria-label="Clear" (click)="Correo.reset()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button cdkFocusInitial [disabled]="Correo.invalid" (click)="recordarPassword(Correo.value)">Enviar</button>
</mat-dialog-actions>