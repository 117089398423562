<h2 mat-dialog-title>{{data?.Tipo === 'Edición' ? 'Editar' : 'Nueva'}} Fecha Bloqueada</h2>
<mat-dialog-content>
    <form [formGroup]="formNuevoBloqueo">
        <mat-form-field color="accent" class="Input">
            <mat-label>Resumen</mat-label>
            <input matInput type="text" formControlName="Resumen">
            <button mat-button *ngIf="Resumen.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Resumen)">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>
        <br>
        <mat-form-field color="accent" class="Input">
            <mat-label>Descripción</mat-label>
            <textarea matInput formControlName="Descripcion"></textarea>
            <button mat-button *ngIf="Descripcion.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Descripcion)">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>
        <br>
        <br>
        <mat-form-field appearance="fill" class="Input">
            <mat-label>Rango de fechas bloqueadas</mat-label>
            <mat-date-range-input [formGroup]="Fecha_Bloqueo" [rangePicker]="picker">
                <input matStartDate formControlName="Inicio" placeholder="Inicio bloqueo">
                <input matEndDate formControlName="Fin" placeholder="Fin bloqueo">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="Fecha_Inicio_Bloqueo.hasError('matStartDateInvalid')">Error en fecha de inicio</mat-error>
            <mat-error *ngIf="Fecha_Fin_Bloqueo.hasError('matEndDateInvalid')">Error en fecha de fin</mat-error>
        </mat-form-field>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="data?.Tipo === 'Edición'" color="warn" mat-raised-button (click)="borrarBloqueo(data.InfoEvento)">Eliminar Bloqueo</button>
    <button mat-button (click)="cancelarEdicion()">Cancelar</button>
    <button mat-button cdkFocusInitial (click)="guardarBloqueo()" [disabled]="formNuevoBloqueo.invalid">Guardar</button>
</mat-dialog-actions>