<h2 mat-dialog-title>Agregar persona</h2>
<mat-dialog-content class="mat-typography">
    <p>Porfavor llenar los datos básicos de la nueva persona a agregar</p>

    <form [formGroup]="formulario_persona">
        <mat-stepper orientation="vertical" linear #stepper>
            <mat-step [stepControl]="Nombres" errorMessage="Debes ingresar tu nombre">
                <ng-template matStepLabel>Datos básicos</ng-template>
                <div class="Contenedor_Grid">
                    <mat-form-field>
                        <mat-label>Nombres</mat-label>
                        <input matInput type="text" [formControl]="Nombres">
                        <button mat-icon-button *ngIf="Nombres.value" matSuffix aria-label="Clear" (click)="borrarCampo(Nombres)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Apellidos</mat-label>
                        <input matInput type="text" [formControl]="Apellidos">
                        <button mat-icon-button *ngIf="Apellidos.value" matSuffix aria-label="Clear" (click)="borrarCampo(Apellidos)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput type="email" [formControl]="email">
                        <button mat-icon-button *ngIf="email.value" matSuffix aria-label="Clear" (click)="borrarCampo(email)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Categoría</mat-label>
                        <mat-select [formControl]="Categoria">
                            <mat-option *ngFor="let categoria of Lista_Categorias_Staff" [value]="categoria">{{categoria}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div>
                    <button mat-raised-button color="warn"  [disabled]="formulario_persona.invalid" (click)="verificarCorreo()">Next</button>
                </div>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Idiomas</ng-template>
                <div class="Contenedor_Tabla">
                    <table formArrayName="Idiomas" *ngIf="Idiomas.controls.length">
                        <tr>
                            <th>Idiomas</th>
                            <th *ngIf="Con_Idioma">Nivel</th>
                            <th>Quitar</th>
                        </tr>
                        <tr *ngFor="let idioma of Idiomas.controls; let i = index" [formGroupName]="i">
                            <td>
                                <mat-form-field>
                                    <mat-label>Idiomas</mat-label>
                                    <mat-select formControlName="Idioma">
                                        <ng-container *ngFor="let idiomaDisponible of Lista_Idiomas">
                                            <mat-option [value]="idiomaDisponible" [disabled]="Idioma_Seleccionado(idiomaDisponible)">{{ idiomaDisponible }}</mat-option>
                                        </ng-container>
                                        
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td *ngIf="idioma.controls.Idioma.valid">
                                <mat-form-field>
                                    <mat-label>Nivel</mat-label>
                                    <mat-select formControlName="Nivel">
                                        <mat-option value="Bajo">Bajo</mat-option>
                                        <mat-option value="Medio">Medio</mat-option>
                                        <mat-option value="Alto">Alto</mat-option>
                                        <mat-option value="Nativo">Nativo</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </td>
                            <td>
                                <button mat-icon-button class="Sin_Margen" aria-label="Borrar" (click)="borrarIdioma(i)">
                                    <mat-icon color="warn">delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    </table>
                    <button mat-raised-button class="Boton_Agregar" color="primary" *ngIf="Idiomas.controls.length < Lista_Idiomas.length" (click)="AgregarIdioma()">
                        Agregar Idioma <mat-icon>add</mat-icon>
                    </button>
                </div>
                <div>
                    <button mat-raised-button color="warn" [disabled]="formulario_persona.invalid" matStepperPrevious>Back</button>
                    <button mat-raised-button color="warn" [disabled]="formulario_persona.invalid || !Idiomas.value.length" matStepperNext>Next</button>
                </div>
            </mat-step>
            <mat-step formGroupName="Especialidades">
                <ng-template matStepLabel>Especialidades</ng-template>

                <ng-container *ngTemplateOutlet="tablaEspecialidad; context: {tipo_especialidad: 'Naturaleza'}"></ng-container>
                <ng-container *ngTemplateOutlet="tablaEspecialidad; context: {tipo_especialidad: 'Cultura'}"></ng-container>
                <ng-container *ngTemplateOutlet="tablaEspecialidad; context: {tipo_especialidad: 'Deporte'}"></ng-container>
            
                
                <ng-template #tablaEspecialidad let-tipo_especialidad="tipo_especialidad">
                    <ng-container [formGroupName]="tipo_especialidad">
                        <mat-slide-toggle formControlName="Activa">{{ tipo_especialidad }}</mat-slide-toggle>
                        <ng-container *ngIf="this['Especialidades_' + tipo_especialidad].value.Activa">
                            <table>
                                <tr *ngIf="this['Especialidades_' + tipo_especialidad].value.Especificas.length">
                                    <th>Especialidad Específica</th>
                                </tr>
                                <tr>
                                    <td>
                                        <mat-form-field>
                                            <mat-label>Especialidad Específica</mat-label>
                                            <mat-select formControlName="Especificas" multiple>
                                                <mat-option *ngFor="let especialidad of this['Lista_Especialidades_' + tipo_especialidad]" [value]="especialidad">{{ especialidad }}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </td>
                                </tr>
                            </table>
                        </ng-container>
                        
                    </ng-container>
                    
                </ng-template>
                
                <br>
                <button mat-raised-button color="warn" [disabled]="formulario_persona.invalid" matStepperPrevious>Back</button>
                <button mat-raised-button color="warn" [disabled]="formulario_persona.invalid" matStepperNext>Next</button>
            </mat-step>
            <mat-step formGroupName="Permisos">
                <ng-template matStepLabel>Permisos</ng-template>

                <ng-container *ngFor="let permiso of Lista_Permisos">
                    <mat-slide-toggle [formControlName]="permiso">{{permiso}}</mat-slide-toggle>
                    <br>
                </ng-container>
                
                <button mat-raised-button color="warn" [disabled]="formulario_persona.invalid" matStepperPrevious>Back</button>
                <!-- <button mat-raised-button mat-dialog-close color="accent" [disabled]="formulario_persona.invalid" (click)="GuardarNuevaPersona()">Guardar nueva persona</button> -->
                <button mat-fab extended mat-dialog-close color="accent" *ngIf="formulario_persona.valid" [disabled]="formulario_persona.invalid" (click)="GuardarNuevaPersona()">Guardar nueva persona</button>
            </mat-step>
        </mat-stepper>
        
        <!-- <mat-form-field>
            <mat-label>Cumpleaños</mat-label>
            <input matInput [matDatepicker]="dp3" disabled [formControl]="Birthday">
            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
        </mat-form-field> -->
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-fab extended mat-dialog-close color="warn">Cancelar</button>
    <!-- <button mat-raised-button mat-dialog-close cdkFocusInitial (click)="accion()" *ngIf="formulario_persona.valid">Aceptar</button> -->
</mat-dialog-actions>