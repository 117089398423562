import { Component } from '@angular/core';
import { Permisos } from 'src/app/models/info_usuarios';
import { LoginService } from 'src/app/services/login.service';
import { PopupService } from '../../services/popups.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent {

  constructor(
    public loginService: LoginService,
  ) { }

  confirmacionLogOut = () => this.loginService.confirmacionLogOut();

  Permiso = (permiso: Permisos) => this.loginService.InfoUsuario?.Permisos?.includes(permiso);
  
}
