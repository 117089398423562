import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';

import { MaterialModule } from '../material/material.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { WidgetsModule } from '../widgets/widgets.module';

import { ConfirmarAccionDialogComponent } from './confirmar-accion-dialog/confirmar-accion-dialog.component';
import { RecordarPasswordDialogComponent } from './recordar-password-dialog/recordar-password-dialog.component';
import { AgregarPersonaDialogComponent } from './agregar-persona-dialog/agregar-persona-dialog.component';
import { EditarInformacionGeneralDialogComponent } from './editar-informacion-general-dialog/editar-informacion-general-dialog.component';
import { DefinirNivelDialogComponent } from './definir-nivel-dialog/definir-nivel-dialog.component';
import { EdicionBloqueoComponent } from './edicion-bloqueo/edicion-bloqueo.component';
import { EdicionReservasComponent } from './edicion-reservas/edicion-reservas.component';
import { AgregarInventarioComponent } from './agregar-inventario/agregar-inventario.component';
import { ImagenGrandeComponent } from './imagen-grande/imagen-grande.component';
import { SolicitudPrestamoDialogComponent } from './solicitud-prestamo-dialog/solicitud-prestamo-dialog.component';


@NgModule({
  declarations: [
    ConfirmarAccionDialogComponent,
    RecordarPasswordDialogComponent,
    AgregarPersonaDialogComponent,
    EditarInformacionGeneralDialogComponent,
    DefinirNivelDialogComponent,
    EdicionBloqueoComponent,
    EdicionReservasComponent,
    AgregarInventarioComponent,
    ImagenGrandeComponent,
    SolicitudPrestamoDialogComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MaterialModule,
    NgxMaskDirective,
    NgxMaskPipe,
    WidgetsModule,
    PipesModule,
  ],
  exports: [
    ConfirmarAccionDialogComponent,
    RecordarPasswordDialogComponent,
    AgregarPersonaDialogComponent,
    EditarInformacionGeneralDialogComponent,
    DefinirNivelDialogComponent,
    EdicionBloqueoComponent,
    EdicionReservasComponent,
    AgregarInventarioComponent,
    ImagenGrandeComponent,
    SolicitudPrestamoDialogComponent,
    NgxMaskDirective,
    NgxMaskPipe,
  ]
})
export class PopupsModule { }
