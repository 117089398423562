import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-imagen-grande',
  templateUrl: './imagen-grande.component.html',
  styleUrls: ['./imagen-grande.component.css']
})
export class ImagenGrandeComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataPopup,
  ) { }

}


interface DataPopup {
  UrlImagen: string;
}