import { Injectable } from '@angular/core';
import { Categorias_Equipos, Inventario, SolicitudesPrestamos, Temas_Libros, TipoElementoInventario } from '../models/inventario';
import { Firestore, Timestamp, addDoc, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, setDoc, updateDoc } from '@angular/fire/firestore';
import { BehaviorSubject } from 'rxjs';
import { ImagenesService } from './imagenes.service';
import { PopupService } from './popups.service';

@Injectable({
  providedIn: 'root'
})
export class InventariosService {

  constructor(
    private firestore: Firestore,
    private popupService: PopupService,
    private imagenesService: ImagenesService,
  ) {
    this.getEquipos();
    this.getPrestamos();
  }

  coleccionInventario = collection(this.firestore,'inventario');
  coleccionPrestamos = collection(this.firestore,'prestamos');

  Inventario: BehaviorSubject<Inventario[]> = new BehaviorSubject<Inventario[]>([]);;
  Prestamos: BehaviorSubject<SolicitudesPrestamos[]> = new BehaviorSubject<SolicitudesPrestamos[]>([]);;

  getEquipos() {
    onSnapshot(this.coleccionInventario, coleccion => {
      const docs: Inventario[] = coleccion.docs.map( documento =>  (
          {
            ...documento.data(),
            Fecha_Compra: (documento.data().Fecha_Compra as unknown as Timestamp).toDate()
          }
        ) as Inventario
      )
      
      this.Inventario.next(docs)
    })
  }
  getPrestamos() {
    onSnapshot(this.coleccionPrestamos, coleccion => {
      const docs: SolicitudesPrestamos[] = coleccion.docs.map( documento =>  (
          {
            ...documento.data(),
            Fecha_Solicitud: (documento.data().Fecha_Solicitud as unknown as Timestamp).toDate(),
            Fecha_Prestamo: {
              Inicio: (documento.data().Fecha_Prestamo.Inicio as unknown as Timestamp).toDate(),
              Fin: (documento.data().Fecha_Prestamo.Fin as unknown as Timestamp).toDate()
            },
            Fecha_Aprobacion: documento.data().Fecha_Aprobacion ? (documento.data().Fecha_Aprobacion as unknown as Timestamp).toDate() : '',
            Fecha_Devolucion: documento.data().Fecha_Devolucion ? (documento.data().Fecha_Devolucion as unknown as Timestamp).toDate() : '',

          }
        ) as SolicitudesPrestamos
      )
      
      this.Prestamos.next(docs)
    })
  }

  getNumeroMaxTipo = ( tipo: TipoElementoInventario ) => this.getNumeroMax('tipo', tipo);

  getNumeroElementosCategoriaMax = ( categoria : Categorias_Equipos | Temas_Libros) => this.getNumeroMax('categoria', categoria);

  getNumeroMax (clase: 'tipo' | 'categoria', valor) {
    const campo = clase === 'tipo' ? 'Equipo_o_Libro' : 'Categoria_o_Tema'
    const corte = clase === 'tipo' ? 4 : 12
    const elementos =  this.Inventario.getValue().filter( (elemento: Inventario) => elemento[campo] === valor )

    if( !elementos.length ) return 0

    return Math.max(
      ...elementos.map(
        elemento => Number( elemento.Codigo_Elemento.slice(corte, corte + 3) )
      )
    )
  }

  borrarElemento = ( elemento: Inventario ) => {
    deleteDoc(doc(this.firestore, `inventario/${elemento.uid}`))
      .then( () => 
        this.imagenesService.borrarImagen(elemento.URL_Imagen)
          .then( () => {
            const PopupMensaje = `Se ha creado borrado el elemento correctamente`;
            this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
          })
      )
      .then( () => {
          const prestamosConElemento= this.Prestamos.getValue().filter( prestamos => prestamos.Codigos_Elementos_Prestamo.find( item => item.Codigo_Elemento === elemento.Codigo_Elemento) )

          prestamosConElemento.forEach(
            prestamo => {
              const indice = prestamo.Codigos_Elementos_Prestamo.findIndex( item => item.Codigo_Elemento === elemento.Codigo_Elemento )
              if (prestamo.Codigos_Elementos_Prestamo.length === 1) deleteDoc(doc(this.firestore, `prestamos/${prestamo.uid}`))
              else {

                this.actualizarElementoPrestamo({
                  ...prestamo,
                  ...prestamo.Codigos_Elementos_Prestamo.splice(indice, 1)
                })
              }
            }
          )
        }
      )
      .catch( () => {
        const PopupMensaje = `Ha habido un problema borrando el elemento`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
      })
  }

  async actualizarElementoInventario(elemento?: Inventario) {
    const InfoElemento = elemento
    const userDocument = doc(this.firestore, `inventario/${InfoElemento.uid}`)

    await setDoc(userDocument, InfoElemento, {merge: true})
  }
  async actualizarElementoPrestamo(elemento?: SolicitudesPrestamos) {
    const InfoElemento = elemento
    const userDocument = doc(this.firestore, `prestamos/${InfoElemento.uid}`)

    await setDoc(userDocument, InfoElemento, {merge: true})
  }

  GuardarElemento(informacion: Inventario): Promise<void | string>{
    if( informacion.uid ) return this.actualizarElementoInventario(informacion)
    else return addDoc(collection(this.firestore, 'inventario'), informacion)
      .then(elemento => {
        const uid = elemento.id;

        updateDoc(doc(this.firestore, `inventario/${uid}`), {uid})
        return uid
      })
  }
  GuardarPrestamo(informacion: SolicitudesPrestamos): Promise<void | string>{
    if( informacion.uid ) return this.actualizarElementoPrestamo(informacion)
    else return addDoc(collection(this.firestore, 'prestamos'), informacion)
      .then(elemento => {
        const uid = elemento.id;

        updateDoc(doc(this.firestore, `prestamos/${uid}`), {uid})
        return uid
      })
  }

  getNuevoID = (tipo: 'Inventario' | 'Prestamo') =>  getDocs( query(tipo === 'Inventario' ? this.coleccionInventario : this.coleccionPrestamos, orderBy('ID', 'desc'), limit(1)) )
    .then( docs => docs.docs.length ? docs.docs[0].data().ID + 1 : 1 )

  getElemento = (ID_elemento) => this.Inventario.getValue().find( elemento => elemento.ID === ID_elemento )

}
