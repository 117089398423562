import { InfoReservaDirectaRespuesta, InformacionReservaAliados, TipoReserva, CategoriasProducto, IdiomasManejados, TipoEvento, OrigenReserva } from './reservas.model';


export class DatosReservaDirecta {
    'Codigo Reserva': string;
    'Fecha Compra': Date;
    Nombre: string;
    Apellido: string;
    'Tipo de Documento': string;
    Documento: string;
    Correo: string;

    constructor(infoReserva: InfoReservaDirectaRespuesta) {
        this['Codigo Reserva']    = infoReserva.Codigo_Reserva;
        this['Fecha Compra']      = infoReserva.Fecha_Compra.toDate();
        this.Nombre               = infoReserva.Datos_Comprador.Nombre;
        this.Apellido             = infoReserva.Datos_Comprador.Apellido;
        this['Tipo de Documento'] = infoReserva.Datos_Comprador.Tipo_Documento;
        this.Documento            = infoReserva.Datos_Comprador.Documento;
        this.Correo               = infoReserva.Datos_Comprador.Correo;
    }
}

export class DatosReservaAliados {
    'Codigo Reserva': string;
    'Fecha Compra': Date;
    'Codigo Empresa Aliada': string;
    'Persona Empresa Reserva': string;

    constructor(infoReserva: InformacionReservaAliados) {
        this['Codigo Reserva']          = infoReserva.Codigo_Reserva;
        this['Fecha Compra']            = infoReserva.Fecha_Reserva.toDate();
        this['Codigo Empresa Aliada']   = infoReserva.Codigo_Empresa_Aliada;
        this['Persona Empresa Reserva'] = infoReserva.Nombre_Persona_Reserva;
    }
}

export class DatosActividad {
    ID_Producto: number;
    Producto: string;
    'Tipo Reserva': TipoReserva;
    'Categoria Reserva': CategoriasProducto;
    'Idioma Guianza': IdiomasManejados;
    'Numero Personas': number;

    constructor(InfoReserva: InfoReservaDirectaRespuesta | InformacionReservaAliados) {
        this.ID_Producto          = InfoReserva.ID_Producto;
        this.Producto             = InfoReserva.Nombre_Producto;
        this['Tipo Reserva']      = InfoReserva.Tipo_Reserva;
        this['Categoria Reserva'] = InfoReserva.Categoria_Reserva;
        this['Idioma Guianza']    = InfoReserva.Idioma_Guianza;
        this['Numero Personas']   = InfoReserva.Numero_Viajeros;
    }
}

export class  FechaActividad {

}

export class  DatosPago {
    'Precio Total Cambio': number;
    'Precio Total COP': number;
    'Base IVA Cambio': number;
    'Base IVA COP': number;
    // TODO: completar campos de acuerdo a Informacion Pago Aliados //

    constructor(InfoReserva: InfoReservaDirectaRespuesta | InformacionReservaAliados, tipo: OrigenReserva) {
        this['Precio Total Cambio'] = InfoReserva.Precio_Total_Cambio;
        this['Precio Total COP']    = InfoReserva.Precio_Total_COP;
        this['Base IVA Cambio']     = InfoReserva.Base_IVA_Cambio;
        this['Base IVA COP']        = InfoReserva.Base_IVA_COP;

        if (tipo === 'Aliado') {
            
        }
    }
}
