import { Injectable, NgZone } from '@angular/core';

import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Info_usuario } from '../models/info_usuarios';
import { PopupService } from './popups.service';
import { onAuthStateChanged, sendEmailVerification, sendPasswordResetEmail } from '@angular/fire/auth';
import { doc, Firestore, getDoc, setDoc } from '@angular/fire/firestore';
import { Timestamp } from '@firebase/firestore-types';


@Injectable({
  providedIn: 'root'
})
export class LoginService {


  InfoUsuario: Info_usuario;

  auth = getAuth();
  
  userDocument;

  Cargado = false;

  constructor(
    private firestore: Firestore,
    private router: Router,
    private ngZone: NgZone,
    public dialog: MatDialog,
    private popupService: PopupService,

  ) {
    onAuthStateChanged(this.auth, async () =>{
      if (this.isLoggedIn ) {
        const userInfo = this.auth.currentUser;
        
        this.userDocument = doc(firestore, `usuarios/${userInfo.uid}`)

        await getDoc(this.userDocument)
        .then( doc => {
          this.InfoUsuario = {
            uid: userInfo.uid,
            email: userInfo.email,
          }
          if ( doc.exists() ) {
            const data = doc.data() as Info_usuario;

            this.InfoUsuario.ID = data.ID;
            this.InfoUsuario.Nombres = data.Nombres;
            this.InfoUsuario.Apellidos = data.Apellidos;
            this.InfoUsuario.Categoria = data.Categoria;
            this.InfoUsuario.Especialidades = data.Especialidades;
            this.InfoUsuario.Idiomas = data.Idiomas;
            if (data.Permisos) this.InfoUsuario.Permisos = data.Permisos;
            if (data.photoURL) this.InfoUsuario.photoURL = data.photoURL;

            if (data.Informacion_General) this.InfoUsuario.Informacion_General =  {
              ...data.Informacion_General,
              Fecha_Nacimiento: (data.Informacion_General.Fecha_Nacimiento as unknown as Timestamp).toDate()
            };
            if (data.Informacion_Especifica) this.InfoUsuario.Informacion_Especifica =  data.Informacion_Especifica;
            if (data.Ficha_Medica) this.InfoUsuario.Ficha_Medica =  data.Ficha_Medica;
          }
          this.Cargado = true;
        })
      }
      else {
        this.Cargado = true;
      }
    })

  }

  SignIn(email, password) {
    return signInWithEmailAndPassword(this.auth, email, password)
      .then( () => {

        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
      })
      .catch( error => {
        window.alert(error.message);
      });
  }

  RecordarPassword( correo ): Promise<void> {
    return sendPasswordResetEmail(this.auth, correo)
      .then(() => {
        const PopupMensaje = `Se ha enviado un correo a ${correo} de para cambiar tu contraseña`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
        this.dialog.closeAll();
      })
      .catch(() => {
        const PopupMensaje = `Ha habido un problema al recuperar tu contraseña, confirma que el correo ${correo} esté registrado`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
      });
  }

  get isLoggedIn(): boolean {
    return this.auth.currentUser !== null
  }
  get emailVerificado(): boolean {
    return this.auth.currentUser?.emailVerified
  }

  confirmacionLogOut(){
    const PopupTitulo = `Log Out`;
    const PopupPregunta = `¿Seguro deseas salir de ${this.InfoUsuario.email}?`;
    const Accion = (): void => this.logOut();
    this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }

  logOut () {
    signOut(this.auth).then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['login']);
    }).catch((error) => {
      console.log(error);
    })
  }

  async SetNombre(Nombres, Apellidos) {
    this.InfoUsuario.Nombres = Nombres;
    this.InfoUsuario.Apellidos = Apellidos;

    await setDoc(this.userDocument, this.InfoUsuario, {merge: true})
  }

  VerificarCorreo(Nombres: string = undefined, Apellidos: string = undefined): void {
    if(!this.InfoUsuario.Nombres || this.InfoUsuario.Apellidos) this.SetNombre(Nombres, Apellidos)

    sendEmailVerification(this.auth.currentUser)
      .then(() => this.router.navigate(['dashboard']) )
      .catch( error => console.log(error) )
  }




}
