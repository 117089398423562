<span *ngIf="Cargado(); else Cargando">

    <h2 mat-dialog-title>Editar información general</h2>
    <mat-dialog-content >
        
        <mat-stepper orientation="vertical" linear="true" #stepper>
            <!--  MARCADOR: Informacion general --------------------------------------------------------------->
            <mat-step [stepControl]="form_Info_General" *ngIf="FormulariosMostrados.includes('Info_General')" errorMessage="Debes ingresar todos los datos">
                <ng-template matStepLabel>Información general</ng-template>
                <form [formGroup]="form_Info_General">
                    <div class="Contenedor_Grid">
                        <mat-form-field>
                            <mat-label>Tipo de Documento</mat-label>
                            <mat-select formControlName="Tipo_Documento">
                                <ng-container *ngFor="let tipo_documento of Lista_Tipos_Documento">
                                    <mat-option [value]="tipo_documento">{{ tipo_documento }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Número de Documento</mat-label>
                            <input matInput type="text" formControlName="Documento" [value]="Documento.value | uppercase">
                            <button mat-icon-button *ngIf="Documento.value" matSuffix aria-label="Clear" (click)="borrarCampo(Documento)">
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-slide-toggle [formControl]="Tiene_RNT">Tiene RNT</mat-slide-toggle>
                        <mat-form-field *ngIf="Tiene_RNT.value">
                            <mat-label>RNT</mat-label>
                            <input matInput type="text" formControlName="RNT" [value]="RNT.value" mask="0*">
                            <button mat-icon-button *ngIf="RNT.value" matSuffix aria-label="Clear" (click)="borrarCampo(RNT)">
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Sexo</mat-label>
                            <mat-select formControlName="Sexo">
                                <mat-option [value]="'Femenino'">Femenino</mat-option>
                                <mat-option [value]="'Masculino'">Masculino</mat-option>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Fecha de Nacimiento</mat-label>
                            <input matInput [matDatepicker]="picker" disabled formControlName="Fecha_Nacimiento">
                            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>Pais Nacionalidad</mat-label>
                            <mat-select formControlName="Nacionalidad">
                                <ng-container *ngFor="let pais of Array_Paises">
                                    <mat-option [value]="pais.Nombre">{{ pais.Nombre }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <p>Telefono celular</p>
                    <div class="Contenedor_Grid" formGroupName="Telefono_Celular">
                        <mat-form-field>
                            <mat-label>Indicativo</mat-label>
                            <mat-select  formControlName="Indicativo">
                                <ng-container *ngFor="let pais of Array_Paises">
                                    <mat-option [value]="pais.Indicativo">{{ pais.Nombre }} (+{{ pais.Indicativo }})</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="Indicativo.value">
                            <mat-label *ngIf="Indicativo.value">Teléfono Celular</mat-label>
                            <span matPrefix>{{Indicativo.value ? '(+'+ Indicativo.value +') ' : ''}} &nbsp;</span>
                            <input matInput type="text" mask="000-000-0000" formControlName="Telefono">
                            <mat-hint>300-555-5555</mat-hint>
                            <button mat-icon-button *ngIf="Telefono.value" matSuffix aria-label="Clear" (click)="borrarCampo(Telefono)">
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <p>Pais de residencia</p>
                    <div class="Contenedor_Grid" formGroupName="Residencia">
                        <mat-form-field>
                            <mat-label>Pais</mat-label>
                            <mat-select formControlName="Pais">
                                <ng-container *ngFor="let pais of Array_Paises">
                                    <mat-option [value]="pais.Nombre" (click)="borrarCampo(Estado)">{{ pais.Nombre }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="Pais.value">
                            <mat-label>Departamento/Estado</mat-label>
                            <mat-select formControlName="Estado">
                                <mat-option *ngIf="!getEstados(Pais.value).length; else estados" value="No aplica" (click)="borrarCampo(Ciudad)">No aplica</mat-option>
                                <ng-container #estados *ngFor="let estado of getEstados(Pais.value)">
                                    <mat-option [value]="estado.Nombre" (click)="borrarCampo(Ciudad)">{{ estado.Nombre }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field *ngIf="Pais.value && Estado.value">
                            <mat-label>Ciudad</mat-label>
                            <mat-select formControlName="Ciudad">
                                <mat-option *ngIf="Estado.value === 'No aplica' && !getCiudades(Pais.value, Estado.value)?.length; else ciudades" value="No aplica">No aplica</mat-option>
                                <ng-container #ciudades *ngFor="let ciudad of getCiudades(Pais.value, Estado.value)">
                                    <mat-option [value]="ciudad">{{ ciudad }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div *ngIf="FormulariosMostrados.length > 1">
                        <button mat-raised-button color="warn" [disabled]="form_Info_General.invalid" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <!--  MARCADOR-CIERRE  ----------------------------------------------------------->

            <!--  MARCADOR: Información Específica --------------------------------------------------------------->
            <mat-step [stepControl]="form_Info_Especifica" *ngIf="FormulariosMostrados.includes('Info_Especifica')" errorMessage="Debes ingresar todos los datos">
                <ng-template matStepLabel>Información Específica</ng-template>
                <form [formGroup]="form_Info_Especifica">
                    <mat-form-field>
                        <mat-label>Restricciones Alimenticias Generales</mat-label>
                        <mat-select  formControlName="Restricciones_Alimenticias_Generales">
                            <ng-container *ngFor="let restriccion of Lista_Restricciones_Alimenticias">
                                <mat-option [value]="restriccion">{{ restriccion }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="Restricciones_Alimenticias_Generales.value === 'Otra'">
                        <mat-label>Restricciones Alimenticias Específicas</mat-label>
                        <input matInput type="text" formControlName="Restricciones_Alimenticias_Especificas">
                        <button mat-icon-button *ngIf="Restricciones_Alimenticias_Especificas.value" matSuffix aria-label="Clear" (click)="borrarCampo(Restricciones_Alimenticias_Especificas)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <p>Contacto de Emergencia</p>
                    <div formGroupName="Contacto_Emergencia">
                        <mat-form-field>
                            <mat-label>Nombre</mat-label>
                            <input matInput type="text" formControlName="Nombre">
                            <button mat-icon-button *ngIf="Contacto_Nombre.value" matSuffix aria-label="Clear" (click)="borrarCampo(Contacto_Nombre)">
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </mat-form-field>
                        <p>Telefono celular</p>
                        <div class="Contenedor_Grid" formGroupName="Telefono">
                            <mat-form-field>
                                <mat-label>Indicativo</mat-label>
                                <mat-select  formControlName="Indicativo">
                                    <ng-container *ngFor="let pais of Array_Paises">
                                        <mat-option [value]="pais.Indicativo">{{ pais.Nombre }} (+{{ pais.Indicativo }})</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field *ngIf="Contacto_Tel_Indicativo.value">
                                <mat-label>Teléfono Celular</mat-label>
                                <span matPrefix>{{Contacto_Tel_Indicativo.value ? '(+'+ Contacto_Tel_Indicativo.value +') ' : ''}} &nbsp;</span>
                                <input matInput type="text" mask="000-000-0000" formControlName="Numero">
                                <mat-hint>300-555-5555</mat-hint>
                                <button mat-icon-button *ngIf="Contacto_Tel_Numero.value" matSuffix aria-label="Clear" (click)="borrarCampo(Contacto_Tel_Numero)">
                                    <mat-icon color="warn">close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                    </div>
                    <p>Hábitos</p>
                    <div formGroupName="Habitos">
                        <p>Fumador</p>
                        <div formGroupName="Cigarrillos">
                            <p>¿Fuma?
                                <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Fuma, sinExplicacion: true}"></ng-container>
                            </p>
                            <ng-container *ngIf="Fuma.get('Valor').value === true">
                                <mat-form-field>
                                    <mat-label>¿Qué?</mat-label>
                                    <mat-select  [formControl]="Fuma.get('Explicacion')">
                                        <mat-option value="Tabaco"> Tabaco </mat-option>
                                        <mat-option value="Otros"> Otros </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Frecuencia</mat-label>
                                    <mat-select  formControlName="Frecuencia">
                                        <ng-container *ngFor="let frecuencia of Lista_Frecuencia">
                                            <mat-option [value]="frecuencia">{{ frecuencia }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                        </div>
                        <p>Actividad Física</p>
                        <div formGroupName="Actividad_Fisica">
                            <p>¿Realiza Actividad Física?
                                <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Realiza_Actividad_Fisica, sinExplicacion: true}"></ng-container>
                            </p>
                            <ng-container *ngIf="Realiza_Actividad_Fisica.get('Valor').value === true">
                                <mat-form-field>
                                    <mat-label>¿Cuál(es)?</mat-label>
                                    <input matInput type="text" [formControl]="Actividad_Fisica_Cuales">
                                    <mat-hint>Ej: Correr, Escalar, jugar Futbol...</mat-hint>
                                    <button mat-icon-button *ngIf="Actividad_Fisica_Cuales.value" matSuffix aria-label="Clear" (click)="borrarCampo(Actividad_Fisica_Cuales)">
                                        <mat-icon color="warn">close</mat-icon>
                                    </button>
                                </mat-form-field>
                                <mat-form-field>
                                    <mat-label>Frecuencia</mat-label>
                                    <mat-select  formControlName="Frecuencia">
                                        <ng-container *ngFor="let frecuencia of Lista_Frecuencia">
                                            <mat-option [value]="frecuencia">{{ frecuencia }}</mat-option>
                                        </ng-container>
                                    </mat-select>
                                </mat-form-field>
                            </ng-container>
                            
                        </div>
                    </div>
                    <div *ngIf="FormulariosMostrados.length > 1">
                        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
                        <button mat-raised-button color="warn"  [disabled]="form_Info_Especifica.invalid" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <!--  MARCADOR-CIERRE  ----------------------------------------------------------->
            
<!--  MARCADOR: Ficha Médica --------------------------------------------------------------->
            <mat-step *ngIf="FormulariosMostrados.includes('Ficha_Medica')" errorMessage="Debes ingresar todos los datos">
                <ng-template matStepLabel>Ficha Médica 1</ng-template>
                <h2>Antecedentes Personales</h2>
                <form [formGroup]="form_Ficha_Medica">
                    <mat-form-field>
                        <mat-label>EPS o Seguro</mat-label>
                        <input matInput type="text" formControlName="EPS_Seguro">
                        <button mat-icon-button *ngIf="EPS_Seguro.value" matSuffix aria-label="Clear" (click)="borrarCampo(EPS_Seguro)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <div formGroupName="Tipo_Sangre">
                        <mat-form-field>
                            <mat-label>Grupo Sanguineo</mat-label>
                            <mat-select  formControlName="Grupo_Sanguineo">
                                <ng-container *ngFor="let grupo of Lista_Grupo_Sanguineo">
                                    <mat-option [value]="grupo">{{ grupo }}</mat-option>
                                </ng-container>
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field>
                            <mat-label>RH</mat-label>
                            <mat-select  formControlName="RH">
                                    <mat-option value="+">(+)</mat-option>
                                    <mat-option value="-">(-)</mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div formGroupName="Medicamentos">
                        <p>¿Toma Medicamentos?
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Toma_Medicamentos, sinExplicacion: true}"></ng-container>
                        </p>
                        <mat-form-field *ngIf="Toma_Medicamentos.get('Valor').value === true">
                            <mat-label>¿Cuales y en qué dosis?</mat-label>
                            <input matInput type="text" [formControl]="Cuales_Dosis">
                            <mat-hint>Ej: Ejemplicina 50mg cada 8 horas para la hipertensión...</mat-hint>
                            <button mat-icon-button *ngIf="Cuales_Dosis.value" matSuffix aria-label="Clear" (click)="borrarCampo(Cuales_Dosis)">
                                <mat-icon color="warn">close</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div formGroupName="Fobias">
                        <p>¿Alguna Fobia?
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Alguna_Fobia, sinExplicacion: true}"></ng-container>
                        </p>
                        <ng-container *ngIf="Alguna_Fobia.get('Valor').value === true">
                            <mat-form-field>
                                <mat-label>Fobia</mat-label>
                                <input matInput type="text" [formControl]="Fobia">
                                <mat-hint>Ej: Le tengo fobia a las arañas...</mat-hint>
                                <button mat-icon-button *ngIf="Fobia.value" matSuffix aria-label="Clear" (click)="borrarCampo(Fobia)">
                                    <mat-icon color="warn">close</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field>
                                <mat-label>Gravedad</mat-label>
                                <mat-select  formControlName="Gravedad">
                                    <ng-container *ngFor="let gravedad of Lista_Gravedad">
                                        <mat-option [value]="gravedad">{{ gravedad }}</mat-option>
                                    </ng-container>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                        
                    </div>
                    <div formGroupName="Antecedentes_Personales">
                        <p>Alergias
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Alergias}"></ng-container>
                        </p>
                        <p>Problemas Cardiovasculares
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Problemas_Cardiovasculares}"></ng-container>
                        </p>
                        <p>Problemas Osteomusculares
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Problemas_Osteomusculares}"></ng-container>
                        </p>
                        <p>Problemas Respiratorios
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Problemas_Respiratorios}"></ng-container>
                        </p>
                        <p>Enfermedades Neurologicas
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Enfermedades_Neurologicas}"></ng-container>
                        </p>
                        <p>Dolores Nauseas Desmayos
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Dolores_Nauseas_Desmayos}"></ng-container>
                        </p>
                        <p *ngIf="Sexo.value === 'Femenino'">Embarazo
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Embarazo}"></ng-container>
                        </p>
                        <p>Otro
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Per_Otro}"></ng-container>
                        </p>
                    </div>
                    <div>
                        <button mat-raised-button color="warn"  *ngIf="FormulariosMostrados.length > 1" matStepperPrevious>Back</button>
                        <button mat-raised-button color="warn" matStepperNext>Next</button>
                    </div>
                </form>
            </mat-step>
            <mat-step  *ngIf="FormulariosMostrados.includes('Ficha_Medica')" errorMessage="Debes ingresar todos los datos">
                <ng-template matStepLabel>Ficha Médica 2</ng-template>
                <h2>Antecedentes Familiares</h2>
                <form [formGroup]="form_Ficha_Medica">
                    <div formGroupName="Antecedentes_Familiares">
                        <p>Muerte Prematura
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Fam_Muerte_Prematura}"></ng-container>
                        </p>
                        <p>Problemas Cardiovasculares
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Fam_Problemas_Cardiovasculares}"></ng-container>
                        </p>
                        <p>Otro
                            <ng-container *ngTemplateOutlet="botones_sino; context: {formControl: Ant_Fam_Otro}"></ng-container>
                        </p>
                    </div>
                    <div>
                        <button mat-raised-button color="warn" matStepperPrevious>Back</button>
                    </div>
                </form>
            </mat-step>
        </mat-stepper>

    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-fab extended mat-dialog-close color="warn">Cancelar</button>
        <button mat-fab extended mat-dialog-close color="accent" cdkFocusInitial (click)="actualizarInfoUsuario()" *ngIf="validacionSubmit">Aceptar</button>
    </mat-dialog-actions>

</span>
<ng-template #Cargando>
    <div class="Cargador">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>

<ng-template #botones_sino let-formControl="formControl" let-sinExplicacion="sinExplicacion">
    <span [formGroup]="formControl">
        <mat-button-toggle-group formControlName="Valor">
            <mat-button-toggle [value]="true" (click)="borrarCampo(formControl.get('Explicacion'))">Sí</mat-button-toggle>
            <mat-button-toggle [value]="false" (click)="formControl.get('Explicacion')?.setValue('No aplica')">No</mat-button-toggle>
        </mat-button-toggle-group>
        <br>
        <mat-form-field *ngIf="!sinExplicacion && formControl.get('Valor').value === true">
            <mat-label>Explicación</mat-label>
            <input matInput type="text" formControlName="Explicacion">
            <button matSuffix *ngIf="formControl.get('Explicacion').value" mat-icon-button aria-label="Clear" (click)="borrarCampo(formControl.get('Explicacion'))">
                <mat-icon>close</mat-icon>
            </button>
        </mat-form-field>
    </span>
</ng-template>