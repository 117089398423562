import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators } from '@angular/forms';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EtapaSolicitudPrestamo, Inventario, RangoFecha, SolicitudesPrestamos, TipoElementoInventario } from 'src/app/models/inventario';
import { EquipoService } from 'src/app/services/equipo.service';
import { GeneralesService } from 'src/app/services/generales.service';
import { InventariosService } from 'src/app/services/inventarios.service';
import { LoginService } from 'src/app/services/login.service';
import { PopupService } from 'src/app/services/popups.service';

@Component({
  selector: 'app-solicitud-prestamo-dialog',
  templateUrl: './solicitud-prestamo-dialog.component.html',
  styleUrl: './solicitud-prestamo-dialog.component.css',
  encapsulation: ViewEncapsulation.None,
})
export class SolicitudPrestamoDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {tipo: EtapaSolicitudPrestamo, info: SolicitudesPrestamos},
    private dialogRef: MatDialogRef<SolicitudPrestamoDialogComponent>,
    private inventariosService: InventariosService,
    private loginService: LoginService,
    private popupService: PopupService,
    private equipoService: EquipoService,
    private generalesService: GeneralesService,
    private formBuilder: FormBuilder,
  ) {
    this.crearFormulario()
    dialogRef.disableClose = true;
  }

  get uid()                        { return this.formulario_elemento.get('uid')                        as FormControl<string> }
  get ID()                         { return this.formulario_elemento.get('ID')                         as FormControl<number> }
  get Fecha_Solicitud()            { return this.formulario_elemento.get('Fecha_Solicitud')            as FormControl<Date> }
  get Fecha_Prestamo()             { return this.formulario_elemento.get('Fecha_Prestamo')             as FormGroup }
  get Estado_Solicitud()           { return this.formulario_elemento.get('Estado_Solicitud')           as FormControl<string> }

  get ID_Solicitante()             { return this.formulario_elemento.get('ID_Solicitante')             as FormControl<number> }
  get Codigos_Elementos_Prestamo() { return this.formulario_elemento.get('Codigos_Elementos_Prestamo') as FormArray }
  get Justificacion()              { return this.formulario_elemento.get('Justificacion')              as FormControl<string> }
  
  get ID_Persona_Que_Aprueba()     { return this.formulario_elemento.get('ID_Persona_Que_Aprueba')     as FormControl<number> }
  get Fecha_Aprobacion()           { return this.formulario_elemento.get('Fecha_Aprobacion')           as FormControl<Date> }
  get Observaciones_Prestamo()     { return this.formulario_elemento.get('Observaciones_Prestamo')     as FormControl<string> }

  get ID_Persona_Que_Entrega()     { return this.formulario_elemento.get('ID_Persona_Que_Entrega')     as FormControl<number> }
  get Fecha_Recogida()             { return this.formulario_elemento.get('Fecha_Recogida')             as FormControl<Date> }
  get Observaciones_Recogida()     { return this.formulario_elemento.get('Observaciones_Recogida')     as FormControl<string> }

  get ID_Persona_Que_Recibe()      { return this.formulario_elemento.get('ID_Persona_Que_Recibe')      as FormControl<number> }
  get Fecha_Devolucion()           { return this.formulario_elemento.get('Fecha_Devolucion')           as FormControl<Date> }
  get Observaciones_Devolucion()   { return this.formulario_elemento.get('Observaciones_Devolucion')   as FormControl<string> }

  formulario_elemento: FormGroup;

  crearFormulario(): void {
    this.formulario_elemento = this.formBuilder.group({
      uid: this.data.info?.uid || '',
      ID: this.data.info?.ID || '',
      Fecha_Solicitud: [this.data.info?.Fecha_Solicitud || new Date(), [Validators.required]],
      Estado_Solicitud: this.data.info?.Estado_Solicitud || 'Solicitud pendiente',
      Fecha_Prestamo: this.formBuilder.group({
        Inicio: [this.data.info?.Fecha_Prestamo.Inicio || '', [Validators.required]],
        Fin: [this.data.info?.Fecha_Prestamo.Fin || '', [Validators.required]],
      }),

      ID_Solicitante: this.data.info?.ID_Solicitante || this.loginService.InfoUsuario.ID,
      Codigos_Elementos_Prestamo: this.formBuilder.array([]),
      Justificacion: [{value: this.data.info?.Justificacion || '', disabled: this.data.tipo !== 'Solicitud'}, Validators.required],
    });

    if(this.data.info) {
      this.data.info.Codigos_Elementos_Prestamo.forEach(
        elemento => this.agregarElemento(elemento)
      )

      if (this.data.tipo === 'Solicitud') return

      else {
        const agregarCampos = (campos: {}) => {
          this.formulario_elemento = this.formBuilder.group({
          ...this.formulario_elemento.controls,
          ...campos
          })
        }

        agregarCampos({
          ID_Persona_Que_Aprueba: this.data.tipo === 'Aprobación' ? this.loginService.InfoUsuario.ID : this.data.info.ID_Persona_Que_Aprueba,
          Fecha_Aprobacion: this.data.info.Fecha_Aprobacion || new Date(),
          Observaciones_Prestamo: [{value: this.data.info.Observaciones_Prestamo || '', disabled: this.data.tipo !== 'Aprobación'}],
        })

        if (this.data.tipo === 'Aprobación') return

        agregarCampos({
          ID_Persona_Que_Entrega: this.data.tipo === 'Recogida' ? this.loginService.InfoUsuario.ID : this.data.info.ID_Persona_Que_Entrega,
          Fecha_Recogida: this.data.info.Fecha_Recogida || new Date(),
          Observaciones_Recogida: [{value: this.data.info.Observaciones_Recogida || '', disabled: this.data.tipo !== 'Recogida'}],
        })

        if (this.data.tipo === 'Recogida') return

        agregarCampos({
          ID_Persona_Que_Recibe: this.loginService.InfoUsuario.ID,
          Fecha_Devolucion: new Date(),
          Observaciones_Devolucion: this.data.info.Observaciones_Devolucion || '',
        })

      }
    }
    else {
      this.inventariosService.getNuevoID('Prestamo')
        .then( id => this.ID.patchValue(id) )
        .then( () => this.agregarElemento() )
    }
  }

  dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
    let respuesta;
    let inicio;
    let fin;

    if (view === 'month') {
      const prestamos = this.inventariosService.Prestamos.getValue();

      let valor = prestamos.filter( elemento => elemento.Estado_Solicitud === 'Aprobada' )
        .map( elemento => elemento.Fecha_Prestamo )
        .find( elemento => cellDate >= elemento.Inicio && cellDate <= elemento.Fin )

      if(valor) {
        respuesta = 'Fecha_con_Prestamo'
        if(cellDate.toDateString() === valor.Inicio.toDateString()) inicio = 'Fecha_Inicio'
        if(cellDate.toDateString() === valor.Fin.toDateString()) fin = 'Fecha_Fin'
      }
    }
    return `${respuesta || ''} ${inicio || ''} ${fin || ''}`;
  };

  agregarElemento(valor?) {
    this.Codigos_Elementos_Prestamo.push(
      this.formBuilder.group({
        Tipo_Elemento      : [{value: valor?.Tipo_Elemento || '', disabled: this.data.tipo !== 'Solicitud'}, Validators.required],
        Categoria_Elemento : [{value: valor?.Categoria_Elemento || '', disabled: this.data.tipo !== 'Solicitud'}, Validators.required],
        Codigo_Elemento    : [{value: valor?.Codigo_Elemento || '', disabled: this.data.tipo !== 'Solicitud'}, Validators.required],
      })
    )
  }

  categoriaVacia = (categoria: TipoElementoInventario):boolean => !this.inventariosService.Inventario.getValue().find(
    elemento => elemento.Equipo_o_Libro === categoria
  )

  getCategorias = (Numero_Elemento: number) => new Set(
    this.inventariosService.Inventario.getValue().filter(
      elemento => elemento.Equipo_o_Libro === this.Codigos_Elementos_Prestamo.value[Numero_Elemento].Tipo_Elemento
    ).map( elemento => elemento.Categoria_o_Tema)
  )

  getElementos =(Numero_Elemento: number) => this.inventariosService.Inventario.getValue().filter(
    elemento => elemento.Categoria_o_Tema === this.Codigos_Elementos_Prestamo.value[Numero_Elemento].Categoria_Elemento
  ).sort((a, b) => (a.Codigo_Elemento > b.Codigo_Elemento) ? 1 : -1)

  elementoYaEscogido = ( opcion: Inventario ): boolean => {
    const Prestamos = this.inventariosService.Prestamos.getValue()
    const fechaPrestamo: RangoFecha = this.Fecha_Prestamo.value;

    const ya_seleccionado = !!this.Codigos_Elementos_Prestamo.value.find( elemento => elemento.Codigo_Elemento === opcion.Codigo_Elemento)

    const ya_prestado = !!Prestamos.filter( elemento => elemento.Estado_Solicitud === 'Aprobada' && !elemento.Fecha_Devolucion &&
        ( fechaPrestamo.Inicio <= elemento.Fecha_Prestamo.Fin ) && ( fechaPrestamo.Fin >= elemento.Fecha_Prestamo.Inicio )
      )
      .find( elemento => !!elemento.Codigos_Elementos_Prestamo.find( ele => ele.Codigo_Elemento === opcion.Codigo_Elemento) )

    return ya_seleccionado || ya_prestado
  };

  getValorElemento = (numero_grupo: number, parte: 'Tipo_Elemento' | 'Categoria_Elemento' | 'Codigo_Elemento'): string =>
    this.Codigos_Elementos_Prestamo.value[numero_grupo][parte];

  mostrarColumna = (parte: 'Tipo_Elemento' | 'Categoria_Elemento' | 'Codigo_Elemento'): boolean => this.Codigos_Elementos_Prestamo.value.find( elemento => elemento[parte] !== "" );

  getNombreUsuario = (ID_usuario) => this.equipoService.getNombreUsuario(ID_usuario)

  enviarSolicitud = (aprobado?: boolean) => {
    if(this.formulario_elemento.dirty || this.data.tipo !== 'Solicitud') {
      const PopupTitulo = `Guardar cambios`;
      const PopupPregunta = () => {
        switch (this.data.tipo) {
          case 'Solicitud':  return `¿Seguro desea guardar la edición del elemento del préstamo?`
          case 'Aprobación': return `¿Seguro desea ${aprobado ? 'aprobar' : 'rechazar'} la solicitud de préstamo?`
          case 'Recogida':   return `¿Seguro desea confirmar la entrega (recogida) de los elementos del préstamo?`
          case 'Devolución': return `¿Seguro desea confirmar la devolución de los elementos del préstamo?`
        }
      };
      const valorForm = this.formulario_elemento.getRawValue();
      const InfoAprobacion = (edicionRechazada?: boolean ) => ({
        ...valorForm,
        Estado_Solicitud: edicionRechazada ? 'Solicitud pendiente' : aprobado ? 'Aprobada' : 'Rechazada',
      })


      const Accion = () => this.inventariosService.GuardarPrestamo(
          this.data.tipo === 'Aprobación' ? InfoAprobacion(): this.data.tipo === 'Solicitud' ? InfoAprobacion(true) : valorForm
        )
        .then(
          uid => {
            const PopupMensaje = () => {
              switch (this.data.tipo) {
                case 'Solicitud':  return `Se ha ${uid ? 'creado la nueva' : 'actualizado la'} solicitud de préstamo`
                case 'Aprobación': return `Se ha ${aprobado ? 'aprobado' : 'rechazado'} el préstamo`
                case 'Recogida':   return `Se ha confirmado la entrega (recogida)`
                case 'Devolución': return `Se ha confirmado la devolución`
              }
            }
            this.popupService.mensajeConfirmacion(PopupMensaje(), 'Confirmacion')
          }
        )
        .catch(
          () => {
            const PopupMensaje = () => {
              switch (this.data.tipo) {
                case 'Solicitud':  return `Ha habido un problema guardando el prestamo`
                case 'Aprobación': return `Ha habido un problema dando respuesta a la solicitud de prestamo`
                case 'Recogida':   return `Ha habido un problema confirmando la entrega`
                case 'Devolución': return `Ha habido un problema confirmando la devolución`
              }
            }
            this.popupService.mensajeConfirmacion(PopupMensaje(), 'Error')
          }
        ).finally(
          () => this.dialogRef.close()
        )
      this.popupService.popupConfirmar( PopupTitulo, PopupPregunta(), Accion );
    }
    else this.dialogRef.close();
  };

  Cancelar() {
    if(this.formulario_elemento.dirty) {

      const PopupTitulo = `Cancelar edición`;
      const PopupPregunta = `¿Seguro desea cancelar la edición de la solicitud de prestamo?, se borrará cualquier cambio`;
      const Accion = (): void => this.dialogRef.close();

      this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
    }
    else this.dialogRef.close();
  }

  borrarCampo = this.generalesService.borrarCampoForm;

  borrarElemento = (indice_elemento: number) => this.Codigos_Elementos_Prestamo.removeAt( indice_elemento );

}
