import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class GeneralesService {

  constructor() { }

  borrarCampoForm = (campo: FormControl) => campo.reset();

}
