import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Lista_Categorias_Equipos, Lista_Categorias_Tipos, Lista_Formas_Compra, Lista_Monedas, Lista_Temas_Libros } from 'src/app/models/categorias';
import { Categorias_Equipos, EstadoPrestamo, FormaCompra, Inventario, Temas_Libros } from 'src/app/models/inventario';
import { GeneralesService } from 'src/app/services/generales.service';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { InventariosService } from 'src/app/services/inventarios.service';
import { PopupService } from 'src/app/services/popups.service';

@Component({
  selector: 'app-agregar-inventario',
  templateUrl: './agregar-inventario.component.html',
  styleUrls: ['./agregar-inventario.component.css']
})
export class AgregarInventarioComponent {
    //////////  MARCADOR: arrays importados /////////////////////////////////////////////////////////////////
    Lista_Categorias_Equipos = Lista_Categorias_Equipos;
    Lista_Temas_Libros       = Lista_Temas_Libros;
    Lista_Monedas            = Lista_Monedas;
    Lista_Formas_Compra      = Lista_Formas_Compra;
    Lista_Categorias_Tipos   = Lista_Categorias_Tipos;
    //////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////

  get uid()                { return this.formulario_elemento.get('uid')                as FormControl<string> }
  get ID()                 { return this.formulario_elemento.get('ID')                 as FormControl<number> }
  get Equipo_o_Libro()     { return this.formulario_elemento.get('Equipo_o_Libro')     as FormControl<'Equipo' | 'Libro'> }
  get Codigo_Elemento()    { return this.formulario_elemento.get('Codigo_Elemento')    as FormControl<string> }
  get Categoria_o_Tema()   { return this.formulario_elemento.get('Categoria_o_Tema')   as FormControl<Categorias_Equipos | Temas_Libros> }
  get Moneda_Compra()      { return this.formulario_elemento.get('Moneda_Compra')      as FormControl<'COP' | 'USD' | 'EUR' | 'Regalo'> }
  get Precio()             { return this.formulario_elemento.get('Precio')             as FormControl<number | 'Regalo'> }
  get Precio_COP()         { return this.formulario_elemento.get('Precio_COP')         as FormControl<number | 'Regalo'> }
  get Forma_Compra()       { return this.formulario_elemento.get('Forma_Compra')       as FormControl<FormaCompra> }
  get Lugar_Compra()       { return this.formulario_elemento.get('Lugar_Compra')       as FormControl<string> }
  get Fecha_Compra()       { return this.formulario_elemento.get('Fecha_Compra')       as FormControl<Date> }
  get Descripcion()        { return this.formulario_elemento.get('Descripcion')        as FormControl<string> }
  get Observaciones()      { return this.formulario_elemento.get('Observaciones')      as FormControl<string> }
  get URL_Imagen()         { return this.formulario_elemento.get('URL_Imagen')         as FormControl<string> }
  get Marca_Titulo()       { return this.formulario_elemento.get('Marca_Titulo')       as FormControl<string> }
  get Referencia_Edicion() { return this.formulario_elemento.get('Referencia_Edicion') as FormControl<string> }
  get Color_Editorial()    { return this.formulario_elemento.get('Color_Editorial')    as FormControl<string> }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Inventario,
    private dialogRef: MatDialogRef<AgregarInventarioComponent>,
    private formBuilder: FormBuilder,
    private generalesService: GeneralesService,
    private popupService: PopupService,
    private imagenesService: ImagenesService,
    private inventariosService: InventariosService,
    private router: Router,
  ) {
    this.crearFormulario()
  }

  formulario_elemento: FormGroup;

  imagenPresubida: File[];

  Imagen: string;

  crearFormulario(): void {
    this.formulario_elemento = this.formBuilder.group({
      uid: '',
      ID: '',
      Equipo_o_Libro: ['', [Validators.required]],
      Codigo_Elemento: [{ value: '', disabled: true}, [Validators.required]],
      Categoria_o_Tema: ['', [Validators.required]],
      Moneda_Compra: ['', [Validators.required]],
      Precio: ['', [Validators.required]],
      Precio_COP: ['', [Validators.required]],
      Forma_Compra: ['', [Validators.required]],
      Lugar_Compra: ['', [Validators.required]],
      Fecha_Compra: [{ value: '', disabled: true}, [Validators.required]],
      Descripcion: ['', [Validators.required]],
      Observaciones: '',
      URL_Imagen: '',
      Marca_Titulo: ['', [Validators.required]],
      Referencia_Edicion: ['', [Validators.required]],
      Color_Editorial: ['', [Validators.required]],
    });

    if(this.Codigo_Elemento?.value) {
      this.Equipo_o_Libro.disable()
      this.Categoria_o_Tema.disable()
    }

    if(this.data) {
      this.formulario_elemento.setValue(this.data)
      this.Equipo_o_Libro.disable()
      this.Categoria_o_Tema.disable()
      if (this.URL_Imagen.value) this.imagenesService.getImagen(this.URL_Imagen.value)
        .then( url => this.Imagen = url )
    }
    else {
      this.inventariosService.getNuevoID('Inventario')
        .then( id => this.ID.patchValue(id) )
    }
  }

  borrarCampo = this.generalesService.borrarCampoForm;

  getValoresExistentes( campo: string ){
    const valores = [];
    this.inventariosService.Inventario.getValue()
      .filter( (elemento: Inventario) => elemento.Equipo_o_Libro === this.Equipo_o_Libro.value)
      .map( (elemento: Inventario) => elemento[campo] ).forEach(
        elemento => valores.includes(elemento) ? null : valores.push(elemento)
      )
    return valores
  }

  async crearCodigo() {
    const tipo = this.Equipo_o_Libro.value;
    const categoria = this.Categoria_o_Tema.value;
    const numeroTotalTipo = this.inventariosService.getNumeroMaxTipo(tipo) + 1;
    const numeroTotalCategoria = this.inventariosService.getNumeroElementosCategoriaMax(categoria) + 1;

    const codigoTipo = this.Lista_Categorias_Tipos[this.Equipo_o_Libro.value];
    const numeroTipo = `${numeroTotalTipo < 100 ? 0 : ''}${numeroTotalTipo < 10 ? 0 : ''}${numeroTotalTipo}`;
    const codigoCategoria = this.Equipo_o_Libro.value === 'Equipo' ? this.Lista_Categorias_Equipos[categoria] : this.Lista_Temas_Libros[categoria];
    const numeroCategoria = `${numeroTotalCategoria < 100 ? 0 : ''}${numeroTotalCategoria < 10 ? 0 : ''}${numeroTotalCategoria}`;

    this.Codigo_Elemento.setValue(`${codigoTipo}-${numeroTipo}-${codigoCategoria}-${numeroCategoria}`)
    this.Equipo_o_Libro.disable()
    this.Categoria_o_Tema.disable()
  }

  Regalo() {
    const elementos = [this.Moneda_Compra, this.Precio, this.Precio_COP ]

    elementos.forEach(
      elemento => {
        if (this.Forma_Compra.value === 'Regalo') elemento.patchValue('Regalo');
        else if ( elemento.value === 'Regalo' ) elemento.reset();
      }
    )
  }

  Moneda() {
    if (this.Moneda_Compra.value === 'COP') {
      this.Precio_COP.disable();
      this.Precio_COP.patchValue(this.Precio.value)
      this.Precio.reset();
    }
    else if ( this.Precio_COP.disabled ) {
      this.Precio_COP.enable();
      this.Precio_COP.reset();
      this.Precio.reset();
    }
  }

  MonedaCOP = () => this.Moneda_Compra.value === 'COP' ? this.Precio_COP.patchValue(this.Precio.value) : null

  GuardarElemento = () => {
    if(this.formulario_elemento.dirty || this.imagenPresubida) {
      const PopupTitulo = `Guardar cambios`;
      const PopupPregunta = `¿Seguro desea guardar la edición del elemento del inventario?`;
      const Accion = () => this.inventariosService.GuardarElemento(this.formulario_elemento.getRawValue())
        .then( elemento => {
          let nuevo: boolean = false;
            if( elemento ) {
              this.uid.patchValue(elemento)
              nuevo = true
            }
            if( this.imagenPresubida ) this.imagenesService.subirArchivo( this.imagenPresubida, 'inventario', this.uid.value );

            return nuevo
          }
        )
        .then(
          res => {
            const PopupMensaje = `Se ha ${res ? 'creado el nuevo' : 'actualizado la información del'} elemento`;
            this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
          }
        )
        .catch(
          () => {
            const PopupMensaje = `Ha habido un problema guardando el elemento`;
            this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
          }
        ).finally(
          () => this.dialogRef.close()
        )
      this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
    }
  };

  Cancelar() {
    if(this.formulario_elemento.touched || this.imagenPresubida) {

      const PopupTitulo = `Cancelar edición`;
      const PopupPregunta = `¿Seguro desea cancelar la edición del elemento?, se borrará cualquier cambio`;
      const Accion = (): void => this.dialogRef.close();

      this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
    }
    else this.dialogRef.close();
  }

  presubirImagen (archivos) {
    archivos = archivos.target.files
      this.imagenPresubida = archivos
      this.Imagen = URL.createObjectURL(archivos[0])
  }

  getEstadoPrestamo = (): EstadoPrestamo => {
    const estadoPrestamo = this.inventariosService.Prestamos.getValue().filter(
      prestamo =>
        prestamo.Fecha_Aprobacion && prestamo.Estado_Solicitud === 'Aprobada'
    ).filter( prestamo => prestamo.Codigos_Elementos_Prestamo.find(item => item.Codigo_Elemento === this.data.Codigo_Elemento))

    if(!estadoPrestamo.length) return 'Nuevo'
    if(!estadoPrestamo.find( prestamo => prestamo.Fecha_Recogida)) return 'Por recoger'
    return !!estadoPrestamo.find( prestamo => prestamo.Fecha_Devolucion ) ? 'Devuelto' : 'Pendiente'
  }

  getClaseEstadoPrestamo = (estado: EstadoPrestamo) => `Prestamo_${estado.replace(/\s/g, '_')}`;

  getSimboloPrestamo = (estado: EstadoPrestamo) => estado === 'Pendiente' ? 'error' : estado === 'Devuelto' ? 'check_circle' : 'grade';
  
}