import { Component } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ImagenesService } from 'src/app/services/imagenes.service';

@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent {

  constructor(
    private imagenesService: ImagenesService,
  ){
    this.Archivo = new FormControl(undefined)
  }

  Archivo: FormControl

  subirImagen = ( archivos ) => this.imagenesService.subirArchivo( archivos, 'productos' )
}
