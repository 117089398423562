import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Lista_Experiencia_General } from 'src/app/models/experiencia-general';
import { ExperienciaGeneral, Info_usuario, NivelPrimerosAuxilios } from 'src/app/models/info_usuarios';
import { Lista_Niveles_Primeros_Auxilios } from 'src/app/models/nivel_primeros_auxilios';
import { PopupService } from '../../../services/popups.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { EquipoService } from 'src/app/services/equipo.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-definir-nivel-dialog',
  templateUrl: './definir-nivel-dialog.component.html',
  styleUrls: ['./definir-nivel-dialog.component.css']
})
export class DefinirNivelDialogComponent {
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Info_usuario,
    private formBuilder: FormBuilder,
    private equipoService: EquipoService,
    private popupService: PopupService,
    private router: Router,
  ){
    this.crearFormPuntaje()
  }

  //////////  MARCADOR: Elementos Formulario /////////////////////////////////////////////////////////////////
  get Puntaje_Experiencia_General() { return this.form_Puntaje.get('Puntaje_Experiencia_General') as FormControl<number>}
  get Puntaje_Experiencia_Empresa() { return this.form_Puntaje.get('Puntaje_Experiencia_Empresa') as FormControl<number>}
  get Nivel_Primeros_Auxilios()     { return this.form_Puntaje.get('Nivel_Primeros_Auxilios')     as FormControl<number>}
  //////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////

  Lista_Experiencia_General = Lista_Experiencia_General;
  Lista_Niveles_Primeros_Auxilios = Lista_Niveles_Primeros_Auxilios;

  form_Puntaje   : FormGroup;


  crearFormPuntaje() {
    this.form_Puntaje = this.formBuilder.group({
      Puntaje_Experiencia_General : ['', [Validators.required]],
      Puntaje_Experiencia_Empresa : ['', [Validators.required]],
      Nivel_Primeros_Auxilios     : ['', [Validators.required]],
    })
    if(this.data.Puntaje){
      const Puntaje = {...this.data.Puntaje}
      delete Puntaje.Nivel
      delete Puntaje.Puntaje_Final
      if(Puntaje) this.form_Puntaje.setValue( Puntaje )
    }
  }
  // NOTA: Experiencia general(0-3)     = Valor x 15 (max 45) //
  // NOTA: Experiencia específica(0-3)  = Valor x 10 (max 30) //
  // NOTA: Nivel Primeros auxilios(0-5) = Valor x 5  (max 25) //
  
  // NOTA: El puntaje va de 0 a 100 //
  getPuntaje = () => {
    const cosciente_Exp_Gen = 15;
    const cosciente_Exp_Emp = 10;
    const cosciente_Niv_Prim_Aux = 5;
    
    if( this.form_Puntaje.valid ) {
      const Puntaje_Experiencia_General = (this.Puntaje_Experiencia_General.value * cosciente_Exp_Gen)
      const Puntaje_Experiencia_Empresa = (this.Puntaje_Experiencia_Empresa.value * cosciente_Exp_Emp)
      const Nivel_Primeros_Auxilios = (this.Nivel_Primeros_Auxilios.value * cosciente_Niv_Prim_Aux)

      return Puntaje_Experiencia_General + Puntaje_Experiencia_Empresa + Nivel_Primeros_Auxilios;
    }
    else return 0
  }

  // NOTA: A(0-30), B(30-60), C(60-80), D(80-90) //
  getNivel = () => {
    const puntaje = this.getPuntaje();
    if(puntaje <  30) return 'D';
    if(puntaje <  60) return 'C';
    if(puntaje <  90) return 'B';
    if(puntaje >= 90) return 'A';
  }

  setPuntaje(){

    if(this.form_Puntaje.untouched) return

    const Info_Actualizada: Info_usuario = {
      ...this.data,
      Puntaje: {
        Puntaje_Experiencia_General: this.Puntaje_Experiencia_General.value,
        Puntaje_Experiencia_Empresa: this.Puntaje_Experiencia_Empresa.value,
        Nivel_Primeros_Auxilios: this.Nivel_Primeros_Auxilios.value,
        Puntaje_Final: this.getPuntaje(),
        Nivel: this.getNivel(),
      }
    }

    this.equipoService.actualizarInfoUsuario(Info_Actualizada)
      .then(
        () => {
          const PopupMensaje = `Se ha puntuado al usuario  ${Info_Actualizada.Nombres} ${Info_Actualizada.Apellidos}`;
          this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
        }
      )
    // FIXME: por ahora se me ocurrio esto para actualizar la tabla //
      .then(()=>this.router.navigate(['dashboard']))
      .then(()=>this.router.navigate(['equipo']))
      .catch(
        () => {
          const PopupMensaje = `No se ha podido actualizar la información del usuario  ${Info_Actualizada.Nombres} ${Info_Actualizada.Apellidos}`;
          this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
        }
      )
  }
}
