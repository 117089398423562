import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Categorias_Equipos, EstadoPrestamo, Inventario, Temas_Libros } from 'src/app/models/inventario';
import { ImagenesService } from 'src/app/services/imagenes.service';
import { InventariosService } from 'src/app/services/inventarios.service';
import { PopupService } from 'src/app/services/popups.service';
import { MatPaginator } from '@angular/material/paginator';

@Component({
  selector: 'app-inventario',
  templateUrl: './inventario.component.html',
  styleUrls: ['./inventario.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class InventarioComponent implements AfterViewInit {
  @ViewChildren(MatSort) sorts = new QueryList<MatSort>;

  constructor(
    private popupService: PopupService,
    private inventariosService: InventariosService,
    private imagenesService: ImagenesService,
    private router: Router,
  ) {
    this.Busqueda = new FormControl('')
  }

  ngAfterViewInit() {
    this.getEquipos();
  }

  columnasTabla: string[] = ['ID', 'codigo', 'precio', 'fecha_compra', 'marca_nombre', 'referencia_edicion', 'color_editorial', 'estado_prestamo', 'editar'];
  columnasTablaExpandidas: string[] = [...this.columnasTabla, 'expand'];
  Lista_Equipos: Inventario[] = [];
  Categorias_Equipos: {Equipos: Categorias_Equipos[], Libros: Temas_Libros[]} = { Equipos: [], Libros: [] };
  dataSource = {Equipos: {},Libros: {}};

  expandedElement: Inventario | null;
  Tipos = ['Equipos', 'Libros']
  Lista_Cargada = false;

  Busqueda: FormControl<string>;

  Modo_Borrar = false;

  toggleModoBorrar = () => {
    this.Modo_Borrar = !this.Modo_Borrar
    this.Modo_Borrar === true ? this.columnasTablaExpandidas.push('delete') : this.columnasTablaExpandidas.pop()
  };

  getEquipos() {
    this.inventariosService.Inventario.subscribe(
      async lista => {
        lista.forEach( async elemento => {
          elemento.URL_Imagen =  await this.imagenesService.getImagen(elemento.URL_Imagen).then(valor => valor.toString())
        })

        this.Lista_Equipos = await lista


        lista.filter( equipo => equipo.Equipo_o_Libro === 'Equipo')
        .map( equipo => equipo.Categoria_o_Tema).forEach(
          (categoria: Categorias_Equipos) => !this.Categorias_Equipos.Equipos.includes(categoria) ? this.Categorias_Equipos.Equipos.push(categoria) : null
        )
        
        lista.filter( equipo => equipo.Equipo_o_Libro === 'Libro')
        .map( equipo => equipo.Categoria_o_Tema).forEach(
          (categoria: Temas_Libros) => !this.Categorias_Equipos.Libros.includes(categoria) ? this.Categorias_Equipos.Libros.push(categoria) : null
        )


        this.Categorias_Equipos.Equipos.forEach(
          (categoria, index) => {
            const data_Equipos = new MatTableDataSource(this.Lista_Equipos.filter( equipo => equipo.Categoria_o_Tema === categoria && equipo.Equipo_o_Libro === 'Equipo' ))

            // NOTA: Tocó ponerle este timeout para que funcionara el sort //
            setTimeout( () => data_Equipos.sort = this.sorts.find( (_, i: number) => i == index) )
            
            this.dataSource['Equipos'][categoria] = data_Equipos
          }
        )
        this.Categorias_Equipos.Libros.forEach(
          (categoria, index) => {
            const data_Libros = new MatTableDataSource(this.Lista_Equipos.filter( equipo => equipo.Categoria_o_Tema === categoria && equipo.Equipo_o_Libro === 'Libro' ))

            // NOTA: Tocó ponerle este timeout para que funcionara el sort //
            setTimeout( () => data_Libros.sort = this.sorts.find( (_, i: number) => i == index) )
            
            this.dataSource['Libros'][categoria] = data_Libros
          }
        )
        this.Lista_Cargada = true;

      }
    )
  }

  dataCategoria = (tipo, categoria) => this.dataSource[tipo][categoria]

  agregarElementoInventario = (elemento?: Inventario) => this.popupService.popupAgregarElementoInventario(elemento)

  borrarElemento(elemento: Inventario) {
    const PopupTitulo = `Eliminar elemento ${elemento.Codigo_Elemento}`;
    const PopupPregunta = `¿Seguro desea eliminar el elemento ${elemento.Codigo_Elemento}?`;
    const Accion = (): void => this.inventariosService.borrarElemento(elemento);

    this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }

  getNumeroElementosCategoria = (categoria: Categorias_Equipos | Temas_Libros) =>
    Object.values(this.inventariosService.Inventario.getValue()).filter( (elemento: Inventario) => elemento.Categoria_o_Tema === categoria).length;

  getEstadoPrestamo = (elemento: Inventario): EstadoPrestamo => {
    const estadoPrestamo = this.inventariosService.Prestamos.getValue().filter(
      prestamo =>
        prestamo.Fecha_Aprobacion && prestamo.Estado_Solicitud === 'Aprobada'
    ).filter( prestamo => prestamo.Codigos_Elementos_Prestamo.find(item => item.Codigo_Elemento === elemento.Codigo_Elemento))

    if(!estadoPrestamo.length) return 'Nuevo'
    if(!estadoPrestamo.find( prestamo => prestamo.Fecha_Recogida)) return 'Por recoger'
    return !!estadoPrestamo.find( prestamo => prestamo.Fecha_Devolucion ) ? 'Devuelto' : 'Pendiente'
  }

  getClaseEstadoPrestamo = (estado: EstadoPrestamo) => `Prestamo_${estado.replace(/\s/g, '_')}`;

  getSimboloPrestamo = (estado: EstadoPrestamo) => estado === 'Pendiente' ? 'error' : estado === 'Devuelto' ? 'check_circle' : 'grade';

  verImagenGrande = (url: string ) => this.popupService.popupVerImagenGrande (url);

  filtrar = (valor: string) => Object.values(this.dataSource).forEach(
    tipo => Object.values(tipo).forEach( (elemento: MatTableDataSource<Inventario, MatPaginator>) => elemento.filter = valor )
  )

  resetBusqueda() {
    this.Busqueda.reset();
    this.filtrar( undefined )
  }

  filtro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtrar(filterValue.trim().toLowerCase() )
  }

}
