<ng-container *ngIf="Lista_Cargada; else cargador">
    <mat-toolbar>
        <button mat-fab extended color="accent" (click)="agregarElementoInventario()">Agregar Elemento<mat-icon>add</mat-icon> </button>
    
        <mat-form-field>
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="filtro($event)" placeholder="Ej: Por nombre" [formControl]="Busqueda">
            <button mat-icon-button *ngIf="Busqueda.value" matSuffix aria-label="Clear" (click)="resetBusqueda()">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>

        <button mat-fab extended [color]="Modo_Borrar ? 'accent' : 'warn'" (click)="toggleModoBorrar()">{{ Modo_Borrar ? 'Cancelar Borrar' : 'Borrar Elemento' }}<mat-icon>{{ Modo_Borrar ? 'cancel' : 'delete' }}</mat-icon> </button>
    
    </mat-toolbar>
    
    <div id="Contenedor_Tabla" >
        <div *ngFor="let tipo of Tipos">
            <table mat-table mat-text-column="justify: 'center'" [dataSource]="dataCategoria(tipo, categoria)" multiTemplateDataRows matSort matSortActive="codigo" matSortDirection="asc"  class="mat-elevation-z8" *ngFor="let categoria of Categorias_Equipos[tipo]; let index = index">
            
                <ng-container matColumnDef="ID">
                    <th class="align_center" mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.ID }} </td>
                </ng-container>
            
                <ng-container matColumnDef="codigo">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Código </th>
                    <td mat-cell *matCellDef="let elemento" class="Codigo"> {{ elemento.Codigo_Elemento }} </td>
                </ng-container>
    
                <ng-container matColumnDef="precio">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Precio (COP) </th>
                    <td mat-cell *matCellDef="let elemento"> <span matPrefix>$ &nbsp;</span>{{ elemento.Precio_COP | mask:'separator': {thousandSeparator: '.'} }} </td>
                </ng-container>
    
                <ng-container matColumnDef="fecha_compra">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha compra </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Fecha_Compra | date }} </td>
                </ng-container>
    
                <ng-container matColumnDef="marca_nombre">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> {{ tipo === 'Equipos' ? 'Marca' : 'Nombre' }} </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Marca_Titulo }} </td>
                </ng-container>
    
                <ng-container matColumnDef="referencia_edicion">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> {{ tipo === 'Equipos' ? 'Referencia' : 'Edición' }} </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Referencia_Edicion }} </td>
                </ng-container>
    
                <ng-container matColumnDef="color_editorial">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> {{ tipo === 'Equipos' ? 'Color' : 'Editorial' }} </th>
                    <td mat-cell *matCellDef="let elemento">
                        <ng-container *ngIf="tipo === 'Equipos'; else editorial">
                            <div class="Cuadro_Color" [style.background-color]="elemento.Color_Editorial"></div>
                        </ng-container>
                        <ng-template #editorial>
                            {{ elemento.Color_Editorial }}
                        </ng-template>
                        
                        
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="estado_prestamo">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Estado Prestamo </th>
                    <td mat-cell *matCellDef="let elemento">
                        <mat-chip [class]="getClaseEstadoPrestamo( getEstadoPrestamo(elemento) )">
                            <div class='Chips_Prestamos'>
                                {{ getEstadoPrestamo(elemento)}}
                                <mat-icon>{{ getSimboloPrestamo( getEstadoPrestamo(elemento) ) }}</mat-icon>
                            </div>
                        </mat-chip>
                    </td>
                </ng-container>
            
                <ng-container matColumnDef="editar">
                    <th class="align_center" mat-header-cell *matHeaderCellDef ></th>
                    <td mat-cell *matCellDef="let elemento">
                        <button mat-icon-button (click)="agregarElementoInventario(elemento)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="delete" *ngIf="Modo_Borrar">
                    <th class="align_center" mat-header-cell *matHeaderCellDef > </th>
                    <td mat-cell *matCellDef="let elemento">
                        <button mat-mini-fab color="warn" (click)="borrarElemento(elemento)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="columnasTablaExpandidas.length">
                        <div *ngIf="element == expandedElement" class="Elemento_Expandible" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="QR">
                                <app-codigo-qr [ColorQR]="tipo === 'Equipos' ? element.Color_Editorial : null" [ContenidoQR]="element.Codigo_Elemento"></app-codigo-qr>
                            </div>
                            <div class="Datos_Compra">
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title>Datos compra</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content><strong>Fecha: </strong>{{ element.Fecha_Compra | date }}</mat-card-content>
                                    <mat-card-content><strong>Lugar: </strong>{{ element.Lugar_Compra }}</mat-card-content>
                                    <mat-card-content><strong>Forma: </strong>{{ element.Forma_Compra }}</mat-card-content>
                                    <mat-card-content><strong>Moneda: </strong>{{ element.Moneda_Compra }}</mat-card-content>
                                    <mat-card-content><strong>Precio: </strong>{{ element.Precio | mask:'separator': {thousandSeparator: element.Moneda_Compra === 'COP' ? '.' : ','} }} {{ element.Moneda_Compra }}</mat-card-content>
                                </mat-card>
                            </div>
                            <div class="Descripcion">
                                <mat-card>
                                    <mat-card-header>
                                        <mat-card-title>Descripción</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>{{element.Descripcion}}</mat-card-content>
                                </mat-card>
                            </div>
                            <div class="Observaciones">
                                <mat-card *ngIf="element.Observaciones">
                                    <mat-card-header>
                                        <mat-card-title>Observaciones</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content>{{element.Observaciones}}</mat-card-content>
                                </mat-card>
                            </div>
                            <div class="Imagen Boton_Hover" [style.backgroundImage]="'url('+ element.URL_Imagen +')'">
                                <button mat-mini-fab color="primary" (click)="verImagenGrande(element.URL_Imagen)"><a><mat-icon>visibility</mat-icon></a></button>
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="Heading_Tipo">
                    <th class="header_tabla" mat-header-cell [attr.colspan]="this.columnasTablaExpandidas.length" *matHeaderCellDef>
                        <h2> {{tipo}} </h2>
                    </th>
                </ng-container>
                
                <ng-container matColumnDef="Heading_Categoria">
                    <th class="header_tabla Heading_Categoria" mat-header-cell [attr.colspan]="this.columnasTablaExpandidas.length" *matHeaderCellDef>
                        <h2 class="Sub-titulo"> {{categoria}}<div class="Cantidad_Elementos"># Elementos categoría: <strong>{{getNumeroElementosCategoria(categoria)}}</strong></div> </h2>
                    </th>
                </ng-container>
            
            
                <ng-container *ngIf="index === 0">
                    <tr mat-header-row *matHeaderRowDef="['Heading_Tipo']; sticky: true" [ngClass]="tipo === 'Equipos' ? 'Header_Tipo_Equipos': 'Header_Tipo_Libros'">{{tipo}}</tr>
                </ng-container>
                
                <tr mat-header-row *matHeaderRowDef="['Heading_Categoria']; sticky: true" [ngClass]="tipo === 'Equipos' ? 'Header_Categoria_Equipos': 'Header_Categoria_Libros'">{{categoria}}</tr>
                <tr mat-header-row *matHeaderRowDef="columnasTablaExpandidas; sticky: true" class="header_tabla" [ngClass]="tipo === 'Equipos' ? 'Headers_Equipos': 'Headers_Libros'"></tr>
    
                <tr mat-row *matRowDef="let element; columns: columnasTablaExpandidas;" class="Columnas_Fila_Expandible" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="Fila_Expandible"></tr>
            </table>
        </div>
        
    </div>

</ng-container>

<ng-template #cargador>
    probando
    <div class="Contenedor_Cargador">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>