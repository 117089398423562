import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-sistema-rating',
  templateUrl: './sistema-rating.component.html',
  styleUrls: ['./sistema-rating.component.css']
})
export class SistemaRatingComponent {

  @Input() Calificacion: number;

  constructor() { }


}
