import { Component, ChangeDetectionStrategy, TemplateRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Subject, Observable } from 'rxjs';

import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView, CalendarMonthViewBeforeRenderEvent } from 'angular-calendar';
import { ReservasService } from 'src/app/services/reservas.service';
import { MatDialog } from '@angular/material/dialog';
import { InformacionBloqueo, OrigenReserva, TipoEvento } from 'src/app/models/reservas.model';
import { EdicionBloqueoComponent } from 'src/app/shared/popups/edicion-bloqueo/edicion-bloqueo.component';
import { EdicionReservasComponent } from 'src/app/shared/popups/edicion-reservas/edicion-reservas.component';
import { PopupService } from 'src/app/services/popups.service';

@Component({
  selector: 'app-reservas',
  templateUrl: './reservas.component.html',
  styleUrls: ['./reservas.component.css']
})
export class ReservasComponent {

  view: CalendarView = CalendarView.Month;

  CalendarView = CalendarView;

  fechaHoy = new Date();
  viewDate: Date = this.fechaHoy;

  refresh: Subject<any> = new Subject();

  activeDayIsOpen: boolean = false;

  cargado = false;

  eventos = () => undefined;

  vistaHoy = () => isSameMonth(this.fechaHoy, this.viewDate);

  getEventos ()  {
    const intervalo = setInterval(() => {
      // if ( this.reservasService.suscrito ) {
      if (
        this.reservasService.ReservasDirectas && this.reservasService.ReservasAliados &&
        this.reservasService.Bloqueos && this.reservasService.eventos
      ) {
        this.eventos = () => this.reservasService.eventos;
        this.actualizar();
        clearInterval(intervalo);
      }
    }, 100);
  }

  actualizar(): void {
    this.cargado = true;
    this.refresh.next('');
    this.cdr.detectChanges();
  }

  constructor(
    private cdr: ChangeDetectorRef,
    public reservasService:ReservasService,
    private dialog: MatDialog,
    private popupConfirmarService: PopupService,
  ) {
    this.getEventos();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) { this.activeDayIsOpen = false; }
      else { this.activeDayIsOpen = true; }
      this.viewDate = date;
    }
  }

  moverFechaBloqueo = ({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void => {
    const PopupTitulo = `Editar fecha bloqueo`;
    const PopupPregunta = `¿Deseas editar las fechas bloqueadas a ${newStart.toLocaleDateString()}-${newEnd.toLocaleDateString()}?`;
    const Accion = (): void => {
      this.cargado = false;
      this.reservasService.moverFechaBloqueo({event, newStart, newEnd} as CalendarEventTimesChangedEvent)
        .then( () => {
          this.popupConfirmarService.mensajeConfirmacion(`Cambio guardado correctamente`, 'Confirmacion');
          this.actualizar();
        })
        .catch( () =>
          this.popupConfirmarService.mensajeConfirmacion(`Problema al guardar el cambio`, 'Error')
        );
    };

    this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );

  }


  verEvento = (evento: CalendarEvent) => {
    let Tipo: string;
    let ruta: Array<any>
    const getRuta = (parte) => this.reservasService[parte];

    switch (evento.meta.Tipo as TipoEvento) {
          case 'Reserva_Directa': 
          Tipo = 'Directa'
          ruta = getRuta('ReservasDirectas') 
          break
          case 'Reserva_Aliados':
          Tipo = 'Aliado'
          ruta = getRuta('ReservasAliados') 
          break
          case 'Bloqueo':
          Tipo = 'Edición'
          ruta = getRuta('Bloqueos') 
          break
    }

    const dialog = Tipo === 'Edición' ? EdicionBloqueoComponent : EdicionReservasComponent

    this.dialog.open(dialog as any, {data: {
      Tipo,
      InfoEvento: ruta.find( InfoEvento => evento.id === InfoEvento.id )
    }, disableClose: Tipo === 'Edición'})
    .afterClosed().subscribe(
      () => this.actualizar()
    )
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    renderEvent.body.forEach( dia => {
      if (dia.events.find(evento => evento.meta?.Tipo === 'Bloqueo') ){
        dia.cssClass = 'Bloqueos';
      }
      dia.badgeTotal = dia.events.filter(evento => evento.meta?.Tipo === undefined || evento.meta?.Tipo !== 'Bloqueo').length;
    });
  }

  agregarBloqueo(): void {
    this.dialog.open(EdicionBloqueoComponent, { disableClose: true })
    .afterClosed().subscribe(
      () => this.actualizar()
    );
  }

  borrarBloqueo( evento: InformacionBloqueo ): void {
    const PopupTitulo = `Borrar fecha bloqueo`;
    const PopupPregunta = `¿Deseas borrar las fechas bloqueadas a ${evento.Fecha_Bloqueo.Inicio.toDate().toLocaleDateString()}-${evento.Fecha_Bloqueo.Fin.toDate().toLocaleDateString()}?`;
    const Accion = (): void => {
      this.reservasService.borrarBloqueo(evento)
        .then( () => this.actualizar() );
    };

    this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }

  setView = (view: CalendarView) => this.view = view;

  closeOpenMonthViewDay = () => this.activeDayIsOpen = false;

}
