<h2 mat-dialog-title>Agregar elemento</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="formulario_elemento">
        <span>
            <mat-form-field>
                <mat-label>Tipo</mat-label>
                <mat-select [formControl]="Equipo_o_Libro">
                    <mat-option [value]="'Equipo'">Equipo</mat-option>
                    <mat-option [value]="'Libro'">Libro</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="Equipo_o_Libro.value">
                <mat-label>{{ Equipo_o_Libro.value === 'Equipo' ? 'Categoría' : 'Tema' }}</mat-label>
                <mat-select [formControl]="Categoria_o_Tema">
                    <mat-option *ngFor="let categoria of (Equipo_o_Libro.value === 'Equipo' ? Lista_Categorias_Equipos: Lista_Temas_Libros) | keyvalue" [value]="categoria.key">
                        {{categoria.key}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <ng-container *ngIf="Equipo_o_Libro.value && Categoria_o_Tema.value">
                <ng-container *ngIf="!Codigo_Elemento.value; else codigo">
                    <button mat-fab extended color="primary" (click)="crearCodigo()">Crear código elemento<mat-icon>add</mat-icon></button>
                </ng-container>
                <ng-template #codigo>
                    <mat-form-field>
                        <mat-label>Código Elemento</mat-label>
                        <input matInput type="text" [formControl]="Codigo_Elemento">
                    </mat-form-field>
                    <app-codigo-qr [ColorQR]="Equipo_o_Libro.value === 'Equipo' ? Color_Editorial.value || '000' : null" [ContenidoQR]="Codigo_Elemento.value"></app-codigo-qr>
                </ng-template>
            </ng-container>
        </span>
        
        <ng-container *ngIf="Codigo_Elemento.value">

            <mat-divider></mat-divider>

            <input #upload type="file" accept="image/*" hidden (change)="presubirImagen($event)"/>
            <div class="Contenedor_Imagen">
                <img [src]="Imagen ? Imagen : '/assets/images/sin_imagen.svg'" alt="Imagen_inventario">
                <button mat-mini-fab color="primary" (click)="upload.click()"><mat-icon>edit</mat-icon></button>
            </div>

            <span>
                <mat-form-field>
                    <mat-label>Forma Compra</mat-label>
                    <mat-select [formControl]="Forma_Compra">
                        <mat-option *ngFor="let forma of Lista_Formas_Compra" [value]="forma" (click)="Regalo()">{{forma}}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field *ngIf="Forma_Compra.value">
                    <mat-label>{{ Forma_Compra.value !== 'Regalo' ? 'Lugar Compra' : 'Origen Regalo'}}</mat-label>
                    <input matInput type="text" [formControl]="Lugar_Compra"  [matAutocomplete]="auto">
                    <button mat-icon-button *ngIf="Lugar_Compra.value" matSuffix aria-label="Clear" (click)="borrarCampo(Lugar_Compra)">
                        <mat-icon color="warn">close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                        <mat-option *ngFor="let lugar of getValoresExistentes('Lugar_Compra')" [value]="lugar">{{lugar}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field *ngIf="Forma_Compra.value">
                    <mat-label>Fecha {{ Forma_Compra.value === 'Regalo' ? 'Regalo' : 'Compra' }}</mat-label>
                    <input matInput [matDatepicker]="picker" formControlName="Fecha_Compra">
                    <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker disabled="false"></mat-datepicker>
                </mat-form-field>
            </span>

            <ng-container *ngIf="Forma_Compra.value && Forma_Compra.value !== 'Regalo'">
                <mat-divider></mat-divider>
    
                <span>
                    <mat-form-field>
                        <mat-label>Moneda Compra</mat-label>
                        <mat-select [formControl]="Moneda_Compra">
                            <mat-option *ngFor="let moneda of Lista_Monedas | keyvalue" [value]="moneda.value" (click)="Moneda()">{{moneda.key}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="Moneda_Compra.value">
                        <mat-label>Precio</mat-label>
                        <!-- <span matPrefix>$ &nbsp;</span> -->
                        <!-- <span matSuffix>&nbsp; {{ Moneda_Compra.value }}</span> -->
                        <input matInput type="text" [formControl]="Precio" mask="separator" [thousandSeparator]="Moneda_Compra.value === 'COP' ? '.' : ',' " [suffix]="' ' + Moneda_Compra.value" (change)="MonedaCOP()">
                        <button mat-icon-button *ngIf="Precio.value" matSuffix aria-label="Clear" (click)="borrarCampo(Precio)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                    <mat-form-field *ngIf="Moneda_Compra.value && Moneda_Compra.value !== 'COP'">
                        <mat-label>Precio (COP)</mat-label>
                        <span matPrefix>$ &nbsp;</span>
                        <input matInput type="text" [formControl]="Precio_COP" mask="separator" thousandSeparator=".">
                        <button mat-icon-button *ngIf="Precio_COP.value" matSuffix aria-label="Clear" (click)="borrarCampo(Precio_COP)">
                            <mat-icon color="warn">close</mat-icon>
                        </button>
                    </mat-form-field>
                </span>
            </ng-container>
            

            <mat-divider></mat-divider>
            
            <span>
                <mat-form-field>
                    <mat-label>{{ Equipo_o_Libro.value === 'Equipo' ? 'Marca' : 'Título Libro' }}</mat-label>
                    <input matInput type="text" [formControl]="Marca_Titulo"  [matAutocomplete]="autoMarcaTitulo">
                    <button mat-icon-button *ngIf="Marca_Titulo.value" matSuffix aria-label="Clear" (click)="borrarCampo(Marca_Titulo)">
                        <mat-icon color="warn">close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #autoMarcaTitulo="matAutocomplete">
                        <mat-option *ngFor="let valor of getValoresExistentes('Marca_Titulo')" [value]="valor">{{valor}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ Equipo_o_Libro.value === 'Equipo' ? 'Referencia' : 'Edición' }}</mat-label>
                    <input matInput type="text" [formControl]="Referencia_Edicion"  [matAutocomplete]="autoReferenciaEdicion">
                    <button mat-icon-button *ngIf="Referencia_Edicion.value" matSuffix aria-label="Clear" (click)="borrarCampo(Referencia_Edicion)">
                        <mat-icon color="warn">close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #autoReferenciaEdicion="matAutocomplete">
                        <mat-option *ngFor="let valor of getValoresExistentes('Referencia_Edicion')" [value]="valor">{{valor}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>{{ Equipo_o_Libro.value === 'Equipo' ? 'Color' : 'Editorial' }}</mat-label>
                    <input matInput [type]="Equipo_o_Libro.value === 'Equipo' ? 'color' : 'text'" [formControl]="Color_Editorial"  [matAutocomplete]="autoEditorial" [style.display]="Equipo_o_Libro.value === 'Equipo' ? 'none' : 'inherit'">
                    <ngx-colors ngx-colors-trigger [formControl]="Color_Editorial" format="hex" *ngIf="Equipo_o_Libro.value === 'Equipo'" acceptLabel="Aceptar" cancelLabel=" Cancelar"></ngx-colors>
                    <button mat-icon-button *ngIf="Color_Editorial.value" matSuffix aria-label="Clear" (click)="borrarCampo(Color_Editorial)">
                        <mat-icon color="warn">close</mat-icon>
                    </button>
                    <mat-autocomplete autoActiveFirstOption #autoEditorial="matAutocomplete">
                        <mat-option *ngFor="let valor of getValoresExistentes('Color_Editorial')" [value]="valor">{{valor}}</mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </span>

            <mat-divider></mat-divider>
            
            <mat-form-field>
                <mat-label>Descripción</mat-label>
                <input matInput type="text" [formControl]="Descripcion">
                <button mat-icon-button *ngIf="Descripcion.value" matSuffix aria-label="Clear" (click)="borrarCampo(Descripcion)">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field>
                <mat-label>Observaciones</mat-label>
                <input matInput type="text" [formControl]="Observaciones">
                <button mat-icon-button *ngIf="Observaciones.value" matSuffix aria-label="Clear" (click)="borrarCampo(Observaciones)">
                    <mat-icon color="warn">close</mat-icon>
                </button>
            </mat-form-field>

            <mat-divider></mat-divider>

            <span class="Cuadro">
                <p>Estado Prestamo</p>
                <mat-chip [class]="getClaseEstadoPrestamo( getEstadoPrestamo(elemento) )">
                    <div class='Chips_Prestamos'>
                        {{ getEstadoPrestamo(elemento)}}
                        <mat-icon>{{ getSimboloPrestamo( getEstadoPrestamo(elemento) ) }}</mat-icon>
                    </div>
                </mat-chip>

            </span>
        </ng-container>
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-fab extended cdkFocusInitial color="accent" (click)="GuardarElemento()" *ngIf="formulario_elemento.valid && ((formulario_elemento.dirty && (imagenPresubida || URL_Imagen.value)) || (formulario_elemento.pristine && imagenPresubida ))">Guardar</button>
    <button mat-fab extended color="warn" (click)="Cancelar()">Cancelar</button>
</mat-dialog-actions>