<h2 mat-dialog-title>{{data.Titulo}}</h2>
<mat-dialog-content class="mat-typography">
    <p>{{data.Texto}}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close color="warn" *ngIf="!data.SinCancelar">
        <mat-icon>cancel</mat-icon>
        No
    </button>
    <button mat-button mat-dialog-close color="accent" cdkFocusInitial (click)="accion()">
        <mat-icon>check_circle</mat-icon>
        {{ data.SinCancelar ? 'Aceptar' : 'Sí' }}
    </button>
</mat-dialog-actions>