import { Timestamp } from '@firebase/firestore-types';
import { CalendarEventAction } from 'angular-calendar';
import { CalendarEvent } from 'calendar-utils';

export const IdiomaCategoriasProducto = {
    Explorer: {
        ES: 'Explorador',
        EN: 'Explorer'
    },
    Essential: {
        ES: 'Esencial',
        EN: 'Essential'
    },
    Summit: {
        ES: 'Cumbre',
        EN: 'Summit'
    }
};

export type IdiomasManejados = 'Español' | 'Inglés';
export type CategoríasNacionalidades = 'Colombiano' | 'Extranjero_CAN' | 'Extranjero_No_CAN';
export type EstadoReserva = 'Sin Confirmar' | 'Pendiente' | 'Confirmada';


export interface InformacionReserva {
    id: string;
    Codigo_Reserva: string;
    ID_Producto: number;
    Nombre_Producto: string;
    Tipo_Reserva: TipoReserva;
    Categoria_Reserva: CategoriasProducto;
    Idioma_Guianza: IdiomasManejados;
    Fecha_Actividad: RangoTimestamp;
    Tipo_Moneda: string;
    Numero_Viajeros: number;
    Datos_Viajeros: Viajero[];
    Precio_Total_COP: number;
    Precio_Total_Cambio: number;
    Base_IVA_Cambio: number;
    Base_IVA_COP: number;

    Opcionales_Incluidos: Opcionales[];
}

export interface InformacionReservaDirecta extends InformacionReserva {
    Fecha_Compra: Timestamp;

    Datos_Comprador: {
        Nombre: string;
        Apellido: string;
        Correo: string;
        Tipo_Documento: string;
        Documento: string;
    }
}

export interface InfoReservaDirectaRespuesta extends InformacionReservaDirecta {
    Estado_Reserva: EstadoReserva;
    Informacion_Pago: InformacionPagoEpayco;
    UID_Quien_Confirma?: string;
}

export interface InformacionReservaAliados extends InformacionReserva {
    Fecha_Reserva: Timestamp;
    Estado_Reserva: EstadoReserva;

    Codigo_Empresa_Aliada: string;
    Nombre_Persona_Reserva: string;
    Informacion_Pago: InformacionPagoAliados;
}

export interface Viajero {
    Edad: number;
    Pais: string;
    Categoría_Nacionalidad: CategoríasNacionalidades;
    Precio_COP: number;
    Base_IVA_COP: number;
}

export type TipoReserva= 'Privado'|'Programado';
export type CategoriasProducto = keyof typeof IdiomaCategoriasProducto;

export interface OpcionalesTraducibles {
    Elemento: Traducible;
    Tipo_Costo: TipoCosto;
    Precio_COP: Precios;
}

export interface Opcionales extends OpcionalesTraducibles {
    Cantidad: number;
}

export type TipoCosto = 'Personal' | 'Grupal';

export interface Precios {
    Aplica_IVA: boolean;
    Precio_Sin_IVA: number;
    Base_IVA: number;
}

export interface Traducible {
    EN: string;
    ES: string;
}

export interface RangoTimestamp {
    Inicio: Timestamp;
    Fin: Timestamp;
}
export type TipoEvento = 'Bloqueo' | 'Reserva_Directa' | 'Reserva_Aliados';
export type OrigenReserva = 'Directa' | 'Aliado';

export interface InformacionBloqueo {
    id: string;
    Editor_uid: string;
    Fecha_Cambio: Timestamp;
    Fecha_Bloqueo: RangoTimestamp;
    Resumen: string;
    Descripcion: string;
}

export class Reserva_Directa implements CalendarEvent {
    id: string;
    start: Date;
    end: Date;
    title: string;
    allDay = true;
    color;
    meta = {
        Tipo: 'Reserva_Directa',
    };

    constructor( informacion: InfoReservaDirectaRespuesta ) {
        this.id = informacion.id;
        this.color = informacion.Estado_Reserva === 'Sin Confirmar' ? colors.yellow : colors.green;
        this.start = informacion.Fecha_Actividad.Inicio.toDate();
        this.end = informacion.Fecha_Actividad.Fin.toDate();
        this.title = `${informacion.Tipo_Reserva} - ${informacion.Nombre_Producto} - ${informacion.Categoria_Reserva} - ${informacion.Idioma_Guianza}`;

    }

}

export class Reserva_Aliados implements CalendarEvent {
    id: string;
    start: Date;
    end: Date;
    title: string;
    allDay = true;
    color;
    meta = {
        Tipo: 'Reserva_Aliados',
    };

    constructor( informacion: InformacionReservaAliados ) {
        this.id = informacion.id;
        this.color = informacion.Estado_Reserva === 'Sin Confirmar' ? colors.yellow : colors.blue;
        this.start = informacion.Fecha_Actividad.Inicio.toDate();
        this.end = informacion.Fecha_Actividad.Fin.toDate();
        this.title =  `${informacion.Tipo_Reserva} - ${informacion.Nombre_Producto} - ${informacion.Categoria_Reserva} - ${informacion.Idioma_Guianza}`;


    }

}

export class Bloqueo implements CalendarEvent {
    id: string;
    start: Date;
    end: Date;
    title: string;
    color = colors.red;
    draggable = true;
    actions;
    resizable = {
        beforeStart: true,
        afterEnd: true,
    };
    meta = {
        Tipo: 'Bloqueo',
    };

    constructor( informacion: InformacionBloqueo, acciones?: CalendarEventAction[] ) {
        this.id = informacion.id;
        this.title = informacion.Resumen;
        this.start = informacion.Fecha_Bloqueo.Inicio.toDate();
        this.end = informacion.Fecha_Bloqueo.Fin.toDate();
        if (acciones) { this.actions = acciones; }
    }

}


export const colors: Colores = {
    red: {
        primary: '#ad2121',
        secondary: '#FAE3E3',
    },
    blue: {
        primary: '#1e90ff',
        secondary: '#D1E8FF',
    },
    yellow: {
        primary: '#e3bc08',
        secondary: '#FDF1BA',
    },
    green: {
        primary: '#84e308',
        secondary: '#cdff8b',
    }
};

interface Colores {
    [key: string]: InfoColores;
}

interface InfoColores {
    primary: string;
    secondary: string;
}

export interface InformacionPagoAliados {
    // TODO: llenar esta interface //
}

export interface InformacionPagoEpayco {
    x_cust_id_cliente:       number;
    x_ref_payco:             number;
    x_id_factura:            string;
    x_id_invoice:            string;
    x_description:           string;
    x_amount:                number;
    x_amount_country:        number;
    x_amount_ok:             number;
    x_tax:                   number;
    x_amount_base:           number;
    x_currency_code:         string;
    x_bank_name:             string;
    x_cardnumber:            string;
    x_quotas:                string;
    x_respuesta:             string;
    x_response:              string;
    x_approval_code:         string;
    x_transaction_id:        string;
    x_fecha_transaccion:     Date;
    x_transaction_date:      Date;
    x_cod_respuesta:         number;
    x_cod_response:          number;
    x_response_reason_text:  string;
    x_cod_transaction_state: number;
    x_transaction_state:     string;
    x_errorcode:             string;
    x_franchise:             string;
    x_business:              string;
    x_customer_doctype:      string;
    x_customer_document:     string;
    x_customer_name:         string;
    x_customer_lastname:     string;
    x_customer_email:        string;
    x_customer_phone:        string;
    x_customer_movil:        string;
    x_customer_ind_pais:     string;
    x_customer_country:      string;
    x_customer_city:         string;
    x_customer_address:      string;
    x_customer_ip:           string;
    x_signature:             string;
    x_test_request:          string;
    x_extra1:                string;
    x_extra2:                string;
    x_extra3:                string;
    x_extra4:                string;
    x_extra5:                string;
    x_extra6:                string;
    x_extra7:                string;
    x_extra8:                string;
    x_extra9:                string;
    x_extra10:               string;
    x_type_payment:          string;
}