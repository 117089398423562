import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { animaciones } from 'src/app/animations/animaciones';
import { LoginService } from '../../services/login.service';

import { RecordarPasswordDialogComponent } from 'src/app/shared/popups/recordar-password-dialog/recordar-password-dialog.component';
import { PopupService } from '../../services/popups.service';
import { GeneralesService } from 'src/app/services/generales.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  animations: animaciones,
})
export class LoginComponent {

  formulario_login: FormGroup;
  Nombre_Completo: FormGroup;


  mensajeVerificacionEnviado = false;
  InfoUsuario = () => this.loginService.InfoUsuario;


  get Correo()   { return this.formulario_login.get('Correo')   as FormControl }
  get Password() { return this.formulario_login.get('Password') as FormControl }

  get Nombres()   { return this.Nombre_Completo.get('Nombres')   as FormControl }
  get Apellidos() { return this.Nombre_Completo.get('Apellidos') as FormControl }

  constructor(
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    private popupService: PopupService,
    private generalesService: GeneralesService,
    public dialog: MatDialog
  ) {
    this.crearFormulario();
  }

  crearFormulario(): void {
    this.formulario_login = this.formBuilder.group({
      Correo   : ['', [Validators.required, Validators.email]],
      Password : ['', [Validators.required]],
    });
    this.Nombre_Completo = this.formBuilder.group({
      Nombres   : [ '', [Validators.required] ],
      Apellidos : [ '', [Validators.required] ]
    })
  }

  ingresar = () => this.formulario_login.valid ? this.loginService.SignIn(this.Correo.value, this.Password.value) : null;

  borrarCampo = this.generalesService.borrarCampoForm;

  VerificarCorreo(): void {
    const PopupTitulo = `Enviar correo verificación`;
    const PopupPregunta = `Se va a enviar un correo de verificación a ${this.InfoUsuario().email}`;
    const Accion = (): void => {
      this.loginService.VerificarCorreo(this.Nombres.value, this.Apellidos.value);
      this.mensajeVerificacionEnviado = true;
    };
    this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }

  DialogRecordarPassword = () => this.dialog.open(RecordarPasswordDialogComponent)

  emailVerificado = () => this.loginService.emailVerificado;

  NombreCompleto = (): boolean => this.InfoUsuario().Nombres && this.InfoUsuario().Apellidos ? true : false;


}

