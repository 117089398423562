<h2 mat-dialog-title>Puntuar a persona</h2>
<mat-dialog-content class="mat-typography">
    <form [formGroup]="form_Puntaje">
        <mat-form-field>
            <mat-label>Experiencia General</mat-label>
            <mat-select formControlName="Puntaje_Experiencia_General">
                <ng-container *ngFor="let elemento of Lista_Experiencia_General; let index = index">
                    <mat-option [value]="index">{{ elemento }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>

        <!-- FIXME: Esto es temporal mientras se automatiza el numero de viajes -->
        <mat-button-toggle-group formControlName="Puntaje_Experiencia_Empresa">
            <ng-container *ngFor="let numero of [0,1,2,3]">
                <mat-button-toggle [value]="numero">{{ numero }}</mat-button-toggle>
            </ng-container>
        </mat-button-toggle-group>

        <mat-form-field>
            <mat-label>Nivel Primeros Auxilios</mat-label>
            <mat-select formControlName="Nivel_Primeros_Auxilios">
                <ng-container *ngFor="let nivel of Lista_Niveles_Primeros_Auxilios; let index = index">
                    <mat-option [value]="index">{{ nivel }}</mat-option>
                </ng-container>
            </mat-select>
        </mat-form-field>
    </form>
    <p>{{ getNivel() }}</p>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button color="warn" mat-dialog-close>Cancelar</button>
    <button mat-raised-button color="accent" mat-dialog-close cdkFocusInitial (click)="setPuntaje()">Guardar</button>
</mat-dialog-actions>