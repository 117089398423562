import { trigger, transition, style, animate, state, animateChild, query } from '@angular/animations';


export const animaciones = [
  trigger(
    'animacion_child', [
      transition(
        ':enter', [
          query('@*', [
            animateChild()
          ], {optional: true}),
        ],
      ),
      transition(
        ':leave', [
          query('@*', [
            animateChild()
          ], {optional: true}),
        ]
      )
    ]
  ),

  trigger(
    'animacion_fade_rapido', [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(
            '0.3s ease-out',
            style({ opacity: 1 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_fade_medio', [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(
            '0.5s ease-out',
            style({ opacity: 1 })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ opacity: 1 }),
          animate(
            '0.5s ease-in',
            style({ opacity: 0 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_fade_lento', [
      transition(
        ':enter', [
          style({ opacity: 0 }),
          animate(
            '0.8s ease-out',
            style({ opacity: 1 })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ opacity: 1 }),
          animate(
            '0.8s ease-in',
            style({ opacity: 0 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_zoomY_rapido', [
          transition(
        ':enter', [
          style({ height: 0 }),
          animate(
            '0.3s ease-out',
            style({ height: '*' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ height: '*' }),
          animate(
            '0.3s ease-in',
            style({ height: 0 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_zoomY_medio', [
          transition(
        ':enter', [
          style({ height: 0 }),
          animate(
            '0.5s ease-out',
            style({ height: '*' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ height: '*' }),
          animate(
            '0.5s ease-in',
            style({ height: 0 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_zoomY_lento', [
          transition(
        ':enter', [
          style({ height: 0 }),
          animate(
            '0.8s ease-out',
            style({ height: '*' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ height: '*' }),
          animate(
            '0.8s ease-in',
            style({ height: 0 })
          )
        ]
      )
    ]
  ),

  trigger(
    'animacion_zoom', [
      transition(
        ':enter', [
          style({ transform: 'scale(0)' }),
          animate(
            '0.3s ease-out',
            style({ transform: 'scale(1)' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ transform: 'scale(1)' }),
          animate(
            '0.3s ease-in',
            style({ transform: 'scale(0)' })
          )
        ]
      )
    ]
  ),

  trigger(
    'transicion_slide_right', [
      transition(
        ':enter', [
          style({ transform: 'translateX(-100%)' }),
          animate(
            '0.3s ease-out',
            style({ transform: 'translateX(0)' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ transform: 'translateX(0)' }),
          animate(
            '0.3s ease-in',
            style({ transform: 'translateX(100%)' })
          )
        ]
      )
    ]
  ),

  trigger(
    'transicion_slide_left_rapido', [
      transition(
        ':enter', [
          style({ transform: 'translateX(100%)' }),
          animate(
            '0.3s ease-out',
            style({ transform: 'translateX(0)' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ transform: 'translateX(0)' }),
          animate(
            '0.3s ease-in',
            style({ transform: 'translateX(-100%)' })
          )
        ]
      )
    ]
  ),

  trigger(
    'transicion_slide_left_lento', [
      transition(
        ':enter', [
          style({ transform: 'translateX(100%)' }),
          animate(
            '0.5s ease-out',
            style({ transform: 'translateX(0)' })
          )
        ]
      ),
      transition(
        ':leave', [
          style({ transform: 'translateX(0)' }),
          animate(
            '0.5s ease-in',
            style({ transform: 'translateX(-100%)' })
          )
        ]
      )
    ]
  ),


]