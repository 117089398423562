

<div class="Contenedor">
    <ng-container *ngIf="!loginService.isLoggedIn; else ingresado">
        <mat-card appearance="outlined" [@animacion_fade_rapido]>
            <mat-card-header>
                <mat-card-title>Log in</mat-card-title>
            </mat-card-header>
            <mat-card-content>
                <div class="Contenedor_Imagen">
                    <img src="assets/images/Sublime_Blanco.png" alt="Logo Sublime">
                </div>
                <form [formGroup]="formulario_login" (ngSubmit)= "null">
                    <h2></h2>
                    <br>
                    <mat-form-field class="Input">
                        <mat-label>Correo Acceso</mat-label>
                        <input matInput type="text" formControlName="Correo" (keyup.enter)="ingresar()">
                        <!-- <button mat-button *ngIf="Correo.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Correo)">
                            <mat-icon color="warn">close</mat-icon>
                        </button> -->
                        <mat-error *ngIf="Correo.invalid">
                            Ingresa un correo válido
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field class="Input">
                        <mat-label>Contraseña</mat-label>
                        <input matInput type="password" formControlName="Password" (keyup.enter)="ingresar()">
                        <!-- <button mat-button *ngIf="Password.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Password)">
                            <mat-icon color="warn">close</mat-icon>
                        </button> -->
                        <mat-error *ngIf="Password.invalid">
                            Ingresa una contraseña válida
                        </mat-error>
                    </mat-form-field>
                    <a (click)="DialogRecordarPassword()">¿Olvidaste tu contraseña?</a>
                </form>
            </mat-card-content>
            <br>
            <mat-card-actions align="center">
                <button mat-fab extended color="primary" [disabled]="formulario_login.invalid" (click)="loginService.SignIn(Correo.value, Password.value)">Ingresar</button>
            </mat-card-actions>
        </mat-card>
    </ng-container>

    <ng-template #ingresado>
        <ng-container *ngIf="InfoUsuario()">
            
            <ng-container *ngIf="loginService.emailVerificado; else verificarEmail">
                <mat-card appearance="outlined" [@animacion_fade_rapido]>
                    <mat-card-header>
                        <mat-card-title>Has ingresado como {{InfoUsuario().Nombres}} {{InfoUsuario().Apellidos}}</mat-card-title>
                    </mat-card-header>
                    <br>
                    <mat-card-actions align="center">
                        <button mat-fab extended color="primary" (click)="loginService.confirmacionLogOut()">Logout</button>
                    </mat-card-actions>
                </mat-card>
            </ng-container>
            <ng-template #verificarEmail>

                <ng-container  *ngIf="!mensajeVerificacionEnviado; else mensajeEnviado">
                    <mat-card appearance="outlined" [@animacion_fade_rapido]>
                        <mat-card-header>
                            <mat-card-title>Para terminar el registro sigue las instrucciones</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <mat-stepper orientation="vertical" linear="true" #stepper>
                                <mat-step [stepControl]="Nombre" errorMessage="Debes ingresar tu nombre" *ngIf="!NombreCompleto()">
                                    <form [formGroup]="Nombre_Completo">
                                        <ng-template matStepLabel>Escribe tu nombre</ng-template>
                                        <mat-form-field class="Input">
                                            <mat-label>Nombres</mat-label>
                                            <input matInput type="text" [formControl]="Nombres">
                                            <button mat-button *ngIf="Nombres.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Nombres)">
                                                <mat-icon color="warn">close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <mat-form-field class="Input">
                                            <mat-label>Apellidos</mat-label>
                                            <input matInput type="text" [formControl]="Apellidos">
                                            <button mat-button *ngIf="Apellidos.value" matSuffix mat-icon-button aria-label="Clear" (click)="borrarCampo(Apellidos)">
                                                <mat-icon color="warn">close</mat-icon>
                                            </button>
                                        </mat-form-field>
                                        <div>
                                            <button mat-raised-button color="primary"  [disabled]="Nombre_Completo.invalid" matStepperNext>Next</button>
                                        </div>
                                    </form>
                                </mat-step>
                                <mat-step>
                                    <ng-template matStepLabel>Verificar Correo</ng-template>
                                    <p>Para finalizar debes verificar tú correo {{InfoUsuario().email}} antes de poder ingresar.</p>
                                    <div>
                                        <button mat-raised-button color="primary" matStepperPrevious *ngIf="!NombreCompleto()">Back</button>
                                        <button mat-raised-button color="primary" (click)="VerificarCorreo()" [disable]="Nombre_Completo.invalid">Verificar</button>
                                    </div>
                                </mat-step>
                            </mat-stepper>
                        </mat-card-content>

                    </mat-card>
                </ng-container>
                <ng-template #mensajeEnviado>
                    <mat-card appearance="outlined" [@animacion_fade_rapido]>
                        <mat-card-header>
                            <mat-card-title><mat-icon>email</mat-icon> Mensaje de verificación enviado</mat-card-title>
                        </mat-card-header>
                        <mat-card-content>
                            <ol>
                                <br>
                                <li>Revisa tu correo {{InfoUsuario().email}}</li>
                                <br>
                                <li>Entra al enlace de verificación</li>
                                <br>
                                <li>Vuelve a ingresar o actualiza esta pestaña</li>
                                <br>
                                <button mat-raised-button color="primary" onClick="window.location.reload(true)">Actualizar</button>
                            </ol>
                        </mat-card-content>
                    </mat-card>
                </ng-template>
                


            </ng-template>

        </ng-container>
        
    </ng-template>

</div>