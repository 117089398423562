import { Injectable } from '@angular/core';
import { deleteObject, getDownloadURL, getStorage, ref, uploadBytes } from '@angular/fire/storage';
import { getAuth } from '@angular/fire/auth';
import { LoginService } from './login.service';
import { Firestore, doc, setDoc, updateDoc } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { EquipoService } from './equipo.service';

@Injectable({
  providedIn: 'root'
})
export class ImagenesService {

  auth = getAuth();

  URLbucket = "gs://sublime-expeditions-operacion.appspot.com"

  storage = getStorage(this.auth.app, this.URLbucket)

  constructor(
    private loginService: LoginService,
    private equipoService: EquipoService,
    private router: Router,
    private firestore: Firestore,
  ) {

  }
  UID = this.loginService.InfoUsuario.uid
  subirArchivo(archivos: File[], carpeta: 'usuarios' | 'productos' | 'inventario', uid = this.UID) {
    if (!archivos) return

    const archivo: File = archivos[0];
    const nombreArchivo = `${ uid }/${carpeta === 'usuarios' ? 'foto_perfil' : 'foto'}.${archivo.name.split('.').pop()}`

    
    const direccionParcial = `${ carpeta }/${ nombreArchivo }`
    const direccionCompleta = `${ this.URLbucket }/${ direccionParcial }`
    const storageRef = ref(this.storage, direccionParcial)
      
    uploadBytes(storageRef, archivo)
      .then(
        () => {
          if(carpeta === 'usuarios') {
            this.loginService.InfoUsuario.photoURL = direccionCompleta
            this.equipoService.actualizarInfoUsuario()
            // FIXME: por ahora esta es la solucion para actualizar la foto cuando cambia //
                    .then(()=>this.router.navigate(['dashboard']))
                    .then(()=>this.router.navigate(['usuario']))
          }
          else if(carpeta === 'inventario') {
            updateDoc(doc(this.firestore, `${ carpeta }/${ uid }`), {URL_Imagen: direccionCompleta})
          }
        }
      )
      .catch(
        error => console.log( error )
      )
  }

  getImagen = ( url: string ) => getDownloadURL( ref(this.storage, url) )

  borrarImagen = ( url: string ) => deleteObject(ref(this.storage, url.replace(`${this.URLbucket}/`, '')))

}
