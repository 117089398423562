import { Pipe, PipeTransform } from '@angular/core';
import { EstadoSolicitud } from '../models/inventario';

@Pipe({
  name: 'estadoSolicitudPlural'
})
export class EstadoSolicitudPluralPipe implements PipeTransform {

  transform = ( valor: EstadoSolicitud ) => valor === 'Solicitud pendiente' ? 'Solicitudes pendientes' : `${valor}s`

}
