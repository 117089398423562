import { Component, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PopupService } from 'src/app/services/popups.service';
import { ReservasService } from '../../../services/reservas.service';
import { LoginService } from '../../../services/login.service';
import { MatDialogRef } from '@angular/material/dialog';
import { InformacionBloqueo } from 'src/app/models/reservas.model';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralesService } from '../../../services/generales.service';

@Component({
  selector: 'app-edicion-bloqueo',
  templateUrl: './edicion-bloqueo.component.html',
  styleUrls: ['./edicion-bloqueo.component.css']
})
export class EdicionBloqueoComponent {

  get Editor_uid()           {return this.formNuevoBloqueo.get('Editor_uid')    as FormControl}
  get Fecha_Cambio()         {return this.formNuevoBloqueo.get('Fecha_Cambio')  as FormControl}
  get Resumen()              {return this.formNuevoBloqueo.get('Resumen')       as FormControl}
  get Descripcion()          {return this.formNuevoBloqueo.get('Descripcion')   as FormControl}
  get Fecha_Bloqueo()        {return this.formNuevoBloqueo.get('Fecha_Bloqueo') as FormGroup  }
  get Fecha_Inicio_Bloqueo() {return this.Fecha_Bloqueo.get('Inicio')           as FormControl}
  get Fecha_Fin_Bloqueo()    {return this.Fecha_Bloqueo.get('Fin')              as FormControl}

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataPopup,
    private formBuilder: FormBuilder,
    private reservasService: ReservasService,
    private loginService: LoginService,
    private generalesService: GeneralesService,
    private popupConfirmarService: PopupService,
    private dialogRef: MatDialogRef<EdicionBloqueoComponent>,
  ) {
    this.crearFormulario();
  }

  formNuevoBloqueo: FormGroup;

  crearFormulario(): void {
    this.formNuevoBloqueo = this.formBuilder.group({

      Editor_uid    : [ this.loginService.InfoUsuario.uid    , [Validators.required]],
      Fecha_Cambio  : [ new Date()                           , [Validators.required]],
      Resumen       : [ this.data?.Tipo === 'Edición' ? this.data.InfoEvento.Resumen                       : '' , [Validators.required]],
      Descripcion   : [ this.data?.Tipo === 'Edición' ? this.data.InfoEvento.Descripcion                   : '' , [Validators.required]],
      Fecha_Bloqueo : this.formBuilder.group({
        Inicio      : [ this.data?.Tipo === 'Edición' ? this.data.InfoEvento.Fecha_Bloqueo.Inicio.toDate() : '' , [Validators.required]],
        Fin         : [ this.data?.Tipo === 'Edición' ? this.data.InfoEvento.Fecha_Bloqueo.Fin.toDate()    : '' , [Validators.required]],
      }),
  });

  }

  borrarCampo  = this.generalesService.borrarCampoForm;

  guardarBloqueo(): void {
    const mensajeConfirmacion = () => {
      this.popupConfirmarService.mensajeConfirmacion( `Bloqueo guardado correctamente`, 'Confirmacion' )
      this.dialogRef.close();
    };

    const PopupTitulo = `${this.data?.Tipo === 'Edición' ? 'Editar' : 'Agregar'} fechas bloqueadas`;
    const PopupPregunta = `¿Deseas ${this.data?.Tipo === 'Edición' ? 'editar' : 'agregar'} las fechas bloqueadas?`;
    const Accion = (): void => {
      if ( this.data?.Tipo === 'Edición' ) {
        this.reservasService.editarBloqueo({
          id: this.data.InfoEvento.id,
          ...this.formNuevoBloqueo.value
        })
          .then( mensajeConfirmacion )
          .catch(() => this.popupConfirmarService.mensajeConfirmacion( `Error al editar bloqueo`, 'Error' ));
      }
      else {
        this.reservasService.agregarBloqueo(this.formNuevoBloqueo.value as InformacionBloqueo)
          .then( mensajeConfirmacion )
          .catch(() => this.popupConfirmarService.mensajeConfirmacion( `Error al guardar bloqueo`, 'Error' ));
      }

    };

    if ( this.formNuevoBloqueo.touched ) {
      this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
    }
    else {
      this.dialogRef.close();
    }
  }

  cancelarEdicion(): void {
    const PopupTitulo = `Cancelar ${this.data?.Tipo === 'Edición' ? 'editar' : 'agregar'} fechas bloqueadas`;
    const PopupPregunta = `¿Deseas cancelar ${this.data?.Tipo === 'Edición' ? 'la edición de las' : 'agregar'} fechas bloqueadas?`;
    const Accion = (): void => {
      this.dialogRef.close();
    };

    if ( this.formNuevoBloqueo.touched ) {
      this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
    }
    else {
      this.dialogRef.close();
    }
  }

  borrarBloqueo( evento: InformacionBloqueo ): void {
    const PopupTitulo = `Borrar fecha bloqueo`;
    const PopupPregunta = `¿Deseas borrar las fechas bloqueadas a ${evento.Fecha_Bloqueo.Inicio.toDate().toLocaleDateString()}-${evento.Fecha_Bloqueo.Fin.toDate().toLocaleDateString()}?`;
    const Accion = (): void => {
      this.reservasService.borrarBloqueo(evento)
        .then(() => {
          this.popupConfirmarService.mensajeConfirmacion( `Bloqueo borrado correctamente`, 'Confirmacion' )
          this.dialogRef.close();
        })
        .catch(() => this.popupConfirmarService.mensajeConfirmacion( `Error al borrar bloqueo`, 'Error' ));

      
    };

    this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }


}

interface DataPopup {
  Tipo?: 'Edición' | 'Nuevo';
  InfoEvento?: InformacionBloqueo;
}
