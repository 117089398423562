<ng-container *ngIf="Cargado(); else cargador">
  <ng-container></ng-container>
  
  <app-menu></app-menu>
  
  <div class="contenido">
    <router-outlet></router-outlet>
  </div>
</ng-container>


<ng-template #cargador>
  <div class="Contenedor_Cargador">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>

