import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PopupService } from '../../../services/popups.service';
import { InfoReservaDirectaRespuesta, InformacionReservaAliados, OrigenReserva } from '../../../models/reservas.model';
import { ReservasService } from '../../../services/reservas.service';
import { DatosActividad, DatosReservaAliados, DatosReservaDirecta } from 'src/app/models/datos_reserva.model';
import { DatosPago } from '../../../models/datos_reserva.model';

@Component({
  selector: 'app-edicion-reservas',
  templateUrl: './edicion-reservas.component.html',
  styleUrls: ['./edicion-reservas.component.css']
})
export class EdicionReservasComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataPopup,
    private formBuilder: FormBuilder,
    private reservasService: ReservasService,
    private popupConfirmarService: PopupService,
    private dialogRef: MatDialogRef<EdicionReservasComponent>,
  ) {
    this.crearFormulario();
    if(this.data.Tipo === 'Directa') {
      this.Datos_Reserva = new DatosReservaDirecta(this.InfoReserva as InfoReservaDirectaRespuesta);
    }
    else if(this.data.Tipo === 'Aliado') {
      this.Datos_Reserva = new DatosReservaAliados(this.InfoReserva as InformacionReservaAliados);
    }
  }

  InfoReserva = this.data.InfoEvento;
  Tipo = this.data.Tipo;
  formReserva: FormGroup;

  Datos_Reserva: DatosReservaDirecta | DatosReservaAliados = undefined

  Datos_Actividad = new DatosActividad(this.InfoReserva);

  Fecha_Actividad = {
    'Inicio Actividad': this.InfoReserva.Fecha_Actividad.Inicio.toDate(),
    'Fin Actividad': this.InfoReserva.Fecha_Actividad.Fin.toDate(),
  };
  Datos_Viajeros = this.InfoReserva.Datos_Viajeros.map(
    viajero =>
    ({
      Pais: viajero.Pais,
      'Categoría Nacionalidad': viajero.Categoría_Nacionalidad,
      'Precio COP': viajero.Precio_COP,
      'Base IVA COP': viajero.Base_IVA_COP,
      Edad: viajero.Edad,
    })
  );

  Moneda = this.InfoReserva.Tipo_Moneda;
  // TODO: Datos Pago por ahora está igual en las reservas directas y las de aliados pero tienen claras diferencias que falta revisar en las interfaces de Reservas de Aliados //
  Datos_Pago = new DatosPago(this.InfoReserva, this.Tipo);
  Opcionales_Incluidos = this.InfoReserva.Opcionales_Incluidos.map(
    opcion =>
    ({
      Elemento: opcion.Elemento,
      'Tipo Costo': opcion.Tipo_Costo,
      Cantidad: opcion.Cantidad,
      'Precio COP': opcion.Precio_COP,
    })
  )

  crearFormulario(): void {
    this.formReserva = this.formBuilder.group({

      Nombre_Producto      : [ { value: this.InfoReserva.Nombre_Producto, disabled: true}      , [Validators.required] ],
      Tipo_Reserva         : [ { value: this.InfoReserva.Tipo_Reserva, disabled: true}         , [Validators.required] ],
      Categoria_Reserva    : [ { value: this.InfoReserva.Categoria_Reserva, disabled: true}    , [Validators.required] ],
      Idioma_Guianza       : [ { value: this.InfoReserva.Idioma_Guianza, disabled: true}       , [Validators.required] ],
      Fecha_Actividad      : [ { value: this.InfoReserva.Fecha_Actividad, disabled: true}      , [Validators.required] ],
      Datos_Viajeros       : [ { value: this.InfoReserva.Datos_Viajeros, disabled: true}       , [Validators.required] ],
      Opcionales_Incluidos : [ { value: this.InfoReserva.Opcionales_Incluidos, disabled: true} , [Validators.required] ],
    });
    console.log(this.Opcionales_Incluidos);

  }

  confirmarReserva(tipo: 'directas'| 'aliados', id: string) {
    const PopupTitulo = `Confirmación reserva`;
    const PopupPregunta = `¿Deseas confirmar esta reserva, esta acción no se puede deshacer?`;
    const Accion = (): void => {
      this.reservasService.confirmarReserva(tipo, id)
        .then( () => {
          this.popupConfirmarService.mensajeConfirmacion( `Reserva confirmada correctamente`, 'Confirmacion' )
          this.dialogRef.close();
        } )
        .catch(() => this.popupConfirmarService.mensajeConfirmacion( `Error al confirmar reserva`, 'Error' ));
      };
    this.popupConfirmarService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  };

}

interface DataPopup {
  Tipo?: OrigenReserva;
  InfoEvento: InfoReservaDirectaRespuesta | InformacionReservaAliados;
}
