<ng-container *ngIf="Lista_Cargada; else cargador">
    <mat-toolbar>
        <button mat-fab extended color="accent" (click)="solicitarPrestamo()">Nueva solicitud<mat-icon>add</mat-icon> </button>
    
        <mat-form-field>
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="filtro($event)" placeholder="Ej: Por nombre" [formControl]="Busqueda">
            <button mat-icon-button *ngIf="Busqueda.value" matSuffix aria-label="Clear" (click)="resetBusqueda()">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>

        <button mat-fab extended [color]="Modo_Borrar ? 'accent' : 'warn'" (click)="toggleModoBorrar()">{{ Modo_Borrar ? 'Cancelar Borrar' : 'Borrar Elemento' }}<mat-icon>{{ Modo_Borrar ? 'cancel' : 'delete' }}</mat-icon> </button>
    
    </mat-toolbar>
    
    <div id="Contenedor_Tabla">

        <ng-container *ngTemplateOutlet="tabla; context: {tipo_categoria: 'Solicitud pendiente'}"></ng-container>
        <ng-container *ngTemplateOutlet="tabla; context: {tipo_categoria: 'Aprobada'}"></ng-container>
        <ng-container *ngTemplateOutlet="tabla; context: {tipo_categoria: 'Rechazada'}"></ng-container>
        
        <ng-template #tabla let-tipo_categoria="tipo_categoria">
            <table mat-table mat-text-column="justify: 'center'" *ngIf="dataCategoria(tipo_categoria).data.length" [dataSource]="dataCategoria(tipo_categoria)" multiTemplateDataRows matSort matSortActive="fecha_solicitud" matSortDirection="asc"  class="mat-elevation-z8">
            
                <ng-container matColumnDef="ID">
                    <th class="align_center" mat-header-cell *matHeaderCellDef> ID </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.ID }} </td>
                </ng-container>
        
                <ng-container matColumnDef="fecha_solicitud">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Solicitud </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Fecha_Solicitud | date }} </td>
                </ng-container>
        
                <ng-container matColumnDef="fecha_prestamo">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Prestamo </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Fecha_Prestamo.Inicio | date }} - {{ elemento.Fecha_Prestamo.Fin | date }} </td>
                </ng-container>
    
                <ng-container matColumnDef="nombre_solicitante">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Solicitante </th>
                    <td mat-cell *matCellDef="let elemento"> {{ getNombreUsuario( elemento.ID_Solicitante ) }} </td>
                </ng-container>
    
                <ng-container matColumnDef="persona_que_aprueba">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Aprobante </th>
                    <td mat-cell *matCellDef="let elemento"> {{ getNombreUsuario( elemento.ID_Persona_Que_Aprueba ) }} </td>
                </ng-container>

                <ng-container matColumnDef="fecha_aprobacion">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Fecha Aprobación </th>
                    <td mat-cell *matCellDef="let elemento"> {{ elemento.Fecha_Aprobacion | date }} </td>
                </ng-container>

                <ng-container matColumnDef="estado_prestamo">
                    <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Estado Prestamo </th>
                    <td mat-cell *matCellDef="let elemento">
                        <mat-chip [class]="getClaseEstadoPrestamo( getEstadoPrestamo(elemento) )">
                            <div class='Chips_Prestamos'>
                                {{ getEstadoPrestamo(elemento)}}
                                <mat-icon>{{ getSimboloPrestamo( getEstadoPrestamo(elemento) ) }}</mat-icon>
                            </div>
                        </mat-chip>
                    </td>
                </ng-container>

                <ng-container matColumnDef="editar">
                    <th class="align_center" mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let elemento">
                        <button mat-icon-button (click)="solicitarPrestamo(elemento)" *ngIf="editable(elemento.ID_Solicitante)">
                            <mat-icon>edit</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="expand">
                    <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                            <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                            <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                        </button>
                    </td>
                </ng-container>
    
                <ng-container matColumnDef="delete" *ngIf="Modo_Borrar">
                    <th class="align_center" mat-header-cell *matHeaderCellDef > </th>
                    <td mat-cell *matCellDef="let elemento">
                        <button mat-mini-fab color="warn" (click)="borrarElemento(elemento)" *ngIf="editable(elemento.ID_Solicitante)">
                            <mat-icon>delete</mat-icon>
                        </button>
                    </td>
                </ng-container>
        
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="ColumnasTabla(tipo_categoria).length">
                        <div *ngIf="element == expandedElement" class="Elemento_Expandible" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">

                            <table mat-table [dataSource]="element.Codigos_Elementos_Prestamo" class="mat-elevation-z8">
                                <ng-container matColumnDef="Tipo_Elemento">
                                    <th mat-header-cell *matHeaderCellDef> Tipo </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.Tipo_Elemento }} </td>
                                </ng-container>
                                <ng-container matColumnDef="Categoria_Elemento">
                                    <th mat-header-cell *matHeaderCellDef> Categoría </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.Categoria_Elemento }} </td>
                                </ng-container>
                                <ng-container matColumnDef="Codigo_Elemento">
                                    <th mat-header-cell *matHeaderCellDef> Código </th>
                                    <td mat-cell *matCellDef="let item"> {{ item.Codigo_Elemento }} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="columnasElementosPrestamo"></tr>
                                <tr mat-row *matRowDef="let row; columns: columnasElementosPrestamo;"></tr>
                            </table>

                            <button mat-fab extended color="accent" (click)="aprobarPrestamo(element)" *ngIf="!element.Fecha_Recogida">
                                <mat-icon>visibility</mat-icon>
                                Revisar Solicitud
                            </button>
                            <button mat-fab extended color="primary" *ngIf="element.Estado_Solicitud === 'Aprobada' && (fechaEntrega(element) || element.Fecha_Recogida) && !element.Fecha_Devolucion" (click)="!element.Fecha_Recogida ? confirmarRecogida(element) : confirmarDevolucion(element)">
                                <mat-icon>check_box_outline_blank</mat-icon>
                                Confirmar {{ !element.Fecha_Recogida ? 'recogida' : 'devolución' }}
                            </button>
                        </div>
                    </td>
                </ng-container>

                <ng-container matColumnDef="Heading_Tipo">
                    <th class="header_tabla" mat-header-cell [attr.colspan]="ColumnasTabla(tipo_categoria).length" *matHeaderCellDef>
                        <h2> {{tipo_categoria | estadoSolicitudPlural}} </h2>
                    </th>
                </ng-container>
                    
            
            
                <tr mat-header-row *matHeaderRowDef="['Heading_Tipo']; sticky: true" [ngClass]="tipo_categoria === 'Solicitud pendiente' ? 'Header_Solicitudes_pendientes' : tipo_categoria === 'Aprobada' ? 'Header_Aprobadas' : 'Header_Rechazadas' ">{{tipo}}</tr>
                <tr mat-header-row *matHeaderRowDef="ColumnasTabla(tipo_categoria); sticky: true" class="header_tabla"></tr>
    
                <tr mat-row *matRowDef="let element; columns: ColumnasTabla(tipo_categoria);" class="Columnas_Fila_Expandible" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="Fila_Expandible"></tr>
            </table>
        </ng-template>
    </div>

</ng-container>

<ng-template #cargador>
    probando
    <div class="Contenedor_Cargador">
        <mat-spinner></mat-spinner>
    </div>
</ng-template>