<mat-toolbar color="primary">
    <ng-container *ngIf="loginService.emailVerificado">
        <span class="Celular">
            <button mat-icon-button class="example-icon" [matMenuTriggerFor]="menu">
                <mat-icon>menu</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
                <button mat-menu-item routerLink="dashboard">Dashboard</button>
                <button mat-menu-item routerLink="equipo" *ngIf="Permiso('Equipo')">Equipo</button>
                <button mat-menu-item routerLink="inventario" *ngIf="Permiso('Inventario')">Inventario</button>
                <button mat-menu-item routerLink="prestamos" *ngIf="Permiso('Prestamos')">Prestamos</button>
                <button mat-menu-item routerLink="productos" *ngIf="Permiso('Productos')">Productos</button>
                <button mat-menu-item routerLink="operaciones" *ngIf="Permiso('Operaciones')">Operaciones</button>
                <button mat-menu-item routerLink="reservas" *ngIf="Permiso('Reservas')">Reservas</button>
            </mat-menu>
        </span>
        <span class="Grande">
            <button mat-button routerLink="dashboard">Dashboard</button>
            <button mat-button routerLink="equipo" *ngIf="Permiso('Equipo')">Equipo</button>
            <button mat-button routerLink="inventario" *ngIf="Permiso('Inventario')">Inventario</button>
            <button mat-button routerLink="prestamos" *ngIf="Permiso('Prestamos')">Prestamos</button>
            <button mat-button routerLink="productos" *ngIf="Permiso('Productos')">Productos</button>
            <button mat-button routerLink="operaciones" *ngIf="Permiso('Operaciones')">Operaciones</button>
            <button mat-button routerLink="reservas" *ngIf="Permiso('Reservas')">Reservas</button>
        </span>
    </ng-container>

    <span class="spacer"></span>
    <button mat-button routerLink="login" *ngIf="!loginService.isLoggedIn; else logout">Log in <mat-icon aria-hidden="false" aria-label="Login">login</mat-icon></button>
    <ng-template #logout>
        <button mat-icon-button routerLink="usuario"><mat-icon>account_circle</mat-icon></button>
        <button mat-button (click)="confirmacionLogOut()">Log out <mat-icon aria-hidden="false" aria-label="Logout">logout</mat-icon></button>
    </ng-template>
</mat-toolbar>