import { Component, Input } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { FixMeLater, QRCodeElementType } from 'angularx-qrcode';


@Component({
  selector: 'app-codigo-qr',
  templateUrl: './codigo-qr.component.html',
  styleUrls: ['./codigo-qr.component.css']
})
export class CodigoQrComponent{

  @Input() ContenidoQR: number;
  @Input() ColorQR: string;


  CodigoQR: string = "";
  UrlDescargaQR: SafeUrl = "";


  getUrlQR(elemento){
    this.CodigoQR = elemento
    return elemento
  };

  onChangeURL = (url: SafeUrl) => this.UrlDescargaQR = url;
  
// NOTA: Tocó implementar estas dos funciones para que funcionara la descarga del QR //
  saveAsImage(parent: FixMeLater) {
      const parentElement = parent.qrcElement.nativeElement.querySelector("img").src

      // converts base 64 encoded image to blobData
      let blobData = this.convertBase64ToBlob(parentElement)
      // saves as image
      const blob = new Blob([blobData], { type: "image/png" })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement("a")
      link.href = url
      // name of the file
      link.download = `QR (${this.ContenidoQR}).png`
      link.click()
  }

  private convertBase64ToBlob(Base64Image: string) {
    // split into two parts
    const parts = Base64Image.split(";base64,")
    // hold the content type
    const imageType = parts[0].split(":")[1]
    // decode base64 string
    const decodedData = window.atob(parts[1])
    // create unit8array of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length)
    // insert all character code into uint8array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i)
    }
    // return blob image after conversion
    return new Blob([uInt8Array], { type: imageType })
  }
}
