import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { LoginService } from '../../../services/login.service';


@Component({
  selector: 'app-recordar-password-dialog',
  templateUrl: './recordar-password-dialog.component.html',
  styleUrls: ['./recordar-password-dialog.component.css']
})
export class RecordarPasswordDialogComponent {

  Correo: FormControl;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
  ) {
    this.Correo = this.formBuilder.control(
      '', [Validators.required, Validators.email]
    );
  }

  recordarPassword = ( correo: string ) => this.loginService.RecordarPassword( correo );


}
