import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { ConfirmarAccionDialogComponent } from '../shared/popups/confirmar-accion-dialog/confirmar-accion-dialog.component';
import { AgregarPersonaDialogComponent } from '../shared/popups/agregar-persona-dialog/agregar-persona-dialog.component';
import { EditarInformacionGeneralDialogComponent } from '../shared/popups/editar-informacion-general-dialog/editar-informacion-general-dialog.component';
import { DefinirNivelDialogComponent } from '../shared/popups/definir-nivel-dialog/definir-nivel-dialog.component';
import { AgregarInventarioComponent } from '../shared/popups/agregar-inventario/agregar-inventario.component';
import { ImagenGrandeComponent } from '../shared/popups/imagen-grande/imagen-grande.component';
import { Info_usuario } from '../models/info_usuarios';
import { EtapaSolicitudPrestamo, Inventario, SolicitudesPrestamos } from '../models/inventario';
import { SolicitudPrestamoDialogComponent } from '../shared/popups/solicitud-prestamo-dialog/solicitud-prestamo-dialog.component';

type tipoMensajeConfirmacion = 'Confirmacion' | 'Error'

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(
    private dialog: MatDialog,
    private mensaje: MatSnackBar,
  ) { }


  popupConfirmar(Titulo: string, Texto: string, Accion: () => void, SinCancelar: boolean = false, tipo?: tipoMensajeConfirmacion): void {
    const Simbolo = tipo ? tipo === 'Confirmacion' ? '✅' : '❌' : '';

    this.dialog.open(ConfirmarAccionDialogComponent,
      { data: {Titulo:`${Titulo} ${Simbolo}`, Texto, Accion, SinCancelar}, panelClass: tipo ? `Mensaje_${tipo}` : undefined }
    );
  }

  popupAgregarPersona(): void {
    this.dialog.open(AgregarPersonaDialogComponent,
    );
  }

  popupVerImagenGrande(url: string): void {
    this.dialog.open(ImagenGrandeComponent,
      { data: {UrlImagen: url} }
    );
  }

  popupAgregarElementoInventario(elemento?: Inventario): void {
    this.dialog.open(AgregarInventarioComponent,
      { data: elemento }
    );
  }

  popupSolicitudPrestamo(tipo: EtapaSolicitudPrestamo, elemento?: SolicitudesPrestamos): void {
    this.dialog.open(SolicitudPrestamoDialogComponent,
      { data: {tipo, info: elemento} }
    );
  }
  

  popupEditarInformacionGeneral(formularios: Array<'Info_General' | 'Info_Especifica' | 'Ficha_Medica'>  = ['Info_General', 'Info_Especifica', 'Ficha_Medica']): void {
    this.dialog.open(EditarInformacionGeneralDialogComponent,
      {data: formularios}
    );
  }

  popupDefinirNivelPersona( info_Usuario: Info_usuario ): void {
    this.dialog.open(DefinirNivelDialogComponent,
      { data: info_Usuario }
    );
  }

  mensajeConfirmacion(mensaje: string, tipo: tipoMensajeConfirmacion ): void {
    this.mensaje.open(
      `${mensaje} ${tipo === 'Confirmacion' ? '✅' : '❌'}`, 'Aceptar', {panelClass: tipo === 'Confirmacion' ? 'Mensaje_Confirmacion' : 'Mensaje_Error'}
    );
  }

}
