import { Injectable } from '@angular/core';
import { initializeApp } from '@angular/fire/app';
import { Auth, User, createUserWithEmailAndPassword, deleteUser, fetchSignInMethodsForEmail, getAuth } from '@angular/fire/auth';
import { collection, deleteDoc, doc, Firestore, getDocs, limit, onSnapshot, orderBy, query, setDoc, Timestamp } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';
import { Info_usuario } from '../models/info_usuarios';
import { PopupService } from './popups.service';
import { LoginService } from './login.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EquipoService {

  constructor(
    private firestore: Firestore,
    public dialog: MatDialog,
    private popupService: PopupService,
    private loginService: LoginService,
    private http: HttpClient,
  ) {
    this.getUsuarios()
  }

  coleccionUsuarios = collection(this.firestore,'usuarios')

  authSecundario = getAuth(initializeApp(environment.firebase, "Secondary"));

  Lista_Usuarios: BehaviorSubject<Info_usuario[]> = new BehaviorSubject<Info_usuario[]>([]);

  getUsuarios() {
    onSnapshot(this.coleccionUsuarios, coleccion => {
      const docs: Info_usuario[] = coleccion.docs.map( documento => (
        //       // NOTA: Acá en caso de tener la ficha completa se transforma el timestamp de la fecha nacimiento a un date //
        documento.data().Informacion_General ?
          {
            ...documento.data(),
            Informacion_General: {
              ...documento.data().Informacion_General,
              Fecha_Nacimiento: (documento.data().Informacion_General?.Fecha_Nacimiento as unknown as Timestamp).toDate()
            }
          }
          : documento.data()
        ) as Info_usuario
      )
      
      this.Lista_Usuarios.next(docs)
    })
  }

  CrearUsuario(formulario) {
    const defaultPassword = 'sublime1234';
    // NOTA: La única forma que se pudo crear usuario sin hacer login a esa cuenta es hacerlo en una app secundaria //

    return createUserWithEmailAndPassword(this.authSecundario, formulario.email, defaultPassword)
      .then(async () => {
        const newUserInfo = this.authSecundario.currentUser;
        const newUserDocument = doc(this.firestore, `usuarios/${newUserInfo.uid}`);
        const InfoNuevoUsuario = formulario;
        InfoNuevoUsuario.Permisos = Object.entries(formulario.Permisos).filter( ([key, valor]) => valor === true ).map( ([key, valor]) => key );
        InfoNuevoUsuario.uid = newUserInfo.uid;
        InfoNuevoUsuario.ID = await this.getNuevoID();

        setDoc(newUserDocument, InfoNuevoUsuario)
        this.authSecundario.signOut();

        const PopupMensaje = `Se ha creado el usuario ${formulario.Nombres} ${formulario.Apellidos}`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
        this.dialog.closeAll();
      })
      .catch(error => {
        const PopupMensaje = `Ha habido un problema creando el usuario  ${formulario.Nombres} ${formulario.Apellidos}`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
        // console.log(error)
        console.log(error.code)
        // if (error.code = 500) alert('ERROR')

      })
  }

  async actualizarInfoUsuario(usuario?: Info_usuario) {
    const infoUsuario = usuario ? usuario : this.loginService.InfoUsuario
    const userDocument = doc(this.firestore, `usuarios/${infoUsuario.uid}`)

    await setDoc(userDocument, infoUsuario, {merge: true})
  }
  
  borrarUsuario( usuario: Info_usuario ) {
    const uid = usuario.uid;
    
    const url = 'https://us-central1-sublime-expeditions-operacion.cloudfunctions.net/api/borrar_usuario'
    const headers = { 'content-type': 'text/plain' }

    const body = uid;

    this.loginService.Cargado === false
    return this.http.post(url, body, {headers}).toPromise()
      .then( res => {
        this.loginService.Cargado === true
        const PopupMensaje = `Se ha eliminado el usuario  ${usuario.email}`;
        this.popupService.mensajeConfirmacion(PopupMensaje, 'Confirmacion')
        }
      )
      .catch(error => {
          const PopupMensaje = `Ha habido un problema borrando el usuario  ${usuario.email}`;
          this.popupService.mensajeConfirmacion(PopupMensaje, 'Error')
          console.log(error)
        }
      )
  }

  getNombreUsuario(IDusuario: number) {
    const usuario = this.Lista_Usuarios.getValue().find( usuario => usuario.ID === IDusuario)

    return `${usuario.Nombres} ${usuario.Apellidos}`
  }

  getNuevoID = () => {
    const q = query(this.coleccionUsuarios, orderBy('ID', 'desc'), limit(1))
    return getDocs(q)
      .then(
        docs => docs.docs.length ? docs.docs[0].data().ID + 1 : 1
      )
  }

  verificarEmailNuevo( email: string ) {
    return fetchSignInMethodsForEmail(this.loginService.auth, email)
      .then((signInMethods: string[]) => 
        signInMethods.length === 0 ? true : false
      )
  }
}
