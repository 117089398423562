import { trigger, state, style, transition, animate } from '@angular/animations';
import { AfterViewInit, Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Categorias_Staff, Info_usuario } from 'src/app/models/info_usuarios';
import { EquipoService } from 'src/app/services/equipo.service';
import { PopupService } from 'src/app/services/popups.service';


@Component({
  selector: 'app-equipo',
  templateUrl: './equipo.component.html',
  styleUrls: ['./equipo.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})

export class EquipoComponent implements AfterViewInit {
  @ViewChildren(MatSort) sorts = new QueryList<MatSort>;

  constructor(
    private popupService: PopupService,
    private equipoService: EquipoService,
    private router: Router,
  ) {
    this.Busqueda = new FormControl('')
  }

  ngAfterViewInit() {
    this.getUsuarios()
  }

  columnasTabla: string[] = ['ID', 'Nombres', 'fichacompleta', 'rnt', 'trabajado', 'birthday', 'nivel', 'calificacion', 'editar'];
  columnasTablaExpandidas: string[] = [...this.columnasTabla, 'expand'];
  Lista_Usuarios: Info_usuario[] = [];
  Categorias_Staff: Categorias_Staff[] = [];
  dataSource = {};
  expandedElement: Info_usuario | null;
  Lista_Cargada = false;

  Busqueda: FormControl<string>;

  Modo_Borrar = false;

  toggleModoBorrar = () => {
    this.Modo_Borrar = !this.Modo_Borrar
    this.Modo_Borrar === true ? this.columnasTablaExpandidas.push('delete') : this.columnasTablaExpandidas.pop()
  };

  tieneRNT = (elemento) => elemento.Informacion_General?.RNT === 'No tiene' || !elemento.Informacion_General?.RNT ? false : true

  getUsuarios() {
    this.equipoService.Lista_Usuarios.subscribe(
      elemento =>  {
        this.Lista_Usuarios = elemento
        
        elemento.map( persona => persona.Categoria).forEach(
          categoria => !this.Categorias_Staff.includes(categoria) ? this.Categorias_Staff.push(categoria) : null
          )
        this.Categorias_Staff.forEach(
          (categoria, index) => {
            const data = new MatTableDataSource(this.equipoService.Lista_Usuarios.getValue().filter( usuario => usuario.Categoria === categoria ))
    
            // NOTA: Tocó ponerle este timeout para que funcionara el sort //
            setTimeout( () => data.sort = this.sorts.find( (_, i: number) => i == index) )
    
            this.dataSource[categoria] = data
          }
        )
        this.Lista_Cargada = true;
      }
    )
  }

  dataCategoria = (categoria) => this.dataSource[categoria]

  agregarPersona = () => this.popupService.popupAgregarPersona()

  borrarUsuario(infoUsuario: Info_usuario) {
    const PopupTitulo = `Eliminar usuario ${infoUsuario.email}`;
    const PopupPregunta = `¿Seguro desea eliminar al usuario ${infoUsuario.email}?`;
    const Accion = (): void => {
      this.Lista_Cargada = false
      this.equipoService.borrarUsuario(infoUsuario)
    };
    this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion );
  }

  filtrar = (valor: string) => Object.values(this.dataSource).forEach(
    (elemento: MatTableDataSource<Info_usuario, MatPaginator>) => elemento.filter = valor
  )

  resetBusqueda() {
    this.Busqueda.reset();
    this.filtrar( undefined )
  }

  filtro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtrar(filterValue.trim().toLowerCase() )
  }

  fichaCompleta = (InfoUsuario: Info_usuario): boolean =>
    !!InfoUsuario.Informacion_General && !!InfoUsuario.Informacion_Especifica && !!InfoUsuario.Ficha_Medica;

  Cumple = (cumpleaños: Date): 'Cumple_Mes' | 'Cumple_Hoy' =>
    cumpleaños.getMonth() === new Date().getMonth() ? cumpleaños.getDay() === new Date().getDay() ? 'Cumple_Hoy' : 'Cumple_Mes' : null ;

  NombreCompleto = (nombre: string, apellido: string) => `${nombre} ${apellido}`;

  definirNivelPersona = (infoUsuario: Info_usuario) => this.popupService.popupDefinirNivelPersona(infoUsuario)

}