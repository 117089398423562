import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirmar-accion-dialog',
  templateUrl: './confirmar-accion-dialog.component.html',
  styleUrls: ['./confirmar-accion-dialog.component.css']
})
export class ConfirmarAccionDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DataPopup,
  ) { }

  accion = () => this.data.Accion();

}

interface DataPopup {
  Titulo: string;
  Texto: string;
  Accion: () => void;
  SinCancelar: boolean;
}