import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, QueryList, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Lista_Estado_Solicitud } from 'src/app/models/categorias';
import { EstadoPrestamo, EstadoSolicitud, SolicitudesPrestamos } from 'src/app/models/inventario';
import { InventariosService } from 'src/app/services/inventarios.service';
import { PopupService } from 'src/app/services/popups.service';
import { EquipoService } from '../../services/equipo.service';
import { MatPaginator } from '@angular/material/paginator';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-prestamos',
  templateUrl: './prestamos.component.html',
  styleUrls: ['./prestamos.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed, void', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
      transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ]),
  ],
})
export class PrestamosComponent {
  @ViewChildren(MatSort) sorts = new QueryList<MatSort>;

  constructor(
    private loginService: LoginService,
    private inventariosService: InventariosService,
    private popupService: PopupService,
    private equipoService: EquipoService,
  ) {
    this.Busqueda = new FormControl('')
  }

  ngAfterViewInit() {
    this.getPrestamos()
  }

  columnasSolicitudPendiente: string[] = ['ID', 'fecha_solicitud', 'fecha_prestamo', 'nombre_solicitante', 'editar'];
  columnasAprobadas: string[] = ['ID', 'fecha_solicitud', 'fecha_prestamo', 'nombre_solicitante', 'persona_que_aprueba', 'fecha_aprobacion', 'estado_prestamo'];
  columnasRechazadas: string[] = ['ID', 'fecha_solicitud', 'fecha_prestamo', 'nombre_solicitante', 'persona_que_aprueba', 'fecha_aprobacion', 'editar'];

  columnasElementosPrestamo: string[] = ['Tipo_Elemento', 'Categoria_Elemento', 'Codigo_Elemento']
  
  Lista_Prestamos: SolicitudesPrestamos[] = [];
  Estados_Solicitud = Lista_Estado_Solicitud;
  dataSource = {};
  expandedElement: SolicitudesPrestamos | null;
  Lista_Cargada = false;

  Busqueda: FormControl<string>;

  Modo_Borrar = false;

  toggleModoBorrar = () => {
    this.Modo_Borrar = !this.Modo_Borrar
    this.Modo_Borrar === true ? this.columnasSolicitudPendiente.push('delete') : this.columnasSolicitudPendiente.pop()
    this.Modo_Borrar === true ? this.columnasRechazadas.push('delete') : this.columnasRechazadas.pop()
  };

  editable = ( ID_Solicitante: number ) =>  this.loginService.InfoUsuario.ID === ID_Solicitante;

  dataCategoria = (categoria) => this.dataSource[categoria]

  ColumnasTabla( categoria: EstadoSolicitud ) {
    const columnas = categoria === 'Solicitud pendiente' ? this.columnasSolicitudPendiente : categoria === 'Aprobada' ? this.columnasAprobadas : this.columnasRechazadas;

    return [ ...columnas, 'expand' ];
  }

  getPrestamos() {
    this.inventariosService.Prestamos.subscribe(
      async lista => {
        this.Lista_Prestamos = await lista
        this.Estados_Solicitud.forEach(
          (estado, index) => {
            const data = new MatTableDataSource(
              this.Lista_Prestamos
                .filter( prestamo => prestamo.Estado_Solicitud === estado )
                .filter( prestamo => this.usuarioDirector() || prestamo.ID_Solicitante === this.loginService.InfoUsuario.ID )
            )

            // NOTA: Tocó ponerle este timeout para que funcionara el sort //
            setTimeout( () => data.sort = this.sorts.find( (_, i: number) => i == index) )
    
            this.dataSource[estado] = data
          }
        )
        this.Lista_Cargada = true;
      }
    )
  }

  usuarioDirector = () => this.loginService.InfoUsuario.Categoria === 'Director';

  getNombreUsuario = (ID_usuario) => this.equipoService.getNombreUsuario(ID_usuario)

  getEquipo = (ID_equipo: number) => this.inventariosService.Inventario.getValue().find( equipo => equipo.ID === ID_equipo).Codigo_Elemento;

  fechaEntrega = (elemento: SolicitudesPrestamos) => elemento.Fecha_Prestamo.Inicio <= new Date() && elemento.Fecha_Prestamo.Fin >= new Date();

  solicitarPrestamo = (elemento?: SolicitudesPrestamos) => this.popupService.popupSolicitudPrestamo('Solicitud', elemento);
  aprobarPrestamo = (elemento: SolicitudesPrestamos) => this.popupService.popupSolicitudPrestamo('Aprobación', elemento);
  confirmarRecogida = (elemento: SolicitudesPrestamos) => this.popupService.popupSolicitudPrestamo('Recogida', elemento);
  confirmarDevolucion = (elemento: SolicitudesPrestamos) => this.popupService.popupSolicitudPrestamo( 'Devolución', elemento);

  getEstadoPrestamo = ( elemento: SolicitudesPrestamos ) => elemento.Fecha_Devolucion ? 'Devuelto' : elemento.Fecha_Recogida ? 'Pendiente' : this.fechaEntrega(elemento) ? 'Por recoger' : 'Aprobada';

  getSimboloPrestamo = (estado: EstadoPrestamo) => estado === 'Pendiente' ? 'error' : estado === 'Devuelto' ? 'check_circle' : estado === 'Por recoger' ? 'pending' : 'grade';

  getClaseEstadoPrestamo = (estado: EstadoPrestamo) => `Prestamo_${ estado.toString().split(" ").join("_") }`;

  filtrar = (valor: string) => Object.values(this.dataSource).forEach( (elemento: MatTableDataSource<SolicitudesPrestamos, MatPaginator>) => elemento.filter = valor )

  resetBusqueda() {
    this.Busqueda.reset();
    this.filtrar( undefined )
  }

  filtro(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.filtrar(filterValue.trim().toLowerCase() )
  }

}
