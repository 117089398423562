import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// NOTA: componentes //
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EquipoComponent } from './components/equipo/equipo.component';
import { InventarioComponent } from './components/inventario/inventario.component';
import { LoginComponent } from './components/login/login.component';
import { PrestamosComponent } from './components/prestamos/prestamos.component';
import { ProductosComponent } from './components/productos/productos.component';
import { ReservasComponent } from './components/reservas/reservas.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { OperacionesComponent } from './components/operaciones/operaciones.component';

// NOTA: Guards //
import { LoginGuard } from './guards/login.guard';
import { ProductosGuard } from './guards/productos.guard';
import { ReservasGuard } from './guards/reservas.guard';
import { EquipoGuard } from './guards/equipo.guard';
import { InventarioGuard } from './guards/inventario.guard';
import { PrestamosGuard } from './guards/prestamos.guard';
import { OperacionesGuard } from './guards/operaciones.guard';

const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard] },
  { path: 'equipo', component: EquipoComponent, canActivate: [LoginGuard, EquipoGuard] },
  { path: 'inventario', component: InventarioComponent, canActivate: [LoginGuard, InventarioGuard] },
  { path: 'productos', component: ProductosComponent, canActivate: [LoginGuard, ProductosGuard] },
  { path: 'prestamos', component: PrestamosComponent, canActivate: [LoginGuard, PrestamosGuard] },
  { path: 'operaciones', component: OperacionesComponent, canActivate: [LoginGuard, OperacionesGuard] },
  { path: 'reservas', component: ReservasComponent, canActivate: [LoginGuard, ReservasGuard] },
  { path: 'usuario', component: UsuarioComponent, canActivate: [LoginGuard] },
  { path: '**', pathMatch: 'full', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
