import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CodigoQrComponent } from './codigo-qr/codigo-qr.component';
import { SistemaRatingComponent } from './sistema-rating/sistema-rating.component';
import { QRCodeModule } from 'angularx-qrcode';
import { MaterialModule } from '../material/material.module';



@NgModule({
  declarations: [
    CodigoQrComponent,
    SistemaRatingComponent,

  ],
  imports: [
    CommonModule,
    QRCodeModule,
    MaterialModule
  ],
  exports:[
    CodigoQrComponent,
    SistemaRatingComponent,
  ]
})
export class WidgetsModule { }
