export const Lista_Especialidades_Naturaleza = [
    "Avistamiento de Aves",
    "Botánica general",
    "Herpetología",
    "Mamíferos",
    "Orquídeas",
    "Restauración"
] as const

export const Lista_Especialidades_Cultura = [
    "Arquitectura",
    "Artes",
    "Culturas ancestrales",
    "Folclore y música",
    "Historia",
    "Saberes y oficios"
] as const

export const Lista_Especialidades_Deporte = [
    "Buceo",
    "Canotaje",
    "Canyoning",
    "Escalada en roca",
    "Espeleología",
    "Montañismo",
    "Parapente",
    "Parcaidismo"
] as const