import { Component, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { Router } from '@angular/router';
import { Lista_Categorias_Staff } from 'src/app/models/categorias';
import { Lista_Especialidades_Naturaleza, Lista_Especialidades_Cultura, Lista_Especialidades_Deporte } from 'src/app/models/especialidades';
import { Lista_Idiomas } from 'src/app/models/idiomas';
import { EspecialidadesGenerales } from 'src/app/models/info_usuarios';
import { Lista_Permisos } from 'src/app/models/permisos';
import { EquipoService } from 'src/app/services/equipo.service';
import { PopupService } from '../../../services/popups.service';
import { GeneralesService } from '../../../services/generales.service';

@Component({
  selector: 'app-agregar-persona-dialog',
  templateUrl: './agregar-persona-dialog.component.html',
  styleUrls: ['./agregar-persona-dialog.component.css']
})

export class AgregarPersonaDialogComponent {

  @ViewChild('stepper') stepper: MatStepper;

  //////////  MARCADOR: arrays importados /////////////////////////////////////////////////////////////////
  Lista_Idiomas = Lista_Idiomas;
  Lista_Especialidades_Naturaleza = Lista_Especialidades_Naturaleza;
  Lista_Especialidades_Cultura    = Lista_Especialidades_Cultura;
  Lista_Especialidades_Deporte    = Lista_Especialidades_Deporte;
  Lista_Categorias_Staff          = Lista_Categorias_Staff;
  Lista_Permisos                  = Lista_Permisos;
  //////////  MARCADOR-CIERRE  /////////////////////////////////////////////////////////////////////////////

  formulario_persona: FormGroup;

  get Nombres()        { return this.formulario_persona.get('Nombres')        as FormControl }
  get Apellidos()      { return this.formulario_persona.get('Apellidos')      as FormControl }
  get email()          { return this.formulario_persona.get('email')          as FormControl }
  get Categoria()      { return this.formulario_persona.get('Categoria')      as FormControl }
  get Idiomas()        { return this.formulario_persona.get('Idiomas')        as FormArray   }
  get Especialidades() { return this.formulario_persona.get('Especialidades') as FormGroup   }
    get Especialidades_Naturaleza()     { return this.Especialidades.get('Naturaleza')         as FormGroup }
    get Especialidades_Cultura()        { return this.Especialidades.get('Cultura')            as FormGroup }
    get Especialidades_Deporte()        { return this.Especialidades.get('Deporte')            as FormGroup }

  get Permisos()       { return this.formulario_persona.get('Permisos')       as FormGroup   }
    get Equipo()         { return this.Permisos.get('Permisos')                 as FormControl }
    get Inventario()     { return this.Permisos.get('Permisos')                 as FormControl }
    get Productos()      { return this.Permisos.get('Permisos')                 as FormControl }
    get Reservas()       { return this.Permisos.get('Permisos')                 as FormControl }

  get Con_Idioma() { return this.Idiomas.controls.find(valor => valor.get('Idioma').valid) }

  constructor(
    private formBuilder: FormBuilder,
    private equipoService: EquipoService,
    private popupService: PopupService,
    private generalesService: GeneralesService,
    private router: Router,
  ) {
    this.crearFormulario();
    // TODO: Recordar borrar esto //
    this.Nombres.setValue('Alam')
    this.Apellidos.setValue('Brito Delgado')
    this.email.setValue('alam@brito.delgado')
    this.Categoria.setValue('Guía')
  }

  crearFormulario(): void {
    this.formulario_persona = this.formBuilder.group({
      Nombres        : ['', [Validators.required]],
      Apellidos      : ['', [Validators.required]],
      email          : ['', [Validators.required, Validators.email]],
      Categoria      : ['', [Validators.required]],
      Idiomas        : this.formBuilder.array([]),
      Especialidades : this.formBuilder.group({
        Naturaleza   : this.formBuilder.group({
          Activa: false,
          Especificas: [[]],
        }),
        Cultura      : this.formBuilder.group({
          Activa: false,
          Especificas: [[]],
        }),
        Deporte      : this.formBuilder.group({
          Activa: false,
          Especificas: [[]],
        }),
      }),
      Permisos       : this.formBuilder.group({
        Equipo      : false,
        Inventario  : false,
        Productos   : false,
        Prestamos   : false,
        Operaciones : false,
        Reservas    : false,
      }),
    });
  }
  
  Lista_Idiomas_Filtrada = (): string[] => this.Lista_Idiomas.filter( idioma => !(this.Idiomas.value.map( elemento => elemento.Idioma) as string[]).includes(idioma) );
  Idioma_Seleccionado = (idioma: string): boolean => (this.Idiomas.value.map( elemento => elemento.Idioma) as string[]).includes(idioma);

  AgregarIdioma() {
    this.Idiomas.push(
      this.formBuilder.group({
        Idioma : ['', [Validators.required]],
        Nivel  : ['', [Validators.required]],
      })
    )
  }

  AgregarEspecialidadEspecifica(especialidad: EspecialidadesGenerales) {
    const array = this[`Especialidades_${especialidad}`].get('Especificas')

    array.patchValue((array.value as string[]).push(''))
  }

  borrarCampo = this.generalesService.borrarCampoForm;

  borrarIdioma = (index: number) => this.Idiomas.removeAt(index);

  borrarEspecialidad = (especialidad: EspecialidadesGenerales, index: number) => {
    const array = this[`Especialidades_${especialidad}`];

    array.setValue((array.value as []).splice(index, 1))
  }

  async verificarCorreo() {
    const emailNuevo = await this.equipoService.verificarEmailNuevo(this.email.value)

    const PopupTitulo = `Usuario ya existente`;
    const PopupPregunta = `El correo ${this.email.value} ya ha sido usado por otro usuario`;
    const Accion = (): void => null;
    
    emailNuevo ? this.stepper.next() : this.popupService.popupConfirmar( PopupTitulo, PopupPregunta, Accion, true, 'Error' );
  }


  GuardarNuevaPersona = () => this.equipoService.CrearUsuario(this.formulario_persona.value);

}
