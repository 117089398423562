<h2 mat-dialog-title>Ver Reserva {{data.Tipo}}</h2>
<mat-dialog-content>
    <form [formGroup]="formReserva">

        <mat-chip [color]="data.Tipo === 'Directa' ? 'primary' : 'accent'" selected>Reserva {{data.Tipo}}</mat-chip>
        <br>
        <h3>Datos Reserva</h3>
        <mat-form-field color="accent" class="Input" *ngFor="let elemento of Datos_Reserva | keyvalue">
            <mat-label>{{elemento.key}}</mat-label>
            <input matInput type="text" [value]="elemento.value" disabled>
        </mat-form-field>
        
        <mat-divider></mat-divider>

        <h3>Datos Pago</h3>
        <mat-form-field color="accent" class="Input">
            <mat-label>Moneda</mat-label>
            <input matInput type="text" [value]="Moneda" disabled>
        </mat-form-field>
        <br>
        <mat-form-field color="accent" class="Input" *ngFor="let elemento of Datos_Pago | keyvalue">
            <mat-label>{{elemento.key}}</mat-label>
            <input matInput type="text" [value]="elemento.value" disabled>
        </mat-form-field>

        <mat-divider></mat-divider>
        
        <h3>Datos Actividad</h3>
        <mat-form-field color="accent" class="Input" *ngFor="let elemento of Fecha_Actividad | keyvalue">
            <mat-label>{{elemento.key}}</mat-label>
            <input matInput type="text" [value]="elemento.value | date" disabled>
        </mat-form-field>
        <mat-form-field color="accent" class="Input" *ngFor="let elemento of Datos_Actividad | keyvalue">
            <mat-label>{{elemento.key}}</mat-label>
            <input matInput type="text" [value]="elemento.value" disabled>
        </mat-form-field>

        <mat-divider></mat-divider>

        <h3>Datos Viajeros</h3>
        <ng-container *ngFor="let viajero of Datos_Viajeros; let index = index">
            <h4>Viajero {{index + 1}}</h4>
            <mat-form-field color="accent" class="Input" *ngFor="let elemento of viajero | keyvalue">
                <mat-label>{{elemento.key}}</mat-label>
                <input matInput type="text" [value]="elemento.value" disabled>
            </mat-form-field>
            
            <mat-divider *ngIf="index !== Datos_Viajeros.length"></mat-divider>
        </ng-container>

        <mat-divider></mat-divider>

        <ng-container *ngIf="Opcionales_Incluidos.length">
            <h3>Datos Opcionales</h3>
            <ng-container *ngFor="let opcion of Opcionales_Incluidos; let index = index">
                <h4>Opcional {{index + 1}}</h4>
                <mat-form-field color="accent" class="Input">
                    <mat-label>Elemento</mat-label>
                    <input matInput type="text" [value]="opcion.Elemento.ES" disabled>
                </mat-form-field>
                <mat-form-field color="accent" class="Input">
                    <mat-label>Tipo Costo</mat-label>
                    <input matInput type="text" [value]="opcion['Tipo Costo']" disabled>
                </mat-form-field>
                <mat-form-field color="accent" class="Input">
                    <mat-label>Precio COP</mat-label>
                    <input matInput type="text" [value]="opcion['Precio COP'].Precio_Sin_IVA" disabled>
                </mat-form-field>
                <mat-form-field color="accent" class="Input">
                    <mat-label>Base IVA COP</mat-label>
                    <input matInput type="text" [value]="opcion['Precio COP'].Base_IVA" disabled>
                </mat-form-field>
                <mat-form-field color="accent" class="Input">
                    <mat-label>Cantidad</mat-label>
                    <input matInput type="text" [value]="opcion.Cantidad" disabled>
                </mat-form-field>
                
            </ng-container>
        </ng-container>
        
        
    </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button *ngIf="InfoReserva.Estado_Reserva !== 'Confirmada' " color="accent" mat-raised-button (click)="confirmarReserva('directas', InfoReserva.id)">Confirmar Reserva</button>
    <button mat-button mat-dialog-close>Cerrar</button>
</mat-dialog-actions>