<ng-container *ngIf="Lista_Cargada">
    <mat-toolbar>
        <button mat-fab extended color="accent" (click)="agregarPersona()">Agregar Persona<mat-icon>person_add</mat-icon> </button>
    
        <mat-form-field>
            <mat-label>Buscar</mat-label>
            <input matInput (keyup)="filtro($event)" placeholder="Ej: Por nombre" [formControl]="Busqueda">
            <button mat-icon-button *ngIf="Busqueda.value" matSuffix aria-label="Clear" (click)="resetBusqueda()">
                <mat-icon color="warn">close</mat-icon>
            </button>
        </mat-form-field>

        <button mat-fab extended [color]="Modo_Borrar ? 'accent' : 'warn'" (click)="toggleModoBorrar()">{{ Modo_Borrar ? 'Cancelar Borrar' : 'Borrar Personas' }}<mat-icon>{{ Modo_Borrar ? 'cancel' : 'person_remove' }}</mat-icon> </button>
    
    </mat-toolbar>
    
    <div id="Contenedor_Tabla">
        <table mat-table mat-text-column="justify: 'center'" [dataSource]="dataCategoria(categoria)" multiTemplateDataRows matSort matSortActive="Nombres" matSortDirection="asc"  class="mat-elevation-z8" *ngFor="let categoria of Categorias_Staff">
        
            <ng-container matColumnDef="ID">
                <th class="align_center" mat-header-cell *matHeaderCellDef> ID </th>
                <td mat-cell *matCellDef="let elemento" class="Texto-Izquierda"> {{ elemento.ID }} </td>
            </ng-container>
        
            <ng-container matColumnDef="Nombres">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header class="Texto-Izquierda"> Nombre Completo </th>
                <td mat-cell *matCellDef="let elemento" class="Texto-Izquierda"> {{ NombreCompleto(elemento.Nombres, elemento.Apellidos) }} </td>
            </ng-container>
    
            <ng-container matColumnDef="fichacompleta">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Ficha Completa </th>
                <td mat-cell *matCellDef="let elemento">
                    <mat-icon [color]="fichaCompleta(elemento) ? 'accent' : 'warn'">{{ fichaCompleta(elemento) ? 'check_circle' : 'cancel' }}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="trabajado">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Trabajado con nosotros </th>
                <td mat-cell *matCellDef="let elemento">
                    <mat-icon [color]="elemento.Puntaje?.Puntaje_Experiencia_Empresa > 0 ? 'accent' : 'warn'">{{ elemento.Puntaje?.Puntaje_Experiencia_Empresa  > 0 ? 'check_circle' : 'cancel' }}</mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="rnt">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> RNT </th>
                <td mat-cell *matCellDef="let elemento">
                    <mat-icon [color]="tieneRNT(elemento) ? 'accent' : 'warn'">{{ tieneRNT(elemento) ? 'check_circle' : 'cancel' }}</mat-icon>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="birthday">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Cumpleaños </th>
                <td mat-cell *matCellDef="let elemento">
                    <mat-chip [class]="elemento.Informacion_General?.Fecha_Nacimiento ? Cumple(elemento.Informacion_General?.Fecha_Nacimiento) : 'Sin_Definir'">
                            {{ elemento.Informacion_General?.Fecha_Nacimiento ? ( elemento.Informacion_General?.Fecha_Nacimiento | date ) : 'Sin definir'}}
                            <mat-icon *ngIf="elemento.Informacion_General?.Fecha_Nacimiento && Cumple(elemento.Informacion_General?.Fecha_Nacimiento) !== null">cake</mat-icon>
                    </mat-chip>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="calificacion">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header="Calificacion"> Calificación </th>
                <td mat-cell *matCellDef="let elemento">
                    <ng-container *ngIf="elemento.Calificacion; else sin_calificacion">
                        <app-sistema-rating [Calificacion]="elemento.Calificacion"></app-sistema-rating>
                    </ng-container>
                    <ng-template #sin_calificacion>
                        <mat-chip class="Sin_Definir">Sin calificaciones</mat-chip>
                    </ng-template>
                </td>
            </ng-container>
            
            <ng-container matColumnDef="nivel">
                <th class="align_center" mat-header-cell *matHeaderCellDef mat-sort-header> Nivel </th>
                <td mat-cell *matCellDef="let elemento">
                    <mat-chip class="Texto_Negro" [class]="elemento.Puntaje?.Nivel ? 'Nivel_' + elemento.Puntaje.Nivel : 'Sin_Definir'">
                            {{ elemento.Puntaje?.Nivel ? elemento.Puntaje.Nivel : 'Sin definir' }}
                    </mat-chip>
                </td>
            </ng-container>
        
            <ng-container matColumnDef="editar">
                <th class="align_center" mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let elemento">
                    <button mat-icon-button (click)="definirNivelPersona(elemento)">
                        <mat-icon>edit</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="expand">
                <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
                <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
                        <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
                        <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
                    </button>
                </td>
            </ng-container>

            <ng-container matColumnDef="delete" *ngIf="Modo_Borrar">
                <th class="align_center" mat-header-cell *matHeaderCellDef > </th>
                <td mat-cell *matCellDef="let elemento">
                    <button mat-mini-fab color="warn" (click)="borrarUsuario(elemento)">
                        <mat-icon>delete</mat-icon>
                    </button>
                </td>
            </ng-container>
    
            <ng-container matColumnDef="expandedDetail">
                <td mat-cell *matCellDef="let element" [attr.colspan]="columnasTablaExpandidas.length">
                    <div *ngIf="element == expandedElement" class="Elemento_Expandible" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                        <span class="contenedor_contact_data">
                            <p *ngIf="element.Informacion_General?.Telefono_Celular">
                                Telefono:
                                <button mat-fab extended>
                                    <a [href]="'tel:'+ element.Informacion_General?.Telefono_Celular.Indicativo + element.Informacion_General?.Telefono_Celular.Telefono">
                                        ({{element.Informacion_General?.Telefono_Celular.Indicativo}}) {{ element.Informacion_General?.Telefono_Celular.Telefono | mask: '000 000-0000' }}
                                    </a>
                                    <mat-icon>phone</mat-icon>
                                </button>
                            </p>
                            <p>
                                Email:
                                <button mat-fab extended>
                                    <a [href]="'mailto:'+ element.email">{{element.email}}</a>
                                    <mat-icon>email</mat-icon>
                                </button>
                            </p>
                        </span>
                        <span>
                            <p>Idiomas:</p>
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked">
                                <mat-chip *ngFor="let idioma of element.Idiomas">
                                    {{ idioma.Idioma }} ({{ idioma.Nivel }})
                                </mat-chip>
                            </mat-chip-listbox>
                        </span>
                        <span>
                            <p>Especialidades:</p>
                            <mat-chip-listbox class="mat-mdc-chip-set-stacked">
                                <ng-container *ngIf="element.Especialidades?.Naturaleza.Activa">
                                    <ng-container *ngIf="!element.Especialidades?.Naturaleza.Especificas.length; else especificos">
                                        <mat-chip> Naturaleza </mat-chip>
                                    </ng-container>
                                    
                                    <ng-template #especificos>
                                        <mat-chip *ngFor="let especialidad of element.Especialidades?.Naturaleza.Especificas">
                                            {{ especialidad }}
                                        </mat-chip>
                                    </ng-template>
                                    
                                    
                                </ng-container>
                                
                                <ng-container *ngIf="element.Especialidades?.Cultura.Activa">
                                    <ng-container *ngIf="!element.Especialidades?.Cultura.Especificas.length; else especificos">
                                        <mat-chip> Cultura </mat-chip>
                                    </ng-container>
                                    
                                    <ng-template #especificos>
                                        <mat-chip *ngFor="let especialidad of element.Especialidades?.Cultura.Especificas">
                                            {{ especialidad }}
                                        </mat-chip>
                                    </ng-template>
                                    
                                    
                                </ng-container>
                                
                                <ng-container *ngIf="element.Especialidades?.Deporte.Activa">
                                    <ng-container *ngIf="!element.Especialidades?.Deporte.Especificas.length; else especificos">
                                        <mat-chip> Deporte </mat-chip>
                                    </ng-container>
                                    
                                    <ng-template #especificos>
                                        <mat-chip *ngFor="let especialidad of element.Especialidades?.Deporte.Especificas">
                                            {{ especialidad }}
                                        </mat-chip>
                                    </ng-template>
                                    
                                    
                                </ng-container>
                                
                            </mat-chip-listbox>
                        </span>
                    </div>
                </td>
            </ng-container>

            <ng-container matColumnDef="Heading_Categoria">
                <th class="align_center" mat-header-cell [attr.colspan]="this.columnasTablaExpandidas.length" *matHeaderCellDef>
                    <h2> {{ categoria }} </h2>
                </th>
            </ng-container>
        
        
            <tr mat-header-row *matHeaderRowDef="['Heading_Categoria']; sticky: true" class="header_tabla">{{categoria}}</tr>
            <tr mat-header-row *matHeaderRowDef="columnasTablaExpandidas; sticky: true" class="header_tabla"></tr>

            <tr mat-row *matRowDef="let element; columns: columnasTablaExpandidas;" class="Columnas_Fila_Expandible" [class.example-expanded-row]="expandedElement === element" (click)="expandedElement = expandedElement === element ? null : element"></tr>
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail'];" class="Fila_Expandible"></tr>
        </table>
    </div>

</ng-container>
